import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { colors } from '../../common/colors';

const useStyles = makeStyles((theme) => ({
  actionBtn: {
    marginLeft: theme.spacing(1),
    borderRadius: 2,

    border: `1px solid ${colors.violet}`,
    color: colors.violetText,
    backgroundColor: 'transparent',

    fontSize: 15,
    fontWeight: 300,
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', 'Noto Sans KR'],

    '&:hover': {
      color: colors.white,
      backgroundColor: colors.darkViolet600,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },

  deleteBtn: {
    border: `1px solid ${colors.warningLight}`,
    color: colors.warningLightText,
    backgroundColor: 'transparent',

    '&:hover': {
      color: colors.white,
      backgroundColor: colors.warning,
      border: '1px solid transparent',
    },
  },

  disabledBtn: {
    backgroundColor: 'rgba(224, 224, 224, 0.8)',
    color: 'rgba(204, 204, 204, 1)',
    cursor: 'default',

    '&:hover': {
      transition: 'none',
      color: 'rgba(204, 204, 204, 1)',
      border: `1px solid ${colors.darkViolet_200}`,
      backgroundColor: 'rgba(224, 224, 224, 0.8)',
    },
  },
}));

const ActionButton = ({ label, action, disable, loading, ...props }) => {
  const classes = useStyles();
  const name = label
    ? label
    : action === 'add'
    ? '추가'
    : action === 'delete'
    ? '삭제'
    : '수정';

  return (
    <Button
      size='small'
      {...props}
      disabled={disable === 'true'}
      className={
        action === 'delete'
          ? [classes.actionBtn, classes.deleteBtn].join(' ')
          : classes.actionBtn
      }
    >
      {name}
    </Button>
  );
};

export default ActionButton;
