import React, { useEffect, useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import List from '@ckeditor/ckeditor5-list/src/list';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';

//import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext';

import UploadAdapter from './uploadAdapter';

import './editor.css';

export function FileUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new UploadAdapter(loader);
  };
}

const Editor = ({ content, setContent, setAttachedFileList, readOnly }) => {
  const isReadOnly = readOnly ? true : false;
  const onImageChange = (data) => {
    const { fileName, originName, extension } = data;

    setAttachedFileList((old) => [
      ...old,
      { url: data.default, fileName, originName, extension, downloadable: 0 },
    ]);
  };

  const isMobile = useMemo(() => (window.innerWidth < 600 ? true : false), []);

  function ImageUploadEditing(editor) {
    const imageUploadEditing = editor.plugins.get('ImageUploadEditing');
    imageUploadEditing.on('uploadComplete', (evt, { data, imageElement }) => {
      onImageChange(data);
    });
  }

  const editorConfiguration = {
    language: 'ko',
    extraPlugins: [FileUploadAdapterPlugin, ImageUploadEditing],
    plugins: [
      Essentials,
      Heading,
      Paragraph,
      Bold,
      Italic,
      Underline,
      Strikethrough,
      List,
      Alignment,
      Indent,
      IndentBlock,
      Link,
      Image,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      ImageResize,
      Font,
      Table,
      TableToolbar,

      //SimpleUploadAdapter,
    ],

    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'fontFamily',
      'fontSize',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'alignment',
      'outdent',
      'indent',
      'bulletedlist',
      'numberedlist',
      '|',
      'imageUpload',
      'link',
      'insertTable',
      '|',
      'undo',
      'redo',
    ],

    heading: {
      options: [
        {
          model: 'paragraph',
          view: 'p',
          title: '본문',
          class: 'ck-heading_paragraph',
        },
        {
          model: 'heading1',
          view: 'h1',
          title: '제목1',
          class: 'ck-heading_heading1',
        },
        {
          model: 'heading2',
          view: 'h2',
          title: '제목2',
          class: 'ck-heading_heading2',
        },
        {
          model: 'heading3',
          view: 'h3',
          title: '제목3',
          class: 'ck-heading_heading3',
        },
      ],
    },

    fontSize: {
      options: [10, 12, 'default', 16, 18, 20, 22, 24, 26, 28, 30],
    },

    fontFamily: {
      options: ['기본', '바탕', '돋움', '궁서체', 'Arial'],
    },

    image: {
      resizeUnit: 'px',
      toolbar: [
        'imageStyle:alignLeft',
        //'imageStyle:full',
        'imageStyle:alignRight',
        '|',
        'imageTextAlternative',
      ],
      styles: ['full', 'alignLeft', 'alignRight'],
    },

    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
  };

  return (
    <>
      <CKEditor
        disabled={isReadOnly}
        editor={ClassicEditor}
        config={editorConfiguration}
        data={content}
        onReady={(editor) => {
          editor.editing.view.change((writer) => {
            writer.setStyle(
              'min-height',
              isMobile ? '250px' : '500px',
              editor.editing.view.document.getRoot()
            );

            writer.setStyle(
              'font-size',
              isMobile ? '16px' : '14px',
              editor.editing.view.document.getRoot()
            );
          });

          const toolbarElement = editor.ui.view.toolbar.element;
          editor.on('set:isReadOnly', (evt, propertyName, isReadOnly) => {
            if (isReadOnly) {
              toolbarElement.style.display = 'none';
            } else {
              toolbarElement.style.display = 'flex';
            }
          });
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setContent && setContent(data);

          //const root = editor.editing.view.document.getRoot();
          //console.log(editor.getData());
        }}
      />
    </>
  );
};

export default Editor;
