import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  infoTypes: {
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'flex-end',
      height: '1.5rem',
    },
  },
  radio: {
    marginLeft: 0,
    [theme.breakpoints.down('xs')]: {
      marginRight: 8,
    },
  },
  label: {
    fontSize: '0.9rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
      lineHeight: 1,
    },
    '& svg': {
      width: '1rem',
    },
  },
}));

const Radiogroup = ({ value, setValue, groupRef }) => {
  const classes = useStyles();
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <RadioGroup
      className={classes.infoTypes}
      aria-label='기준'
      name='type'
      value={value}
      onChange={handleChange}
    >
      {groupRef.map((radio) => {
        return (
          <FormControlLabel
            className={classes.radio}
            classes={{ label: classes.label }}
            key={radio.value}
            value={radio.value}
            control={<PurpleRadio />}
            label={radio.label}
          />
        );
      })}
    </RadioGroup>
  );
};

export default Radiogroup;

const colors = {
  grey: '#454545',
  violet: '#8884d8',
};

const PurpleRadio = withStyles((theme) => ({
  root: {
    color: colors.grey,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingRight: 4,
    },
    '&$checked': {
      color: colors.violet,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
  },
  checked: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingRight: 4,
    },
  },
}))((props) => <Radio size='small' color='default' {...props} />);
