import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core';
import { colors } from '../../common/colors';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Noto Sans KR',
    fontSize: 16,
    padding: '1rem',
    paddingBottom: '1.6rem',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '1rem',
      fontSize: '15px',
    },
  },

  title: {
    color: colors.black700,
    fontSize: '0.875em',
  },

  badge: {
    display: 'block',
    fontSize: 11,
    color: colors.unosNavy,
  },

  contents: {
    paddingLeft: 20,
    fontSize: 18,
    color: colors.black400,
    overflow: 'auto',

    '& p': {
      marginBottom: 4,
    },

    '& span': {
      display: 'block',
      fontSize: 12,
      color: colors.black700,
      maxHeight: 80,
      overflow: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        marginTop: 8,
        marginBottom: 2,
      },
    },
  },
}));

function getBadgeColor(badge) {
  switch (badge) {
    case 'Best':
      return colors.green;

    case 'Worst':
      return colors.orange;

    case 'Today':
      return colors.violet;

    case 'Day':
      return colors.skyblue;

    default:
      return colors.unosNavy;
  }
}

const CardItem = ({ card }) => {
  const classes = useStyles();
  const { title, badge, contents, subContents } = card;

  return (
    <Card
      className={classes.cardContainer}
      variant='outlined'
      style={{ borderLeft: `2px solid ${getBadgeColor(badge)}` }}
    >
      <div className={classes.title}>
        <Badge badge={badge} />
        {title}
      </div>
      <div className={classes.contents}>
        <p>{contents}</p>
        <span>{subContents}</span>
      </div>
    </Card>
  );
};
//className={`${styles.badge} ${getBackgroundColor(badge)}`}

export default CardItem;

const Badge = ({ badge }) => {
  const classes = useStyles();

  return (
    <span
      className={classes.badge}
      style={{
        color: getBadgeColor(badge),
      }}
    >
      {badge}
    </span>
  );
};
