import React from 'react';

import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

export const menu = [
  {
    id: 1,
    title: 'Home',
    url: '/home',
  },
  {
    id: 2,
    title: '매출조회',
    items: [
      {
        id: 1,
        title: '기간별 조회',
        url: '/sales/term',
      },
      {
        id: 2,
        title: '상품별 조회',
        url: '/sales/md',
      },
      {
        id: 3,
        title: '매장별 조회',
        url: '/sales/store',
        role: ['master', 'investor'],
      },
      {
        id: 4,
        title: '캘린더 조회',
        url: '/sales/calendar',
      },
      {
        id: 5,
        title: '카테고리별 조회',
        url: '/sales/category',
      },
      {
        id: 6,
        title: '결제수단별 조회',
        url: '/sales/paytype',
      },
      {
        id: 7,
        title: '비교 조회',
        url: '/sales/contrast',
      },
      {
        id: 8,
        title: '영수건별 조회',
        url: '/sales/realtime',
      },
      {
        id: 9,
        title: '기기별 조회',
        url: '/sales/device',
        role: ['common'],
      },
    ],
  },
  {
    id: 2,
    title: '재고관리',
    items: [
      {
        id: 1,
        title: '재고조회',
        url: '/stock/check',
      },
      {
        id: 2,
        title: '재고수정/품절 관리',
        url: '/menu/soldout',
        role: ['common'],
      },
      {
        id: 3,
        title: '품절기록 조회',
        url: '/stock/soldout',
      },
    ],
  },
  {
    id: 3,
    title: '메뉴수정',
    url: '/menu/modify',
    role: ['common', 'investor'],
  },
  {
    id: 4,
    title: '옵션 전체수정',
    url: '/option/modify',
    role: ['common', 'investor'],
  },
  {
    id: 5,
    title: '부가세 신고자료 요청',
    url: '/tax_request',
    role: ['common'],
  },
  {
    id: 6,
    title: '공지사항',
    url: '/board/notice',
  },
  /* {
    id: 6,
    title: 'A/S',
    url: 'http://www.unospay.com/ser_as.html',
    isBlank: true,
  }, */
  {
    id: 7,
    title: '우노스 스토어',
    url: 'https://smartstore.naver.com/unospay',
    isBlank: true,
  },
  {
    id: 8,
    title: '매뉴얼 다운로드',
    url: '/manual',
  },
];

export const studyMenus = [
  {
    id: 10,
    icon: <PlaylistAddCheckIcon />,
    title: '백신패스 관리',
    url: '/vaccine_pass',
  },
];

export const menus = [
  {
    title: 'Home',
    url: '/home',
  },
  {
    title: '기간별 조회',
    url: '/sales/term',
  },
  {
    title: '상품별 조회',
    url: '/sales/md',
  },
  {
    title: '매장별 조회',
    url: '/sales/store',
  },
  {
    title: '비교 조회',
    url: '/sales/contrast',
  },
  {
    title: '카테고리별 조회',
    url: '/sales/category',
  },
  {
    title: '결제수단별 조회',
    url: '/sales/paytype',
  },
  {
    title: '캘린더 조회',
    url: '/sales/calendar',
  },
  {
    title: '영수건별 조회',
    url: '/sales/realtime',
  },
  {
    title: '기기별 조회',
    url: '/sales/device',
  },
  {
    title: '재고조회',
    url: '/stock/check',
  },
  {
    title: '재고수정/품절 관리',
    url: '/menu/soldout',
  },
  {
    title: '품절기록 조회',
    url: '/stock/soldout',
  },
  {
    title: '메뉴수정',
    url: '/menu/modify',
  },
  {
    title: '메뉴수정new',
    url: '/menu/modify_new',
  },
  {
    title: '옵션 전체수정',
    url: '/option/modify',
  },
  {
    title: '부가세 신고자료 요청',
    url: '/tax_request',
  },
  {
    title: '매뉴얼 다운로드',
    url: '/manual',
  },
  /* {
    title: '백신패스 관리',
    url: '/vaccine_pass',
  }, */
  {
    title: '공지사항',
    url: '/board/notice',
  },
  {
    title: '공지사항',
    url: '/board/view',
  },
  {
    title: '공지사항',
    url: '/board/new',
  },
  {
    title: '공지사항',
    url: '/board/modify',
  },
];
