import axios from 'axios';

export const httpClient = axios.create({
  baseURL: 'https://sales.unospay.com/api',
  //withCredentials: true,
});

/* httpClient.interceptors.request.use(function (config) {
  //Do something before request is sent
  const accessToken = localStorage.getItem('accessToken');
  config.headers.Authorization = 'Bearer ' + accessToken;

  return config;
});
 */
