import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  select: {
    outline: 'none',
    border: 'none',
    backgroundColor: 'f6f6f6',
    padding: '2px',

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const EditCellSelect = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    const newValue = e.target.value;

    setValue(newValue);
    updateData(index, id, newValue * 1);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <select
      className={classes.select}
      value={value}
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
    >
      <option value='-1'>모두노출</option>
      <option value='0'>매장만노출</option>
      <option value='1'>포장만노출</option>
    </select>
  );
};

export default EditCellSelect;
