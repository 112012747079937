import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';

import { getPageIcon } from '../../common/icons';

import styles from './page_header.module.css';

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '3.5rem',
    height: '3.5rem',
    padding: '0.7rem',
    fontSize: '1.5rem',
    color: '#454545',
    borderRadius: '12px',
    background: '#fff',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
    marginRight: 12,

    [theme.breakpoints.down('sm')]: {
      width: '2rem',
      height: '2rem',
      padding: '0.2rem',
    },

    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

const PageHeader = memo(({ title }) => {
  const description = pageInfos[title];

  return (
    <>
      <div className={styles.titleBox}>
        <TitleIcon title={title} />
        <span className={styles.title}>{title}</span>
      </div>

      {description && (
        <ul className={styles.description}>
          {description.map((row, index) => (
            <li key={index}>{row}</li>
          ))}
        </ul>
      )}
    </>
  );
});

export default PageHeader;

const pageInfos = {
  '카테고리별 조회': [
    '※ 2021년 4월 9일 업데이트 이후 매출 건부터 정상 집계됩니다.',
    '※ 키오스크/POS 업데이트가 미진행된 매장의 경우 카테고리명이 반영되지 않습니다.',
  ],
  '품절기록 조회': [
    '※ 2021년 6월 24일 업데이트 이후부터 기록 조회가 가능합니다.',
  ],
  '옵션 전체수정': [
    '※ 아래 사항 중 하나라도 다르면 다른 옵션으로 취급됩니다.',
    '- 옵션명(한,영,일,중), 가격, 그룹명(한,영,일,중), 최대/최소 선택, New, Best, Sale, 한정, 컵 갯수, 품절/출력 여부',
  ],
};

function TitleIcon({ title }) {
  const classes = useStyles();
  return <div className={classes.icon}>{getPageIcon(title)}</div>;
}
