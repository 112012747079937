import React, { useEffect, useState, useMemo, useRef } from 'react';

import {
  useTable,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  useFilters,
} from 'react-table';

import { useVirtual } from 'react-virtual';

import SearchIcon from '@material-ui/icons/Search';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import { makeStyles } from '@material-ui/core';

import { getSessionData, setSessionData } from '../../common/session';
import { colors } from '../../common/colors';

const tableMaxHeight = '20rem';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: '43rem',
    minHeight: '22rem',
    margin: '0',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('xs')]: {
      maxHeight: tableMaxHeight,
    },
  },

  tableContainerXS: {
    //overflow: 'hidden',
    maxWidth: '100%',
    maxHeight: '18rem',
  },

  table: {
    width: '100%',
    padding: 0,
    userSelect: 'none',
    borderCollapse: 'collapse',
  },

  tableHeader: {
    '& tr': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 11,
      backgroundColor: colors.lightestVioletGrey,
      boxShadow: `0px 1px 0px ${colors.greyViolet}`,
    },

    '& tr th': {
      whiteSpace: 'nowrap',
      padding: '0.5rem',
      fontWeight: 300,
    },
  },

  tableBody: {
    fontWeight: 400,

    '& tr': {
      borderBottom: `1px solid ${colors.greyViolet}`,
    },

    '& tr:first-child': {
      minWidth: 100,
    },

    '& tr:last-child': {
      borderBottom: 'none',
    },

    '& tr td': {
      padding: '6px 4px',
    },

    [theme.breakpoints.up('md')]: {
      '& tr:hover': {
        backgroundColor: colors.borderLightViolet,
      },
    },
  },

  row: {
    '& td': {
      textAlign: 'center',
      padding: '12px 4px',
      minWidth: 50,
    },
  },

  selectedRow: {
    backgroundColor: colors.borderLightViolet,
  },

  //search
  searchBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem',
    paddingTop: 0,
  },

  search: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 'fit-content',
    padding: 6,
    border: `1px solid ${colors.iconLightGrey}`,
    borderRadius: 4,

    '&:hover, &:focus-visible, &:active': {
      border: `1px solid ${colors.violet}`,
    },

    '& input': {
      outline: 'none',
      border: 'none',
      //fontSize: 16,
      paddingLeft: 8,
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      border: `1px solid ${colors.borderLightViolet}`,

      '& input': {
        transform: 'scale(0.87)',
        width: '100%',
      },
    },
  },

  clearIcon: {
    cursor: 'pointer',
    color: colors.grey,

    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1rem',
    },
  },

  empty: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1',

    '& svg': {
      fontSize: '4rem',
      color: colors.iconLightGrey,
      marginBottom: 10,
    },
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type='checkbox' ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const EditiableWithSelectTable = ({
  xs,
  search,
  tableData,
  selectedRow,
  setSelectedRow,
  isSingleSelect,
  isSorted,
  isVirtual,
  placeholder,
}) => {
  const classes = useStyles();

  const data = useMemo(() => tableData.data, [tableData.data]);
  const columns = useMemo(() => tableData.columns, [tableData.columns]);

  const savedSearchWord = getSessionData('modifySearch');
  const globalFilter = search && savedSearchWord ? savedSearchWord : '';
  const savedSelectCategory = getSessionData('modifySelectCategory');
  const filters =
    search && savedSelectCategory && savedSelectCategory.value !== ''
      ? [savedSelectCategory]
      : [];

  const selectedRowCodes =
    selectedRow && selectedRow.map((row) => row.original.code);

  const selectedRowsIndex = selectedRowCodes
    ? selectedRowCodes.reduce((res, code) => {
        res = { ...res, [data.findIndex((row) => row.code === code)]: true };
        return res;
      }, {})
    : {};

  //columnFilter
  function DefaultColumnFilter() {
    return null;
  }

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: ['sortCode', 'sort_code'],
        sortBy: isSorted
          ? isSorted
          : [
              { id: 'sortCode', desc: false },
              { id: 'sort_code', desc: false },
              { id: 'group_name', desc: false },
            ],
        selectedRowIds: selectedRowsIndex,
        globalFilter,
        filters,
        autoResetSortBy: false,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      !isSingleSelect &&
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                선택
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds },
    selectedFlatRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    toggleAllRowsSelected,
  } = tableInstance;

  const parentRef = useRef();
  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
    overscan: 20,
    //estimateSize: React.useCallback(() => 50, []),
  });

  const items = rowVirtualizer.virtualItems;
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom =
    items.length > 0
      ? rowVirtualizer.totalSize - items[items.length - 1].end
      : 0;

  useEffect(() => {
    setSelectedRow(selectedFlatRows);
    return () => setSelectedRow([]);
  }, [selectedRowIds]);

  return (
    <>
      {search && (
        <div className={classes.searchBox}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            placeholder={placeholder}
          />
        </div>
      )}

      <div
        ref={parentRef}
        className={[
          classes.tableContainer,
          xs ? classes.tableContainerXS : ' ',
        ].join(' ')}
      >
        <table className={classes.table} {...getTableProps()}>
          <thead className={classes.tableHeader}>
            {headerGroups.map((headerGroup) => {
              const { key: headerGroupKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr key={headerGroupKey} {...headerGroupProps}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={classes.headerCell}
                      {...column.getHeaderProps({
                        style: { minWidth: column.minWidth || 60 },
                      })}
                    >
                      {column.render('Header')}
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody className={classes.tableBody} {...getTableBodyProps()}>
            {isVirtual ? (
              <>
                {paddingTop > 0 && (
                  <tr>
                    <td style={{ height: `${paddingTop}px` }} />
                  </tr>
                )}

                {rowVirtualizer.virtualItems.map((virtualRow) => {
                  const row = rows[virtualRow.index];
                  prepareRow(row);
                  return (
                    <tr
                      key={virtualRow.index}
                      ref={virtualRow.measureRef}
                      {...row.getRowProps({
                        onClick: () => {
                          if (isSingleSelect) {
                            toggleAllRowsSelected(false);
                            row.toggleRowSelected();
                          } else {
                            row.toggleRowSelected();
                          }
                        },
                      })}
                      className={[
                        classes.row,
                        row.isSelected ? classes.selectedRow : '',
                      ].join(' ')}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}

                {paddingBottom > 0 && (
                  <tr>
                    <td style={{ height: `${paddingBottom}px` }} />
                  </tr>
                )}
              </>
            ) : (
              <>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      className={[
                        classes.row,
                        row.isSelected ? classes.selectedRow : '',
                      ].join(' ')}
                      {...row.getRowProps({
                        onClick: () => {
                          if (isSingleSelect) {
                            toggleAllRowsSelected(false);
                            row.toggleRowSelected();
                          } else {
                            row.toggleRowSelected();
                          }
                        },
                      })}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EditiableWithSelectTable;

function GlobalFilter({ globalFilter, setGlobalFilter, placeholder }) {
  const classes = useStyles();
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    setSessionData('modifySearch', value);
  }, 200);

  const onClear = () => {
    setValue('');
    onChange('');
  };

  return (
    <div className={classes.search}>
      <SearchIcon />
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={placeholder || '메뉴 등 검색'}
      />
      {value ? (
        <ClearOutlinedIcon onClick={onClear} className={classes.clearIcon} />
      ) : (
        <span style={{ width: 24 }}></span>
      )}
    </div>
  );
}
