import React, { useEffect, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';

import ModalPortal from '../modal_portal/modal_portal';
import ModifyForm from '../modify_form/modify_form';

import MenuService from '../../service/menu_service';
import OptionService from '../../service/option_service';

import { colors } from '../../common/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'inherit',
    maxWidth: 800,
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  title: {
    padding: '1.3rem 1.2rem',
    margin: 0,
    borderBottom: '1px solid #c4c4c4',
  },

  formBox: {
    overflow: 'auto',
    paddingBottom: 0,
    maxHeight: '65vh',

    '&:last-child': {
      marginBottom: 0,
    },
  },

  closeIcon: {
    position: 'absolute',
    right: 20,
    top: 20,
    cursor: 'pointer',
  },

  actionBtns: {
    padding: '1rem 1.4rem',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '2%',
    borderTop: '1px solid #c4c4c4',
  },

  actionBtn: {
    flexBasis: '40%',
    flex: 1,
    padding: '0.6rem',
    borderRadius: 0,
    border: '1px solid #888',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    /* transition: 'all 200ms ease-in', */

    '&:hover': {
      border: `1px solid ${colors.borderDarkViolet}`,
      backgroundColor: colors.borderDarkViolet,
      color: colors.white,
    },
  },

  cancelBtn: {
    '&:hover': {
      border: `1px solid ${colors.black700}`,
      backgroundColor: colors.lightGrey,
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },

  activeBtn: {
    color: colors.white,
    backgroundColor: colors.darkViolet600,

    '&:hover': {
      color: colors.white,
      backgroundColor: colors.darkViolet,
    },
  },
}));

const ModifyModal = ({
  open,
  setOpen,
  title,
  item,
  onValidate,
  onSave,
  affiliate,
  isLock,
  loading,
  categoryList,
  menuList,
}) => {
  const classes = useStyles();
  const [newItem, setNewItem] = useState(item);
  const [error, setError] = useState({});

  /* menu */
  const [menuCode, setMenuCode] = useState(null);

  //image
  const [menuImage, setMenuImage] = useState({
    loading: false,
    image: null,
    uploadFile: null,
    origin: null,
  });

  const getMenuImage = async (menuCode) => {
    if (!menuCode || title !== '메뉴 수정') return;

    setMenuImage((old) => ({ ...old, loading: true }));
    await MenuService.getMenuImage(menuCode).then((res) => {
      let image = null;
      const { data, error } = res;

      if (!error) {
        const base64 = data.image;
        if (base64) {
          image = `data:image/png;base64,${base64}`;
        }
      }

      setMenuImage({ loading: false, image, origin: image });
    });
  };

  //  option
  const [originOptionList, setOriginOptionList] = useState(null);
  const [newOptionList, setNewOptionList] = useState(null);
  const [isOptionUpdate, setIsOptionUpdate] = useState(false);

  const getMenuOption = async (menuCode) => {
    if (!menuCode || !affiliate.id) return;
    await OptionService.getOptionList(affiliate.id, 'one', menuCode)
      .then((res) => {
        //console.log(res);
        let { data, error } = res;
        if (!error) setOriginOptionList(data);
      })
      .catch((err) => console.log(err));
  };

  const isDifferent = (origin, newOne) => {
    return JSON.stringify(origin) !== JSON.stringify(newOne);
  };

  const isUpdate = () => {
    if (!item || !newItem) return false;

    if (!isDifferent(item, newItem)) return false;

    let diff = false;
    Object.keys(item).map((key) => {
      if (item[key] !== newItem[key]) {
        diff = { ...diff, [key]: newItem[key] };
      }
    });

    return diff;
  };

  const getUpdatedOptions = () => {
    if (!originOptionList) return;

    if (!isDifferent(originOptionList, newOptionList)) return [];

    let updated = [];
    newOptionList.forEach((newOption) => {
      let origin = originOptionList.find(
        (originOption) => originOption.code === newOption.code
      );

      if (!origin || isDifferent(origin, newOption)) {
        updated.push(newOption);
      }
    });

    //console.log('getUpdatedOptions updated: ', updated);
    return updated;
  };

  const handleSave = (e) => {
    e.preventDefault();
    //console.log('handleSave', newItem);
    //check update
    let isUpdated = false;
    let isOptionUpdated = isOptionUpdate;
    let isImageUpdated = false;

    if (title.includes('메뉴')) {
      if (menuImage.image !== menuImage.origin) {
        isImageUpdated = true;
      }
    }

    //메뉴 수정 시 옵션 validation
    if (title === '메뉴 수정') {
      let isOptionValid = true;

      for (let i = 0; i < newOptionList.length; i++) {
        let { menu_option, option_price, min_select, max_select } =
          newOptionList[i];

        if (!Number.isInteger(option_price * 1)) {
          isOptionValid = false;
          alert(`옵션 가격을 확인해주세요.(옵션이름: ${menu_option})`);
          break;
        }

        if (!Number.isInteger(min_select * 1)) {
          isOptionValid = false;
          alert(`최소 선택을 확인해주세요.(옵션이름: ${menu_option})`);
          break;
        }

        if (!Number.isInteger(max_select * 1)) {
          isOptionValid = false;
          alert(`최대 선택을 확인해주세요.(옵션이름: ${menu_option})`);
          break;
        }
      }

      if (!isOptionValid) return;

      let updatedOptionList = getUpdatedOptions();
      if (updatedOptionList.length !== 0) {
        isOptionUpdated = true;
        OptionService.updateOption(updatedOptionList).then((res) => {});
      }

      newItem.optionCode = newOptionList.map((option) => option.code).join(',');
    }

    isUpdated = isUpdate();
    if (!isUpdated && !isOptionUpdated && !isImageUpdated) {
      alert('수정된 내용이 없습니다.');
      return;
    }

    //validate
    const isError = onValidate(newItem);
    setError(isError);
    if (Object.keys(isError).length !== 0) return;

    if (title.includes('옵션수정')) {
      onSave(title, isUpdated);
    } else {
      onSave(title, {
        ...newItem,
        isImageUpdated,
        menuImage: menuImage.uploadFile,
      });
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    const isMenuUpdated = isUpdate();
    if (
      isMenuUpdated ||
      isOptionUpdate ||
      (title.includes('메뉴') && newItem.isImageUpdate)
    ) {
      let confirm = window.confirm(
        '수정된 내용이 있습니다. 저장하지 않고 종료하시겠습니까?'
      );
      if (!confirm) return;
    }

    setNewItem(null);
    setOpen(false);
  };

  useEffect(() => {
    //prevent background scroll
    if (open) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    } else {
      document.body.style.overflow = `unset`;
      document.body.style.height = 'unset';
    }

    return () => (document.body.style = `overflow: unset`);
  }, [open]);

  useEffect(() => {
    if (!title || !open) return;

    if (title.includes('메뉴')) {
      //console.log(newItem);
      getMenuImage(newItem.code);
      if (title === '메뉴 수정') {
        getMenuOption(newItem.code);
      }
    }
  }, [open, title]);

  return (
    <ModalPortal setOpen={setOpen}>
      <div className={classes.container}>
        <div className={classes.titleBox}>
          <h2 className={classes.title}>{title || ''}</h2>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        </div>

        <div className={classes.formBox}>
          {newItem && (
            <ModifyForm
              title={title}
              newItem={newItem}
              setNewItem={setNewItem}
              isLock={isLock}
              error={error}
              categoryList={categoryList || null}
              menuList={menuList || null}
              affiliate={affiliate}
              optionList={originOptionList}
              setUpdatedOption={setNewOptionList}
              menuImage={menuImage}
              setMenuImage={setMenuImage}
              setIsOptionUpdate={setIsOptionUpdate}
            />
          )}
        </div>

        <div className={classes.actionBtns}>
          <button
            className={`${classes.actionBtn} ${classes.cancelBtn}`}
            onClick={handleClose}
            disabled={loading ? true : false}
          >
            취소
          </button>

          <button
            className={`${classes.actionBtn} ${
              loading ? classes.activeBtn : ''
            }`}
            onClick={handleSave}
            disabled={loading ? true : false}
          >
            {loading ? '저장중...' : '저장'}
          </button>
        </div>
      </div>
    </ModalPortal>
  );
};

export default ModifyModal;
