import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';

import InfoService from '../../service/info_service';
import CategoryService from '../../service/category_service';
import MenuService from '../../service/menu_service';
import OptionService from '../../service/option_service';

import ModifyModal from '../../component/modify_modal/modify_modal';
import EditCellSelect from '../../component/edit_cell_select/edit_cell_select';

import ActionButton from '../../component/action_button/action_button';
import SimplePopper from '../../component/simple_popper/simple_popper';
import EditCellCheckbox from '../../component/edit_cell_checkbox/edit_cell_checkbox';
import EditiableWithSelectTable from '../../component/editable_with_select_table/editiable_with_select_table';
import FooterToolbar from '../../component/footer_toolbar/footer_toolbar';

import { defaultCategory, defaultMenu } from '../menu_modify/menuRef';
import { swipeRowSortCode } from '../../component/detail_table/table';

import { toCurrency } from '../../common/format';
import { colors } from '../../common/colors';

import { useAuth } from '../../Context/Auth';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },

  infoContainer: {
    display: 'flex',
    width: '100%',
    padding: '1.6rem 1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.borderLightGrey}`,
    marginBottom: 10,

    [theme.breakpoints.down('xs')]: {
      padding: '0.5rem',
      flexFlow: 'wrap-reverse',
      marginTop: 0,
    },
  },

  infoBox: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 12,
    flexWrap: 'wrap',

    '& h3': {
      fontSize: 20,
      fontWeight: 400,
      margin: 0,
    },

    '& span': {
      fontSize: 15,
      color: colors.black700,
    },

    [theme.breakpoints.down('xs')]: {
      gap: 0,

      '& h3': {
        width: '100%',
      },
    },
  },

  infoSelectBox: {
    display: 'flex',
    justifyContent: 'flex-end',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'flex-start',
    },
  },

  tableContainer: {
    padding: '1rem',
    margin: '1rem 0',
    borderRadius: 10,
    backgroundColor: '#fff',

    [theme.breakpoints.down('md')]: {
      padding: '1rem 0',
    },
  },

  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    paddingBottom: '2rem',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '0.7rem',
    },
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
  },

  refresh: {
    cursor: 'pointer',
    color: colors.black900,
    marginLeft: 4,

    '&:hover': {
      color: colors.darkViolet600,
    },
  },

  rotate: {
    color: colors.darkViolet600,
    animation: `$spin 1000ms ${theme.transitions.easing.easeIn} infinite`,
  },

  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

const MenuModifyV2 = () => {
  const classes = useStyles();
  const history = useHistory();

  //user info - select affiliate and get info
  const affiliateRef = useRef();
  const auth = useAuth();
  const user = auth.user || null;

  const [affiliate, setAffiliate] = useState({ id: '', name: '' });
  const [affiliateInfo, setAffiliateInfo] = useState({
    master: '',
    isLock: false,
    isMenuMove: true,
    isMenuVisible: true,
    isMembershipLock: false,
  });

  const [affiliateList, setAffiliateList] = useState(null);
  const handleSelect = (e) => {
    e.preventDefault();

    const value = affiliateRef.current.value.split('||');
    let id = value[0];
    let name = value[1];

    setAffiliate({ id, name });
  };

  const goToLogin = () => {
    alert('세션이 만료되어 재로그인이 필요합니다.');
    history.push('/login');
  };

  const getAffiliateInfo = async (id) => {
    if (!id) return;

    await InfoService.getAffilaiteInfo(id).then((res) => {
      let { data, error } = res;

      if (error && res.message === 'Session expired') {
        goToLogin();
        return;
      }

      if (!error) {
        let { master, lock } = data;

        let isLock = lock ? true : false;
        /* 메뉴 순서변경 - lock 기본 : 불가능 */
        let isMenuMove = !isLock
          ? true
          : master === 'no1juicy_master' ||
            master === 'juicyxchayam_master' ||
            master === 'chayam_master'
          ? true
          : false;

        /* 메뉴 visible 변경 - lock 기본 : 가능 */
        let isMenuVisible = !isLock
          ? true
          : master !== 'eggdrop_master'
          ? true
          : false;

        /* 멤버십 적립 lock */
        let isMembershipLock =
          master === 'no1juicy_master' ||
          master === 'juicyxchayam_master' ||
          master === 'chayam_master' ||
          id === 'test_demo3';

        setAffiliateInfo({
          master,
          isLock,
          isMenuMove,
          isMenuVisible,
          isMembershipLock,
        });
      }
    });
  };

  //category
  const [category, setCategory] = useState({ list: null, loading: false });
  const [newCategoryList, setNewCategoryList] = useState(null);

  const categoryColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'sortCode',
      },
      {
        Header: '카테고리명',
        accessor: 'name',
        minWidth: 120,
      },
      {
        Header: '영어',
        accessor: 'nameEng',
        minWidth: 120,
      },
      {
        Header: '일본어',
        accessor: 'nameJp',
        minWidth: 120,
      },
      {
        Header: '중국어',
        accessor: 'nameCh',
        minWidth: 120,
      },
      {
        Header: (
          <div className={classes.cellHeaderWithIcon}>
            키오스크 노출
            <SimplePopper contentsKey='visible' />
          </div>
        ),
        accessor: 'visible',
        Cell: (props) => (
          <EditCellCheckbox updateData={onChangeCategory} {...props} />
        ),
      },
    ],
    []
  );

  const [categoryTable, setCategoryTable] = useState({
    data: [],
    columns: categoryColumns,
  });
  const [selectedCategory, setSelectedCategory] = useState([]);

  const getCategoryList = async (id) => {
    if (!id) return;

    setCategory((old) => ({ ...old, loading: true }));
    await CategoryService.getCategoryList(id, 'detail')
      .then((res) => {
        const { data, error } = res;
        if (error) {
          if (error.mesage === 'Session expired') {
            alert('세션이 만료되어 로그인이 필요합니다.');
            history.push('/login');
            return;
          }
        }

        if (data) {
          setCategory({ list: data, loading: false });
          setNewCategoryList(data);
        }
      })
      .catch((err) => {
        //console.log(err);
        setCategory((old) => ({ ...old, loading: false }));
      });
  };

  const refreshCategory = (e) => {
    e.preventDefault();
    if (!affiliate && !affiliate.id) return;

    getCategoryList(affiliate.id);
  };

  const refreshMenu = (e) => {
    e.preventDefault();

    if (!affiliate && !affiliate.id) return;
    getMenuList(affiliate.id);
  };

  const onChangeCategory = (rowIndex, columnId, value) => {
    setNewCategoryList((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const addOrUpdate = (title, newItem) => {
    //console.log(title, newItem);
    let action = title.includes('추가')
      ? 'add'
      : title.includes('수정')
      ? 'modify'
      : 'orderOrVisible';

    if (title.includes('카테고리')) {
      addOrUpdateCategory(action, newItem);
    } else {
      addOrUpdateMenu(action, newItem);
    }
  };

  const addOrUpdateCategory = async (action, newCategoryList) => {
    if (action !== 'orderOrVisible' && affiliateInfo.isLock) {
      alert('카테고리 수정이 제한된 매장입니다.');
      return;
    }

    setModalLoading(true);
    await CategoryService.addOrUpdateCategory(
      action,
      newCategoryList,
      affiliate.id
    )
      .then((res) => {
        const { data, error } = res;
        if (error) {
          if (error.message === 'Session expired') {
            goToLogin();
          }
          return;
        }

        getCategoryList(affiliate.id);
        alert(`${action === 'add' ? '추가' : '수정'} 완료`);
        setOpen(false);
        setSelectedCategory([]);
      })
      .catch((err) => console.log(err))
      .finally(() => setModalLoading(false));
  };

  //menu
  const [menu, setMenu] = useState({ list: [], loading: false });
  const [newMenuList, setNewMenuList] = useState(null);

  const [menuImageList, setMenuImageList] = useState(null);
  const [menuImage, setMenuImage] = useState({ loading: false, image: null });

  const onChangeMenu = (rowIndex, columnId, value) => {
    setNewMenuList((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const menuColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'sortCode',
      },
      {
        Header: '카테고리',
        accessor: 'categoryName',
        minWidth: 120,
        Filter: (props) => (
          <SelectColumnFilter setSelectedFilter={setFilterMenu} {...props} />
        ),
        filter: 'equals',
      },
      {
        Header: '메뉴명',
        accessor: 'name',
        minWidth: 150,
      },
      {
        Header: '가격',
        accessor: 'price',
        Cell: (props) => toCurrency(props.value) || 0,
      },
      {
        Header: '면세금액',
        accessor: 'noTaxPrice',
        Cell: (props) => toCurrency(props.value) || 0,
      },
      {
        Header: '품절',
        accessor: 'soldOut',
        Cell: (props) => (
          <EditCellCheckbox updateData={onChangeMenu} {...props} />
        ),
      },
      {
        Header: (
          <div className={classes.cellHeaderWithIcon}>
            키오스크 노출
            <SimplePopper contentsKey='visible' />
          </div>
        ),
        accessor: 'visible',
        Cell: (props) => (
          <EditCellCheckbox
            updateData={onChangeMenu}
            disable={!affiliateInfo.isMenuVisible}
            {...props}
          />
        ),
      },
      {
        Header: (
          <div className={classes.cellHeaderWithIcon}>
            멤버십적립
            <SimplePopper contentsKey='membership' />
          </div>
        ),
        accessor: 'saveStamp',
        Cell: (props) => (
          <EditCellCheckbox
            updateData={onChangeMenu}
            disable={affiliateInfo.isMembershipLock}
            {...props}
          />
        ),
      },
      {
        Header: (
          <div className={classes.cellHeaderWithIcon}>
            포장매장구분
            <SimplePopper contentsKey='package_flag' />
          </div>
        ),
        accessor: 'packageFlag',
        Cell: (props) => (
          <EditCellSelect updateData={onChangeMenu} {...props} />
        ),
      },
      {
        Header: '영어',
        accessor: 'nameEng',
        minWidth: 130,
      },
      {
        Header: '일본어',
        accessor: 'nameJp',
        minWidth: 130,
      },
      {
        Header: '중국어',
        accessor: 'nameCh',
        minWidth: 130,
      },
      {
        Header: '주방쪽 별칭',
        accessor: 'nameForKitchen',
      },
      {
        Header: '외부코드',
        accessor: 'externalCode',
      },
      {
        Header: '기프티콘 코드',
        accessor: 'gifticonCode',
      },
      {
        Header: '바코드',
        accessor: 'barcode',
        minWidth: 130,
      },
      {
        Header: '라벨출력장수',
        accessor: 'labelCnt',
      },
    ],
    [affiliateInfo]
  );

  const isImageExist = (menuCode) => {
    return menuImageList &&
      menuImageList.findIndex((menu) => menu.menuCode === menuCode) !== -1
      ? true
      : false;
  };

  const menuImageColumn = [
    {
      Header: '이미지',
      accessor: 'code',
      Cell: (props) => (isImageExist(props.value) ? 'O' : ''),
    },
  ];

  //printer
  const [printerList, setPrinterList] = useState([]);
  const getPrinterList = (id) => {
    if (!id) return;

    InfoService.getPrinterList(id).then((res) => {
      const { data, error } = res;
      if (error) {
        if (error.message === 'Session expired') {
          goToLogin();
        }
        return;
      }
      //console.log(data);
      setPrinterList(data);
    });
  };

  const updatePrinter = (rowIndex, columnId, value) => {
    setNewMenuList((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          const rowPrinterCodes = old[rowIndex].printerCode;

          let codesArr =
            !rowPrinterCodes || rowPrinterCodes === ''
              ? []
              : rowPrinterCodes.split(',');

          if (value === 0) {
            codesArr = codesArr.filter((code) => code * 1 !== columnId);
          } else {
            codesArr.push(columnId);
          }

          return {
            ...old[rowIndex],
            printerCode: codesArr.join(','),
          };
        }
        return row;
      })
    );
  };

  const printerColumns = useMemo(() => {
    if (!printerList) return;

    let columns = printerList.map((printer, index) => {
      return {
        id: printer.code,
        Header: printer.name || `printer${index + 1}`,
        accessor: 'printerCode',
        Cell: (props) => {
          let value = props.value?.includes(printer.code) ? 1 : 0;
          return (
            <EditCellCheckbox
              {...props}
              updateData={updatePrinter}
              value={value}
            />
          );
        },
      };
    });
    return columns;
  }, [printerList]);

  const [menuTable, setMenuTable] = useState({
    data: [],
    columns: menuColumns,
  });
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [filterMenu, setFilterMenu] = useState(null);

  const getMenuImageList = (menuCodes) => {
    if (!menuCodes) {
      setMenuImageList([]);
      return;
    }

    MenuService.getMenuImageList(menuCodes).then((res) =>
      setMenuImageList(res.data)
    );
  };

  const isSubName = (name) => {
    return name?.includes('\r\n');
  };

  const divideSubName = (menu) => {
    let { name, nameEng, nameJp, nameCh } = menu;
    let subName = '',
      subNameEng = '',
      subNameJp = '',
      subNameCh = '';

    if (isSubName(name)) {
      const nameArr = name.split('\r\n');
      name = nameArr[0];
      subName = nameArr.slice(1).join('\r\n');
    }

    if (isSubName(nameEng)) {
      const nameArr = nameEng.split('\r\n');
      nameEng = nameArr[0];
      subNameEng = nameArr.slice(1).join('\r\n');
    }

    if (isSubName(nameJp)) {
      const nameArr = nameJp.split('\r\n');
      nameJp = nameArr[0];
      subNameJp = nameArr.slice(1).join('\r\n');
    }

    if (isSubName(nameCh)) {
      const nameArr = nameCh.split('\r\n');
      nameCh = nameArr[0];
      subNameCh = nameArr.slice(1).join('\r\n');
    }

    return {
      ...menu,
      name,
      nameEng,
      nameJp,
      nameCh,
      subName,
      subNameEng,
      subNameJp,
      subNameCh,
    };
  };

  const getMenuList = async (id) => {
    if (!id) return;

    setMenu((old) => ({ ...old, loading: true }));
    await MenuService.getMenuList(id, 'detail')
      .then((res) => {
        //console.log('getMenuList...', res);
        let { error, data } = res;
        if (error) {
          if (error.message === 'Session expired') {
            alert('세션이 만료되어 로그인이 필요합니다.');
            history.push('/login');
          }
          return;
        }

        if (data) {
          setMenu((old) => ({ ...old, list: data }));
          setNewMenuList(data);

          const menuCodes = data.map((menu) => menu.code).join(',');
          const imageList = getMenuImageList(menuCodes);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setMenu((old) => ({ ...old, loading: false })));
  };

  //modify popup
  const [modalLoading, setModalLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modifyItem, setModifyItem] = useState(null);

  const onAction = (props) => {
    const splits = props.split('/');
    let type = splits[0];
    let action = splits[1];

    let item = null;
    if (type === 'category') {
      if (action === 'add') {
        item = defaultCategory;
      } else {
        item = selectedCategory[0].original;
      }
    } else {
      //menu add or modify
      if (action === 'add') {
        item = { ...defaultMenu, image: null };
      } else {
        item = divideSubName(selectedMenu[0].original);
        item.image = isImageExist(item.code);
      }
    }

    setModifyItem({
      item,
      title: `${type === 'category' ? '카테고리' : '메뉴'} ${
        action === 'add' ? '추가' : '수정'
      }`,
    });

    setOpen(true);
  };

  const isNullOrEmpty = (value) => {
    if (value === '' || value === null) return true;
    return false;
  };

  const onValidate = (item) => {
    let error = {};

    if (modifyItem.title.includes('카테고리')) {
      const { name, nameCh, nameEng, nameJp, visible } = item;
      if (isNullOrEmpty(name)) {
        error = { ...error, name: '카테고리명은 필수입니다.' };
      }
    } else {
      const {
        category,
        name,
        subName,
        nameEng,
        subNameEng,
        nameCh,
        subNameCh,
        nameJp,
        subNameJp,
        price,
        noTaxPrice,
        labelCnt,
        stock,
        maxStock,
        kcal,
        baseShot,
      } = item;

      if (category === 'default') {
        error = { ...error, category: '카테고리를 선택해주세요.' };
      }

      if (name === '') {
        error = { ...error, name: '메뉴명은 필수입니다.' };
      }

      if (isNullOrEmpty(price) || !Number.isInteger(price)) {
        error = { ...error, price: '가격은 필수입니다.' };
      }

      if (isNullOrEmpty(noTaxPrice) || noTaxPrice * 1 < 0) {
        error = { ...error, noTaxPrice: '면세금액을 확인해주세요. 없을 시 0' };
      }

      if (!nameEng && subNameEng) {
        error = { ...error, nameEng: '메뉴명(영) 없이 서브네임이 존재합니다.' };
      }

      if (!nameJp && subNameJp) {
        error = { ...error, nameJp: '메뉴명(일) 없이 서브네임이 존재합니다.' };
      }

      if (!nameCh && subNameCh) {
        error = { ...error, nameCh: '메뉴명(중) 없이 서브네임이 존재합니다.' };
      }

      if (baseShot === '-') {
        error = { ...error, baseShot: '기본샷 갯수를 확인해주세요.' };
      }
    }

    return error;
  };

  const deleteMenuInCategory = async (id, categoryCodes) => {
    if (menu.list.length === 0) return;

    let menuCodesInCategory = menu.list
      .filter((menu) => categoryCodes.includes(menu.category))
      .map((menu) => menu.code)
      .join(',');

    if (!menuCodesInCategory) return;

    MenuService.deleteMenu(id, menuCodesInCategory)
      .then((res) => {
        //console.log(res);
        let { data, error } = res;

        if (error) return;
        getMenuList(id);
      })
      .catch((err) => console.log(err));
  };

  const deleteCategory = async (e) => {
    e.preventDefault();

    if (!affiliateInfo || !selectedCategory || selectedCategory.length === 0)
      return;

    if (affiliateInfo.isLock) {
      setCategory((old) => ({ ...old, loading: false }));
      alert('카테고리 삭제가 제한된 매장입니다.');
      return;
    }

    const cancel = window.confirm(
      `선택된 ${selectedCategory.length}개 카테고리에 속하는 메뉴, 옵션 또한 모두 삭제됩니다.\n계속하시겠습니까?`
    );
    if (!cancel) return;

    let id = affiliate.id;
    if (!id) return;

    const codes = selectedCategory.map((row) => row.original.code).join(',');
    await CategoryService.deleteCategory(codes, id)
      .then((res) => {
        //console.log(res);
        const { data, error } = res;
        if (error) {
          if (error?.message === 'Session expired') {
            goToLogin();
          }
          return;
        }

        deleteMenuInCategory(id, codes);
        alert('삭제 완료');

        getCategoryList(id);
        setSelectedCategory([]);
      })
      .catch((err) => console.log(err));
  };

  const mergeName = (name, subName) => {
    return subName === '' ? name : name + '\r\n' + subName;
  };

  const addOrUpdateMenuImage = (action, menuCode, image, isUpdate) => {
    if (action === 'orderOrVisible' || (action === 'modify' && !isUpdate))
      return;

    const formData = new FormData();
    formData.append('image', image);
    formData.append('menuCode', menuCode);
    formData.append('action', action);

    MenuService.addOrUpdateMenuImage(formData)
      .then((res) => {
        getMenuList(affiliate.id);
      })
      .catch((err) => console.log(err));
  };

  const updateOptionList = async (optionList) => {
    if (!optionList || optionList.length === 0) return;

    await OptionService.updateOption(optionList);
  };

  const addOrUpdateMenu = (action, newMenu) => {
    let id = affiliate.id;
    let menu = newMenu;

    if (action !== 'orderOrVisible' && affiliateInfo.isLock) {
      alert('메뉴 수정이 제한된 매장입니다. 옵션 수정만 진행됩니다.');
      return;
    }

    const {
      name,
      subName,
      nameEng,
      subNameEng,
      nameCh,
      subNameCh,
      nameJp,
      subNameJp,
      menuImage,
      isImageUpdated,
      updatedOptionList,
    } = newMenu;

    if (action !== 'orderOrVisible') {
      //add or modify
      menu = {
        ...newMenu,
        name: mergeName(name, subName),
        nameEng: mergeName(nameEng, subNameEng),
        nameCh: mergeName(nameCh, subNameCh),
        nameJp: mergeName(nameJp, subNameJp),
      };

      if (action === 'add') {
        menu = { ...menu, printerCode: '' };
      }

      delete menu.menuImage;
      delete menu.subNameEng;
      delete menu.subNameCh;
      delete menu.subNameJp;
    }

    setModalLoading(true);
    MenuService.addOrUpdateMenu(action, menu, affiliate.id)
      .then((res) => {
        const { data, error } = res;

        if (error) {
          setModalLoading(false);
          if (error.message) {
            console.log(error.message);
          }
          alert('메뉴 수정 오류');
          return;
        }

        if (action === 'orderOrVisible') {
          getPrinterList(id);
        } else {
          let menuCode = data.code;
          if (menuCode) {
            updateOptionList(updatedOptionList);
            addOrUpdateMenuImage(action, menuCode, menuImage, isImageUpdated);
          }
        }

        getMenuList(id);
        setModalLoading(false);
        alert(`${action === 'add' ? '추가' : '수정'} 완료`);
        setOpen(false);
        setSelectedMenu([]);
      })
      .catch((err) => console.log(err));
  };

  const deleteMenuImageByMenuCode = (menuCodes) => {
    MenuService.deleteMenuImage(menuCodes).then((res) => {
      let { data, error } = res;
      if (error) return;
    });
  };

  const deleteMenu = async () => {
    if (!selectedMenu || selectedMenu.length === 0) return;

    const cancel = window.confirm(
      `선택된 ${selectedMenu.length}개 메뉴에 속하는 옵션 또한 모두 삭제됩니다.\n계속하시겠습니까?`
    );
    if (!cancel) return;

    let id = affiliate.id;
    let menuCodes = selectedMenu.map((menu) => menu.original.code).join(',');
    if (!id || !menuCodes) return;

    MenuService.deleteMenu(id, menuCodes)
      .then((res) => {
        //console.log(res);
        let { data, error } = res;

        if (error) return;

        deleteMenuImageByMenuCode(menuCodes);
        alert('삭제 완료');

        getMenuList(id);
        setSelectedMenu([]);
      })
      .catch((err) => console.log(err));
  };

  //순서 변경
  const isMovable = (direction, selectedRows, list) => {
    const minOrMaxRow = selectedRows[0];
    const rowIndex = list.findIndex(
      (row) => row.code === minOrMaxRow.original.code
    );

    return direction === 'up'
      ? rowIndex === 0
        ? false
        : true
      : rowIndex === list.length - 1
      ? false
      : true;
  };

  const moveRow = (type, direction) => {
    const movedRows = type === 'category' ? selectedCategory : selectedMenu;
    if (movedRows?.length === 0) return;

    let newList = type === 'category' ? [...newCategoryList] : [...newMenuList];

    const sortedRows =
      direction === 'up'
        ? movedRows.sort((a, b) => a.original.sortCode - b.original.sortCode)
        : movedRows.sort((a, b) => b.original.sortCode - a.original.sortCode);

    if (!movedRows || movedRows.length === 0) return;
    if (!isMovable(direction, sortedRows, newList)) return;

    movedRows.forEach((row) => {
      const rowIndex = newList.findIndex(
        (option) => option.code === row.original.code
      );

      const affectedIndex = direction === 'up' ? rowIndex - 1 : rowIndex + 1;
      swipeRowSortCode(newList, rowIndex, affectedIndex);
    });

    type === 'category' ? setNewCategoryList(newList) : setNewMenuList(newList);
  };

  const onSaveOrderOrState = (type) => {
    //console.log('onSaveOrderOrState', type);
    let updated = [];

    const updatedList = type === 'category' ? newCategoryList : newMenuList;
    const originList = type === 'category' ? category.list : menu.list;
    let compareList = ['code', 'name', 'visible', 'sortCode'];
    if (type === 'menu') {
      compareList = [
        ...compareList,
        'packageFlag',
        'saveStamp',
        'soldOut',
        'printerCode',
      ];
    }

    if (!updatedList || !originList) return;

    updated = updatedList.reduce((res, newItem) => {
      let compareItem = originList.find((item) => item.code === newItem.code);
      if (JSON.stringify(newItem) !== JSON.stringify(compareItem)) {
        let obj = compareList.reduce((res, key) => {
          return { ...res, [key]: newItem[key] };
        }, {});
        res.push(obj);
      }
      return res;
    }, []);

    if (updated.length === 0) {
      alert('변경사항이 없습니다.');
      return;
    }

    const saveUpdate = window.confirm('변경사항을 저장하시겠습니까?');
    if (!saveUpdate) return;

    type === 'category'
      ? addOrUpdateCategory('orderOrVisible', updated)
      : addOrUpdateMenu('orderOrVisible', updated);
  };

  useEffect(() => {
    if (!user || user.role !== 'common') return;

    setAffiliate({ ...user });
  }, [user]);

  useEffect(() => {
    if (!affiliate) return;

    getAffiliateInfo(affiliate.id);
    getCategoryList(affiliate.id);
    getMenuList(affiliate.id);
    getPrinterList(affiliate.id);
  }, [affiliate]);

  useEffect(() => {
    if (!newCategoryList) return;

    setCategoryTable({
      data: newCategoryList,
      columns: categoryColumns,
      title: '카테고리',
    });
  }, [newCategoryList]);

  useEffect(() => {
    if (!newMenuList || !menuImageList || !printerColumns) return;

    let columns = [...menuColumns, ...menuImageColumn, ...printerColumns];
    setMenuTable({
      data: newMenuList,
      columns,
      title: '메뉴',
    });
  }, [newMenuList, menuImageList, printerColumns]);

  return (
    <Grid className={classes.container}>
      {/* 매장선택 */}

      <Grid item className={`${classes.container} ${classes.infoContainer}`}>
        <div className={classes.infoBox}>
          <h3 className={classes.name}>
            {affiliate.name || '매장을 선택해 주세요'}
          </h3>
          <span>{`카테고리 ${
            category.loading ? '...' : category.list?.length || ''
          } 개 / 메뉴 ${menu.loading ? '...' : menu.list?.length} 개`}</span>
        </div>

        {user.role !== 'common' && (
          <div className={classes.infoSelectBox}>
            {user.affiliates ? (
              <>
                <select
                  className={classes.infoSelect}
                  ref={affiliateRef}
                  name='selectAffiliate'
                  id='selectAffiliate'
                  defaultValue={'default'}
                >
                  <option key='default' value='default'>
                    --선택하세요--
                  </option>

                  {user.affiliates.map((a) => {
                    return (
                      <option key={a.value} value={`${a.value}||${a.label}`}>
                        {a.label}
                      </option>
                    );
                  })}
                </select>
                <button
                  className={classes.infoSelectbtn}
                  onClick={handleSelect}
                >
                  선택
                </button>
              </>
            ) : null}
          </div>
        )}
      </Grid>

      <Grid item className={classes.tableContainer}>
        <div className={classes.header}>
          <div className={classes.title}>
            카테고리
            <RefreshIcon
              onClick={refreshCategory}
              className={
                category.loading
                  ? [classes.refresh, classes.rotate].join(' ')
                  : classes.refresh
              }
            />
          </div>

          <div className={classes.actionBtns}>
            <ActionButton
              action='delete'
              onClick={deleteCategory}
              disable={
                !affiliateInfo.isLock && selectedCategory.length !== 0
                  ? 'false'
                  : 'true'
              }
            />

            <ActionButton
              disable={affiliateInfo.isLock ? 'true' : 'false'}
              action='add'
              onClick={() => onAction('category/add')}
            />

            <ActionButton
              action='modify'
              onClick={() => onAction('category/modify')}
              disable={
                !affiliateInfo.isLock && selectedCategory.length === 1
                  ? 'false'
                  : 'true'
              }
            />
          </div>
        </div>

        <EditiableWithSelectTable
          tableData={categoryTable}
          selectedRow={selectedCategory}
          setSelectedRow={setSelectedCategory}
        />

        <FooterToolbar
          id='category'
          lockInfo={affiliateInfo}
          moveRow={moveRow}
          saveList={() => onSaveOrderOrState('category')}
          loading={modalLoading}
        />
      </Grid>

      <Grid item className={classes.tableContainer}>
        <div className={classes.header}>
          <div className={classes.title}>
            메뉴
            <RefreshIcon
              onClick={refreshMenu}
              className={
                category.loading
                  ? [classes.refresh, classes.rotate].join(' ')
                  : classes.refresh
              }
            />
          </div>

          <div className={classes.actionBtns}>
            <ActionButton
              action='delete'
              onClick={deleteMenu}
              disable={
                !affiliateInfo.isLock && selectedMenu.length !== 0
                  ? 'false'
                  : 'true'
              }
            />

            <ActionButton
              disable={affiliateInfo.isLock ? 'true' : 'false'}
              action='add'
              onClick={() => onAction('menu/add')}
            />

            <ActionButton
              action='modify'
              onClick={() => onAction('menu/modify')}
              disable={selectedMenu.length === 1 ? 'false' : 'true'}
            />
          </div>
        </div>

        <EditiableWithSelectTable
          tableData={menuTable}
          selectedRow={selectedMenu}
          setSelectedRow={setSelectedMenu}
          search
          isVirtual
        />

        {!filterMenu ? (
          <FooterToolbar
            id='menu'
            lockInfo={affiliateInfo}
            moveRow={moveRow}
            saveList={() => onSaveOrderOrState('menu')}
            loading={modalLoading}
          />
        ) : null}
      </Grid>

      {open && (
        <ModifyModal
          open={open}
          setOpen={setOpen}
          title={modifyItem.title || ''}
          item={modifyItem.item}
          onSave={addOrUpdate}
          onValidate={onValidate}
          isLock={affiliateInfo?.isLock || false}
          categoryList={category.list || null}
          menuList={menu.list || null}
          affiliate={affiliate}
          loading={modalLoading}
        />
      )}
    </Grid>
  );
};

export default MenuModifyV2;

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  setSelectedFilter,
}) {
  const classes = useStyles();

  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      className={classes.select}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
        //setSessionData('modifySelectCategory', { id, value: e.target.value });
        setSelectedFilter(e.target.value === '' ? null : e.target.value);
      }}
    >
      <option value=''>All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}
