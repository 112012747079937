export function setSessionData(key, data) {
  try {
    sessionStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.log('Storage is full');
  }
}

export function getSessionData(key) {
  const stored = sessionStorage.getItem(key);
  if (!stored || stored === 'undefined') {
    return false;
  }

  return JSON.parse(stored);
}

export function removeSessionData(key) {
  sessionStorage.removeItem(key);
}

export function clearSessionData() {
  localStorage.clear();
  sessionStorage.clear();
}

export function makeAffiliate(arr, origin) {
  if (arr === 'all') {
    return origin;
  }
  const affiliateArr = arr.reduce((res, aff) => {
    let find = origin.find((f) => aff === f.value);
    res.push(find);
    return res;
  }, []);
  return affiliateArr;
}
