import React, { useState, useEffect } from 'react';
import styles from './edit_cell_checkbox.module.css';

const EditCellCheckbox = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  disable,
}) => {
  let disabled = false;
  if (disable !== undefined) {
    disabled = disable;
  }

  const [value, setValue] = useState(initialValue);
  const onChange = () => {
    const newValue = value * 1 === 1 ? 0 : 1;
    setValue(newValue);
    updateData(index, id, newValue); //not use blur for safari
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <input
      className={styles.checkbox}
      type='checkbox'
      key={index}
      value={value}
      checked={value * 1 === 1 ? true : false}
      onClick={(e) => e.stopPropagation()}
      onChange={onChange}
      //onBlur={onBlur}
      disabled={disabled}
    />
  );
};

export default EditCellCheckbox;
