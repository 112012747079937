import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { CookiesProvider } from 'react-cookie';
import { CssBaseline } from '@material-ui/core';

import App from './app';
import './index.css';

import DataService from './service/data_service';
import EmailService from './service/email_service';
import BoardService from './service/board_service';

import { ProvideAuth } from './Context/Auth';
import { ProvideMenuInfo } from './Context/MenuInfo';

import { httpClient } from './service/axios';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';

const dataService = new DataService(httpClient);
const emailService = new EmailService(httpClient);
const boardService = new BoardService(httpClient);

//const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

const darkTheme = createTheme({
  palette: {
    type: 'dark',

    /* primary: {
      main:'#202020'
    } 대충 이런식으로 색 지정*/
  },
});

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#15253F',
    },
    type: 'light',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ProvideAuth>
        <ProvideMenuInfo>
          <CookiesProvider />
          <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <App
              dataService={dataService}
              emailService={emailService}
              boardService={boardService}
            />
          </ThemeProvider>
        </ProvideMenuInfo>
      </ProvideAuth>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
