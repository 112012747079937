import React from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../common/colors';

const useStyles = makeStyles((theme) => ({
  footerToolbar: {
    alignItems: 'center',
    borderTop: `1px solid ${colors.borderLightGrey}`,
    padding: '1rem',

    [theme.breakpoints.down('xs')]: {
      padding: 8,
    },
  },

  orderBox: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 10,
    marginRight: 10,
    borderRight: '1px solid #999',
    gap: 10,

    [theme.breakpoints.down('xs')]: {
      border: 'none',
      marginRight: 4,
      gap: 6,
    },
  },

  orderBtn: {
    minWidth: 'fit-content',
    color: colors.violetText,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${colors.violet}`,
    borderRadius: 2,
    padding: 4,

    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },

  loadingBtn: {
    width: 134,
    color: colors.white,
    backgroundColor: colors.violet,
  },
}));

const FooterToolbar = (props) => {
  const classes = useStyles();
  const { id, moveRow, lockInfo, saveList, loading } = props;
  const isMovable = id === 'category' ? lockInfo.isLock : !lockInfo.isMenuMove;

  return (
    <Grid container className={classes.footerToolbar}>
      <div className={classes.orderBox}>
        <span>선택항목 순서변경</span>
        <Button
          className={classes.orderBtn}
          disabled={isMovable}
          onClick={() => moveRow(id, 'up')}
        >
          <ArrowUpwardIcon fontSize='small' />
        </Button>

        <Button
          className={classes.orderBtn}
          disabled={isMovable}
          onClick={() => moveRow(id, 'down')}
        >
          <ArrowDownwardIcon fontSize='small' />
        </Button>
      </div>

      <Button
        className={
          loading
            ? [classes.orderBtn, classes.loadingBtn].join(' ')
            : classes.orderBtn
        }
        onClick={saveList}
        disable={lockInfo.isLock ? 'true' : 'false'}
      >
        {loading ? '저장중...' : '수정내용/순서 저장'}
      </Button>
    </Grid>
  );
};

export default FooterToolbar;
