import React, { useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

import axios from 'axios';

import { makeStyles } from '@material-ui/core';

import Editor from '../../component/editor/editor';
import AttachIcon from '../../component/attach_icon/attach_icon';
import CloseIcon from '@material-ui/icons/Close';

import { useAuth } from '../../Context/Auth';
import { colors } from '../../common/colors';
import { unosIds } from '../../common/config';
import { isOverLimit } from '../../common/board';

import UploadFile from '../../component/uplaod_file/upload_file';
//import CustomDatePicker from '../../component/custom_date_picker/custom_date_picker';
import DatePickerOne from '../../component/date_picker_one/date_picker_one';
import {
  getFormatedDay,
  getFormatedDatetime,
  getDatetime,
  makeFormatedDatetime,
} from '../../common/format';

import CustomIconCheckbox from '../../component/custom_icon_checkbox/custom_icon_checkbox';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: 1200,
    padding: '2rem',
    paddingTop: '1.5rem',
    margin: '0 auto',
    marginTop: '1rem',
    borderRadius: 12,

    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },

  header: {
    width: '100%',
    display: 'flex',
    padding: '1.4rem 0',
    justifyContent: 'space-between',

    '& h2': {
      margin: 0,
    },

    [theme.breakpoints.down('xs')]: {
      padding: '0.8rem 0.5rem',
    },
  },

  category: {
    width: '12%',
    backgroundColor: colors.lightBlueGrey,
    border: 'none',
    borderRadius: 4,
    height: '2.5rem',
    lineHeight: 2.8,
    paddingLeft: 16,

    [theme.breakpoints.down('xs')]: {
      //width: 'fit-content',
      marginRight: 6,
      paddingLeft: 0,
      textAlign: 'center',
    },
  },

  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },

  titleInput: {
    boxSizing: 'border-box',
    width: '87%',
    height: '2.5rem',
    border: 'none',
    borderRadius: 4,
    backgroundColor: colors.lightBlueGrey,
    paddingLeft: 10,
    color: '#333',
    fontSize: 16,
  },

  optionBox: {
    borderTop: '',
    paddingTop: 10,
    borderTop: `1px solid ${colors.borderLightGrey}`,
    marginTop: 16,
  },

  reserveTimeBox: {
    display: 'flex',
    alignItems: 'center',
  },

  content: {
    marginTop: '1rem',
  },

  attachedFileBox: {
    minHeight: 60,
    marginTop: '1.2rem',
    border: `1px solid ${colors.borderLightGrey}`,
    borderLeft: 'none',
    borderRight: 'none',
  },

  attachFileHeader: {
    marginTop: 10,
    padding: 6,
    //backgroundColor: colors.lightBlueGrey,
  },

  attachedFileList: {
    padding: '6px 10px',
    margin: '10px 0',
    backgroundColor: colors.lightestGrey,
    transition: '0.3s',

    '& li': {
      display: 'flex',
      alignItems: 'center',
      padding: '6px 0',
      fontSize: 14,
      cursor: 'default',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },

  fileName: {
    marginRight: 14,
  },

  fileDelete: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 500,
    color: colors.commonBlack,
    padding: 3,
    borderRadius: '50%',
    backgroundColor: '#ddd',
    cursor: 'pointer',

    '&:hover': {
      color: colors.red,
    },

    '& svg': {
      width: '100%',
    },
  },

  bottom: {
    marginTop: '1rem',
  },

  cancelBtn: {
    width: '4rem',
    height: '2rem',
    border: `1px solid ${colors.borderLightGrey}`,
    color: colors.black,
    backgroundColor: colors.borderLightGrey,
    borderRadius: 4,
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    cursor: 'pointer',
    fontSize: 14,
    marginRight: 10,

    '&:hover': {
      transition: 'all 300ms ease-in',
    },
  },

  submitBtn: {
    width: '4rem',
    height: '2rem',
    border: `1px solid ${colors.blue}`,
    color: '#fff',
    backgroundColor: colors.blue,
    borderRadius: 4,
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    cursor: 'pointer',
    fontSize: 14,

    '&:hover': {
      transition: 'all 300ms ease-in',
    },
  },

  postOption: {
    marginTop: '1rem',
    display: 'flex',
    alignItems: 'center',

    '& input': {
      marginRight: 10,
    },
  },

  /* poll */

  pollContainer: {
    marginTop: '1rem',
  },

  pollContentContainer: {
    width: '100%',
    display: 'flex',
    padding: '2rem',
    border: `1px solid ${colors.borderLightGrey}`,
    borderRadius: 6,
    flexWrap: 'wrap',
    gap: 16,

    [theme.breakpoints.down('xs')]: {
      padding: '1rem',
    },
  },

  poll: {
    flex: '1 1',
  },

  section: {
    flexBasis: '50%',
    marginBottom: 12,
    padding: '0 0.5rem',

    '& h4': {
      color: colors.commonBlack,
      marginBottom: 4,
    },

    '& hr': {
      marginBottom: 10,
    },
  },

  pollSelect: {
    marginLeft: 10,
    border: 'none',
  },

  warning: {
    margin: '6px 0',
    color: colors.red,
    fontSize: 12,
  },

  pollOption: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },

  pollOptionList: {
    width: '100%',

    '& li': {
      marginBottom: 8,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },

  pollInput: {
    width: 450,
    maxWidth: '100%',
    padding: '8px 6px',
    marginRight: 6,
    fontSize: 16,
    border: '1px solid #CBCBCB',
    borderRadius: 4,

    [theme.breakpoints.down('xs')]: {
      width: 300,
      padding: '4px 2px',
    },
  },

  pollDeleteBtn: {
    padding: 4,
    cursor: 'pointer',
    color: colors.black700,

    '&:hover': {
      color: colors.warning,
    },
  },

  pollAddBtn: {
    width: 120,
    padding: '6px 10px',
    cursor: 'pointer',
    border: 'none',
  },

  pollSetting: {
    flex: '1 1',
  },

  setting: {
    display: 'flex',
    height: '2.3rem',
    alignItems: 'center',
    padding: 6,
  },

  limitTimeBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingLeft: 4,
    gap: 10,

    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },

  datePicker: {
    //backgroundColor: colors.lightBlueGrey,
    borderBottom: '1px solid black',

    '&:hover': {
      borderBottom: `1px solid ${colors.blue}`,
    },
  },
}));

let cancelToken;

const BoardNew = ({ boardService }) => {
  const classes = useStyles();
  const history = useHistory();
  const match = useParams();
  const auth = useAuth();
  const user = auth.user;

  //const categoryRef = useRef();
  const titleRef = useRef();
  const pollTitleRef = useRef('');
  const visibleRef = useRef(0);
  const pinRef = useRef(0);
  const limitTimeRef = useRef();

  const [board, setBoard] = useState(null);
  const [isReservedBoard, setIsReservedBoard] = useState({
    isSet: false,
    isUsed: false,
    origin: false,
  });
  const [content, setContent] = useState(null);
  const [attachedFile, setAttachedFile] = useState([]);
  const [attachedFileProgress, setAttachedFileProgress] = useState(0);

  const [poll, setPoll] = useState(0);
  const [pollLimit, setPollLimit] = useState({
    isUsed: 0,
    datetime: getFormatedDay(new Date()),
  });

  const [pollSettings, setPollSettings] = useState({
    multiple: 0,
    showResult: 0,
  });

  const [pollList, setPollList] = useState([
    { id: 1, value: '' },
    { id: 2, value: '' },
  ]);

  useEffect(() => {
    //새글 or 수정
    const { category, id } = match;

    if (id) {
      boardService
        .getBoard(user.id, category, id)
        .then((res) => {
          //console.log('getBoard', res);
          setBoard(res);

          const visible = res.visible === '1' ? false : true;
          visibleRef.current.checked = visible;

          const pin = res.pin === '1' ? true : false;
          pinRef.current.checked = pin;
          titleRef.current.value = res.title || '';

          const boardId = category === 'unos_notice' ? 1 : 2;
          if (res.attachedFile === '1') {
            getAttachFile(boardId, res.noticeId);
          }

          if (id && res.reserveDatetime) {
            setIsReservedBoard({
              isSet: true,
              origin: true,
              isUsed: isOverLimit(res.reserveDatetime),
            });
          }

          if (res.poll === '1') {
            setPoll(1);
            getPollList(boardId, res.noticeId);
          }

          setContent(res.content);
          scrollToTop();
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getAttachFile = (boardId, noticeId) => {
    boardService
      .getAttachFileList(boardId, noticeId)
      .then((res) => {
        //console.log('getAttachFile: ', res);
        setAttachedFile(res);
      })
      .catch((err) => console.log(err));
  };

  const getPollList = (boardId, noticeId) => {
    boardService
      .getPollList(boardId, noticeId)
      .then((res) => {
        if (res.length !== 0) {
          pollTitleRef.current.value = res.title;
          pollTitleRef.current.disabled = true;
          setBoard((board) => ({ ...board, poll: '1' }));

          setPollList(
            res.pollList
              .filter((poll) => poll !== '')
              .map((poll, index) => ({ id: index + 1, value: poll }))
          );

          let { multiple, showResult } = res;
          setPollSettings({ multiple, showResult });

          if (res.limitDatetime) {
            setPollLimit({
              isUsed: 1,
              datetime: getDatetime(res.limitDatetime),
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const goToList = () => {
    history.push('/board/notice');
  };

  const goToView = (category, boardId) => {
    history.push(`/board/view/${category}/${boardId}`);
  };

  const onCancel = (e) => {
    e.preventDefault();
    const { category, id } = match;

    if (id) {
      goToView(category, id);
    } else {
      goToList();
    }
  };

  const onReservedChange = (e) => {
    const check = e.target.checked === true ? 1 : 0;
    setIsReservedBoard((old) => ({ ...old, isSet: check }));
  };

  const onReserveTimeChange = (e) => {
    setBoard((old) => ({ ...old, reserveDatetime: makeFormatedDatetime(e) }));
  };

  const onFileDelete = (e, fileName, extension) => {
    e.preventDefault();

    if (fileName && extension) {
      boardService
        .deleteAttachFile(fileName, extension)
        .then((res) => {
          //console.log(res);
          if (res.result === 'success') {
            setAttachedFile((old) =>
              old.filter((file) => file.fileName !== res.fileName)
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onAttachInputChange = (e) => {
    e.preventDefault();
    //첨부파일

    const files = e.target.files;
    const data = new FormData();
    const maxSize = 100 * 1024 * 1024;

    if (files) {
      let isSizePermit = true;

      for (let i = 0; i < files.length; i++) {
        let nameArr = files[i]['name'].split('.');
        let name = nameArr.slice(0, nameArr.length - 1).join('.');

        data.append('names[]', name);
        data.append('files[]', files[i]);

        if (files[i].size > maxSize) isSizePermit = false;
      }

      if (!isSizePermit) {
        alert('첨부파일 크기는 100MB 이하만 가능합니다.');
        e.target.value = null;
        return;
      }

      uploadFileService(data)
        .then((res) => {
          //console.log('uploadFileService', res);
          if (res && res.data) {
            res.data.map((file) => {
              const { response, url, fileName, originName, extension } = file;
              response === 'success'
                ? setAttachedFile((old) => [
                    ...old,
                    { url, fileName, originName, extension, downloadable: 1 },
                  ])
                : console.log('업로드 실패');
            });

            e.target.value = null;
            setAttachedFileProgress(0);
          }
        })
        .catch((err) => console.log('err : ', err));
    }
  };

  const uploadFileService = async (fileData) => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('파일 업로드가 중지됨.');
    }

    cancelToken = axios.CancelToken.source();

    const result = await axios
      .post(
        `${process.env.REACT_APP_API_URL}/board_upload_file.php`,
        fileData,
        {
          headers: { 'content-type': 'multipart/form-data' },
          cancelToken: cancelToken.token,
          onUploadProgress: (progressEvent) => {
            let percentage = Math.ceil(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setAttachedFileProgress(percentage);
          },
        }
      )
      .catch((err) => console.log(err.message));

    return result;
  };

  const onFileUploadCancel = (e) => {
    e.preventDefault();

    cancelToken.cancel('Cancel Upload File');
    setAttachedFileProgress(0);
  };

  const onMultipleAnsCheck = (e) => {
    const check = e.target.checked === true ? 1 : 0;
    setPollSettings((old) => ({ ...old, multiple: check }));
  };

  const onShowResultCheck = (e) => {
    const check = e.target.checked === true ? 1 : 0;
    setPollSettings((old) => ({ ...old, showResult: check }));
  };

  const validatePost = () => {
    /* const category = categoryRef.current.value;
    if (!category) {
      alert('분류가 선택되지 않았습니다.');
      return;
    } */

    const title = titleRef.current.value;
    if (!title) {
      alert('제목을 입력해 주세요.');
      return;
    }

    if (isReservedBoard.isSet) {
      if (!board?.reserveDatetime) {
        alert('예약 시간이 설정되지 않았습니다.');
        return;
      }

      if (
        board.reserveDatetime <= getFormatedDatetime(new Date()) &&
        !isReservedBoard.isUsed
      ) {
        alert('예약 시간이 제대로 설정되지 않았습니다.');
        return;
      }
    }

    if (!content) {
      alert('내용을 입력해 주세요.');
      return;
    }

    if (attachedFileProgress) {
      alert('파일 업로드가 진행중입니다. 업로드를 기다리시거나 취소해 주세요.');
      return;
    }

    if (poll) {
      const polltitle = pollTitleRef.current.value;
      if (!polltitle) {
        alert('투표 질문을 입력해 주세요.');
        return;
      }

      if (pollList.length === 0) {
        alert('투표 항목은 하나 이상이어야 합니다.');
        return;
      }

      let isListComplete = true;
      pollList.map((poll) => {
        if (poll.value === '') {
          isListComplete = false;
          return;
        }
      });

      if (!isListComplete) {
        alert('투표 항목을 입력해 주세요.');
        return;
      }
    }

    return true;
  };

  const fixImagePath = (content) => {
    return content.replaceAll('/board/attach_upload_temp/', '/board/attach/');
  };

  const checkFileExsit = (attachedFileList, content) => {
    //console.log('checkFileExsit', attachedFileList, content);
    return attachedFileList.reduce((prev, curr) => {
      if (curr.downloadable === 1) {
        //첨부파일
        prev.push(curr);
      } else {
        //본문파일
        if (content.includes(curr.fileName)) {
          prev.push(curr);
        }
      }

      return prev;
    }, []);
  };

  const addPost = (post) => {
    boardService
      .postBoard(post)
      .then((res) => {
        if (res.result === true) {
          goToView(post.category, res.noticeId);
        } else {
          console.log(res);
          alert('등록이 실패했습니다. ');
        }
      })
      .catch((err) => console.log(err));
  };

  const updatePost = (post) => {
    boardService
      .updateBoard(post)
      .then((res) => {
        if (res.result === true) {
          goToView(post.category, res.id);
        } else {
          alert('등록이 실패했습니다. ');
        }
      })
      .catch((err) => console.log(err));
  };

  const addOrUpdatePost = (e) => {
    e.preventDefault();

    const postId = match.id;
    const type = postId ? 'update' : 'add';
    let visible = visibleRef.current.checked ? 0 : 1;
    const pin = pinRef.current.checked ? 1 : 0;

    let category = match.category || 'notice';
    if (type === 'add' && user.master === 'unospay') {
      category = 'unos_notice';
    }

    const isUnosAdmin = unosIds.includes(user.id);
    if (user.role !== 'master' && !isUnosAdmin) {
      //권한이 없는 계정의 등록 or 수정
      alert('올바른 요청이 아닙니다.');

      if (postId) {
        goToView(category, postId);
      } else {
        goToList();
      }

      return;
    }

    const validate = validatePost();
    if (!validate) return;

    const title = titleRef.current.value;
    const reserveDatetime = isReservedBoard.isSet
      ? board?.reserveDatetime || ''
      : '';

    let postProps = {
      boardId: postId ? postId : '',
      id: user.id,
      category,
      title,
      attachedFile,
      visible,
      pin,
      content,
      reserveDatetime,
      poll,
    };

    //본문 이미지 확인
    if (type === 'add') {
      postProps.attachedFile = checkFileExsit(attachedFile, content);
    }

    /* 투표 */
    if (poll) {
      postProps.pollTitle = pollTitleRef.current.value;
      postProps.limitDatetime = pollLimit.isUsed ? pollLimit.datetime : '';
      postProps.pollMultiple = pollSettings.multiple;
      postProps.pollShowResult = pollSettings.showResult;
      postProps.pollList = pollList;
    }

    if (attachedFile.length !== 0) {
      postProps.content = fixImagePath(content);
    }

    if (reserveDatetime && !isReservedBoard.isUsed) {
      postProps.visible = 1;
    }

    //예약노출 있다가 없어지는 경우
    const isUpdateReserveTime =
      isReservedBoard.origin && !reserveDatetime ? 1 : 0;

    if (type === 'add') {
      addPost(postProps);
    } else {
      updatePost({ ...postProps, isUpdateReserveTime });
    }
  };

  //poll
  const onPollChange = (e) => {
    e.preventDefault();

    const target = e.target.value * 1;
    if (target && match.id) {
      const boardId = match.category === 'unos_notice' ? 1 : 2;
      getPollList(boardId, match.id);
    }
    setPoll(target);
  };

  const onAddPollAnswer = () => {
    if (pollList.length === 10) {
      alert('투표 항목은 10개 까지 가능합니다.');
      return;
    }

    setPollList((old) => [...old, { id: pollList.length + 1, value: '' }]);
  };

  const onPollInputChange = (e, index) => {
    const answer = e.target.value;

    setPollList((old) =>
      old.map((poll) => {
        if (poll.id === index) {
          return { ...poll, value: answer };
        }
        return poll;
      })
    );
  };

  const onPollDelete = (e, index) => {
    e.preventDefault();

    setPollList((old) =>
      old
        .filter((poll) => poll.id !== index)
        .map((poll, index) => ({ ...poll, id: index + 1 }))
    );
  };

  const onPollLimitChange = (e) => {
    const target = e.target.checked ? 1 : 0;
    setPollLimit((old) => ({ ...old, isUsed: target }));
  };

  const onLimitTimeChange = (e) => {
    setPollLimit((old) => ({ ...old, datetime: makeFormatedDatetime(e) }));
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h2>{match.id ? '글 수정' : '글 쓰기'}</h2>
        {match.id ? (
          <button className={classes.submitBtn} onClick={addOrUpdatePost}>
            수정
          </button>
        ) : (
          <button className={classes.submitBtn} onClick={addOrUpdatePost}>
            등록
          </button>
        )}
      </div>

      <div className={classes.body}>
        <div className={classes.title}>
          <span className={classes.category}>공지</span>
          {/* <select
            ref={categoryRef}
            className={classes.category}
            defaultValue='notice'
            name='category'
            id='category'
          >
            <option value=''>분류 선택</option>
            <option value='notice'>공지</option>
          </select> */}
          <input
            ref={titleRef}
            className={classes.titleInput}
            type='text'
            placeholder='제목'
          />
        </div>
        {!match.id ||
        (match.id &&
          board &&
          board.reserveDatetime &&
          !isOverLimit(board.reserveDatetime)) ? (
          <>
            <div className={classes.optionBox}>
              <div className={classes.reserveTimeBox}>
                <CustomIconCheckbox
                  value={isReservedBoard.isSet ? 1 : 0}
                  onValueChange={onReservedChange}
                  label='예약노출 설정'
                />

                {isReservedBoard.isSet ? (
                  <DatePickerOne
                    value={
                      board?.reserveDatetime.replace(' ', 'T') || new Date()
                    }
                    setValue={onReserveTimeChange}
                    isTimeValue
                    isClearIcon
                  />
                ) : null}
              </div>
              <p className={classes.warning}>
                ※ 예약노출 설정은 새 글 등록시에만 설정이 가능합니다. (예약시간
                전까지는 예약시간 수정 가능)
              </p>
            </div>
          </>
        ) : null}

        <div className={classes.attachedFileBox}>
          <div className={classes.attachFileHeader}>
            첨부파일 :{' '}
            {attachedFile.filter((file) => file.downloadable === 1).length} 개
          </div>

          {attachedFile.length > 0 && (
            <ul className={classes.attachedFileList}>
              {attachedFile.map((file, index) => {
                const { extension, fileName, originName, downloadable } = file;

                if (downloadable === 1) {
                  return (
                    <li key={index}>
                      <AttachIcon extension={extension} />

                      <span className={classes.fileName}>
                        {originName}.{extension}
                      </span>
                      <div
                        className={classes.fileDelete}
                        onClick={(e) => onFileDelete(e, fileName, extension)}
                      >
                        <CloseIcon />
                      </div>
                    </li>
                  );
                }
              })}
            </ul>
          )}

          <UploadFile
            inputChange={onAttachInputChange}
            cancelUpload={onFileUploadCancel}
            progress={attachedFileProgress}
          />
        </div>

        <div className={classes.content}>
          <Editor
            content={content}
            setContent={setContent}
            setAttachedFileList={setAttachedFile}
          />
        </div>
      </div>

      <div className={classes.postOption}>
        글 숨김
        <input
          disabled={isReservedBoard.isSet && !isReservedBoard.isUsed}
          ref={visibleRef}
          type='checkbox'
        />
        글 고정
        <input ref={pinRef} type='checkbox' />
      </div>

      <div className={classes.pollContainer}>
        <span>투표 첨부</span>
        <select
          value={poll || '0'}
          className={classes.pollSelect}
          onChange={onPollChange}
          name='poll'
          id='poll'
        >
          <option value='0'>미사용</option>
          <option value='1'>사용</option>
        </select>

        {poll ? (
          <>
            <p className={classes.warning}>
              ※투표내용은 첫 등록 이후 수정 할 수 없습니다.
            </p>
            <div className={classes.pollContentContainer}>
              <div className={classes.poll}>
                <div className={classes.section}>
                  <h4>질문</h4>
                  <Divider />
                  <input
                    className={classes.pollInput}
                    ref={pollTitleRef}
                    type='text'
                  />
                </div>

                <div className={classes.section}>
                  <h4>항목</h4>
                  <Divider />

                  <ul className={classes.pollOptionList}>
                    {board?.poll === '1'
                      ? pollList.map((poll, index) => {
                          return (
                            <li className={classes.pollInput} key={index}>
                              {poll.value}
                            </li>
                          );
                        })
                      : pollList.map((poll, index) => {
                          return (
                            <li key={index}>
                              <input
                                className={classes.pollInput}
                                onChange={(e) =>
                                  onPollInputChange(e, index + 1)
                                }
                                type='text'
                                value={poll.value}
                              />
                              <span
                                className={classes.pollDeleteBtn}
                                onClick={(e) => onPollDelete(e, index + 1)}
                              >
                                삭제
                              </span>
                            </li>
                          );
                        })}
                  </ul>

                  {!board || board?.poll !== '1' ? (
                    <button
                      className={classes.pollAddBtn}
                      onClick={onAddPollAnswer}
                    >
                      항목 추가
                    </button>
                  ) : null}
                </div>
              </div>

              {/* poll settings */}
              <div className={classes.pollSetting}>
                <div className={classes.section}>
                  <h4>투표 설정</h4>
                  <Divider />
                  <ul>
                    {board?.poll !== '1' ? (
                      <li className={classes.setting}>
                        <CustomIconCheckbox
                          value={pollSettings.multiple * 1 || 0}
                          onValueChange={onMultipleAnsCheck}
                          label='중복 투표'
                        />
                      </li>
                    ) : null}

                    {board?.showResult !== '1' ? (
                      <li className={classes.setting}>
                        <CustomIconCheckbox
                          value={pollSettings.showResult * 1 || 0}
                          onValueChange={onShowResultCheck}
                          label='투표 결과 보이기'
                        />
                      </li>
                    ) : null}

                    <li className={classes.setting}>
                      <CustomIconCheckbox
                        value={pollLimit.isUsed || 0}
                        onValueChange={onPollLimitChange}
                        label='투표 마감시간'
                      />
                      {/* {pollLimit.isUsed == 1 ? (
                        <div className={classes.datePicker}>
                          <CustomDatePicker
                            inputRef={limitTimeRef}
                            handleDate={onLimitTimeChange}
                            dateValue={pollLimit.datetime.replace(' ', 'T')}
                            view='time'
                            disableFuture={false}
                          />
                        </div>
                      ) : null} */}

                      {pollLimit.isUsed == 1 ? (
                        <div className={classes.datePicker}>
                          <DatePickerOne
                            value={pollLimit.datetime.replace(' ', 'T')}
                            setValue={onLimitTimeChange}
                            isTimeValue
                            isClearIcon
                          />
                        </div>
                      ) : null}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>

      <div className={classes.bottom}>
        <button className={classes.cancelBtn} onClick={onCancel}>
          취소
        </button>
        <button className={classes.submitBtn} onClick={addOrUpdatePost}>
          {match.id ? '수정' : '등록'}
        </button>
      </div>
    </div>
  );
};

export default BoardNew;
