export const colors = {
  //common color
  red: '#CC3333',
  white: '#fff',
  black: '#000',
  green: '#18a558',
  orange: '#ff6548',
  skyblue: '#84aad8',

  lightYellow: '#FFFFE0',

  //black
  lightblack: '#2d2d2d',

  black400: '#444',
  commonBlack: '#666',
  black700: '#777',
  black900: '#999',

  //red
  warning: '#D94452',
  warningLight: '#ECA5AB',
  warningLightText: '#E06974',

  //grey
  grey: '#d0d7de',
  lightGrey: '#f2f2f2',

  borderLightGrey: '#E0E0E0',

  lightBlueGrey: '#f4f9f9',
  lightestGrey: '#f8f8f8',

  //blue
  unosNavy: '#113967',
  navy: '#113a69',
  unosBlue: '#009de4',
  unosBaseBlue: '#3f51b5',
  blue300: '#4C77DD',
  blue: '#3968DA',
  lightBlue: '#E8EFFA',
  lightestVioletGrey: '#F9F9FB',

  //violet theme
  borderDarkViolet: '#8884d8',
  borderLightViolet: '#F2F0F9',

  greyViolet: '#C7C6E2',

  lighterViolet: '#D9D5EC',
  violet: '#8c84dc',

  darkViolet: '#635DCC',
  darkViolet600: '#726dd1',
  darkViolet_200: '#6D5BD0',

  violetText: '#6159A9',

  tableHeaderBackground: '#F2F7E9',
  tableBorderGrey: 'rgba(224,224,224)',

  tableBorder: '#c4c4c4',

  iconLightGrey: 'rgba(166, 175, 195, 0.25)',
};
