import React, { useMemo } from 'react';
import { useEffect, useState, memo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import Header from '../header/header';
import ScrollButton from '../scroll_button/scroll_button';
import SideMenu from '../side_menu/side_menu';
import PageHeader from '../../component/page_header/page_header';
import { menus } from '../../component/side_menu/menu';

import styles from './layout.module.css';
import pageStyles from '../../pages/common/page_common.module.css';
import { useHistory } from 'react-router';

const menuWidth = 250;

/* 추후 테마 추가*/
/* const theme = createMuiTheme({ palette });

const palette = {
  types: {
    dark: {
      background: {
        default: '#00000',
      },
    },

    light: {
      background: {
        default: '#ffffff',
      },
    },
  },
};

const muiStyles = (theme) => ({
  '@global': {
    body: {
      background:
        theme.palette.type === 'light'
          ? theme.palette.types.light.background.default
          : theme.palette.types.dark.background.default,
    },
  },
}); */

const useStyles = makeStyles((theme) => ({
  main: {
    flexGrow: 1,
    width: 'calc(100% - 250px)',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 0,
    marginBottom: '4rem',
    overflow: 'hidden',
    //backgroundColor: theme.palette.background.paper,

    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.down('md')]: {
      //backgroundColor: theme.palette.background.paper,
      width: '100%',
      marginBottom: '2rem',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '0.5rem',
      marginBottom: '1rem',
    },
  },

  mainShift: {
    flexGrow: 1,
    width: 'calc(100% - 250px)',
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: menuWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    //backgroundColor: theme.palette.background.paper,

    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },

    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },

    [theme.breakpoints.down('xs')]: {
      padding: '0.5rem 0',
    },
  },
}));

const Layout = memo(({ children, handleLogout, user }) => {
  const classes = useStyles();
  const width = window.innerWidth;
  const [sideMenu, setSideMenu] = useState(width > 1280 ? true : false);
  const history = useHistory();
  const pathname = history.location.pathname;

  //const find = menus.filter((m) => pathname.includes(m.url))[0];
  //const find = menus.find((m) => pathname.includes(m.url));
  //const title = find?.title || 'Home';

  const title = useMemo(() => {
    const find = menus.find((m) => pathname.includes(m.url));
    return find ? find.title : 'Home';
  }, [pathname]);

  const onToggle = (e) => {
    e.preventDefault();
    setSideMenu(!sideMenu);
  };

  const onClose = () => {
    setSideMenu(false);
  };

  useEffect(() => {
    if (width <= 1024) {
      setSideMenu(false);
    }
  }, [width]);

  //const contentRef = useRef();
  const onContentBoxClick = () => {
    if (width > 1200 || sideMenu === false) {
      return;
    }

    onClose();
  };

  //scrolltop button
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', toggleVisible);
    };
    watch(); // 실행
    return () => {
      // addEventListener 삭제
      window.removeEventListener('scroll', toggleVisible);
    };
  });

  return (
    <>
      <Header handleLogout={handleLogout} user={user} onToggle={onToggle} />
      <div className={styles.container}>
        <SideMenu open={sideMenu} onClose={onClose} />
        <div
          //ref={contentRef}
          onClick={onContentBoxClick}
          className={sideMenu ? classes.mainShift : classes.main}
        >
          <Grid container className={pageStyles.mainWrapper}>
            <Grid item className={pageStyles.header}>
              <div className={pageStyles.info} xs={12}>
                <PageHeader title={title} />
              </div>
            </Grid>
            {children}
          </Grid>
        </div>

        <ScrollButton visible={visible} />
      </div>
    </>
  );
});

export default Layout;
