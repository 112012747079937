import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import { toCurrency } from '../../common/format';

const useStyles = makeStyles((theme) => ({
  barchart: {
    marginLeft: -15,
    [theme.breakpoints.down('sm')]: {
      fontSize: '90%',
    },

    [theme.breakpoints.down('xs')]: {
      marginLeft: -20,
      marginTop: 10,
      fontSize: '80%',
    },
  },
  xAxis: {
    height: 100,
    [theme.breakpoints.down('xs')]: {
      height: 120,
    },
  },
  xTick: {
    border: '1px solid black',
    fontSize: '100%',
    [theme.breakpoints.down('md')]: {
      fontSize: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      textAnchor: 'end',
      fontSize: '90%',
      transform: 'rotate(-45deg)',
    },
  },
  legend: {
    marginLeft: 10,
  },
}));

const colors = ['#dadada', '#8884d8', '#82ca9d', '#ffc658', 'rgb(204,204,204)'];
const CustomMixBarchart = ({ chartData }) => {
  const classes = useStyles();
  const barDataKeys = Object.keys(chartData[0])
    .filter((data) => data !== 'name')
    .sort();

  const storeHeads = chartData.map((data) => data.name.split('_')[0]);
  const storeDiff = storeHeads.findIndex((value) => value !== storeHeads[0]);
  const removeHead = (name) => (name.includes('_') ? name.split('_')[1] : name);

  function chunk(str, size) {
    return str.match(new RegExp('.{1,' + size + '}', 'g'));
  }

  const customTick = (props) => {
    const { x, y, payload, width } = props;
    const text = storeDiff !== -1 ? payload.value : removeHead(payload.value);
    let textArr = [];
    if (width > 500) {
      const splitArr = text && text.replace(' ', '_').split('_');
      textArr =
        splitArr &&
        splitArr.reduce((res, text) => {
          if (text.length > 6) {
            const chunks = chunk(text, 5);
            chunks.map((chunk) => res.push(chunk));
          } else {
            res.push(text);
          }
          return res;
        }, []);
    } else {
      const fullText = text.replace(' ', '');
      const slice =
        fullText.length > 7 ? fullText.slice(0, 7) + '...' : fullText;
      textArr.push(slice);
    }

    return (
      <>
        <g transform={`translate(${x},${y})`}>
          <text
            width={width / 10}
            className={classes.xTick}
            x={0}
            y={0}
            textAnchor={width > 500 ? 'middle' : 'right'}
            fill='#666'
            //scaleToFit={true}
          >
            {textArr.map((text, index) => {
              return (
                <tspan key={index} x={0} dy={14}>
                  {text}
                </tspan>
              );
            })}
          </text>
        </g>
      </>
    );
  };

  return (
    <ResponsiveContainer height='80%'>
      <BarChart
        className={classes.barchart}
        width={500}
        height={300}
        data={chartData}
        maxBarSize={45}
        barCategoryGap='30%'
        barGap='0'
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          className={classes.xAxis}
          interval={0}
          allowDecimals
          dataKey='name'
          tick={customTick}
          minTickGap={10}
          height={80}
        />
        <YAxis type='number' tickFormatter={(value) => DataFormater(value)} />
        <Tooltip
          content={<CustomTooltip />}
          formatter={(value) => toCurrency(value)}
        />
        {barDataKeys.map((key, index) => {
          return <Bar key={index} dataKey={key} fill={colors[index]} />;
        })}
        <Legend content={<CustomLegend />} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomMixBarchart;

const DataFormater = (number) => {
  if (number > 1000000000) {
    return (number / 1000000000).toString() + 'B';
  } else if (number > 1000000) {
    return (number / 1000000).toString() + 'M';
  } else if (number > 1000) {
    return (number / 1000).toString() + 'K';
  } else {
    return number.toString();
  }
};

const customStyles = makeStyles((theme) => ({
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1rem 1.5rem',
    fontSize: 14,
    backgroundColor: '#fff',
    border: '1px solid rgb(204,204,204)',
    '& > p': {
      margin: 0,
      marginTop: 6,
    },
    '& p:first-child': {
      marginBottom: 6,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0.7rem 1.2rem',
    },
  },
  dot: {
    width: 10,
    height: 10,
    marginRight: 10,
  },
  label: {
    marginBottom: 10,
  },
  list: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  legend: {
    color: '#555',
  },
}));

const CustomTooltip = ({ active, payload, label }) => {
  const classes = customStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.tooltip}>
        <p className={classes.label}>{label}</p>
        <p className={classes.list}>
          <FiberManualRecordIcon
            className={classes.dot}
            style={{ color: payload[0].color }}
          />
          {`${payload[0].name} : ${toCurrency(payload[0].value)}`}
        </p>
        <p className={classes.list}>
          <FiberManualRecordIcon
            className={classes.dot}
            style={{ color: payload[1].color }}
          />
          {`${payload[1].name} : ${toCurrency(payload[1].value)}`}
        </p>
      </div>
    );
  }

  return null;
};

const CustomLegend = (props) => {
  const classes = customStyles();
  const { payload } = props;
  return (
    <ul className={classes.list}>
      {payload.map((entry, index) => {
        return (
          <li key={`item-${index}`} className={classes.row}>
            <StopIcon style={{ color: entry.color }} />
            <span className={classes.legend}>{entry.value}</span>
          </li>
        );
      })}
    </ul>
  );
};
