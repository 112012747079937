//import { client } from './client';
import { httpClient } from './axios';

const getAffilaiteInfo = (id) => {
  return httpClient.get(`affiliate_info.php?id=${id}`).then((res) => res.data);
};

const getPrinterList = (id) => {
  return httpClient.get(`printer_info.php?id=${id}`).then((res) => res.data);
};

const InfoService = {
  getAffilaiteInfo,
  getPrinterList,
};

export default InfoService;
