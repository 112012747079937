import React from 'react';
import Spinner from 'react-spinkit';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fullContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.1)',
    transition: 'all 200ms ease-in',
  },

  fullSpinner: {
    position: 'absolute',
    left: '50%',
    top: '50%',

    '& div': {
      width: 40,
      height: 40,
    },
  },

  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  /* spinner: {
    maxWidth: 50,
    maxHeight: 50,
  }, */
}));

const LoadingSpinner = ({ spinner, color, isFull }) => {
  const classes = useStyles();

  return (
    <div className={isFull ? classes.fullContainer : classes.container}>
      <Spinner
        className={isFull ? classes.fullSpinner : classes.spinner}
        name={spinner}
        color={color}
        fadeIn={isFull ? 'quarter' : 'none'}
      />
    </div>
  );
};

export default LoadingSpinner;
