import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { colors } from '../../common/colors';

const useStyles = makeStyles(() => ({
  textField: {
    marginBottom: '1.3rem',
  },
}));

const VioletTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.violet,
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: colors.violet,
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 0,
      },
      '&:hover fieldset': {
        borderColor: colors.violet,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.violet,
      },
    },
  },
})(TextField);

const CustomTextField = ({ onChange, ...props }) => {
  const classes = useStyles();
  const { label, width, fullWidth, defaultValue, multiline } = props;
  const disabled = props.disabled || false;

  let { name, value } = props;

  const isRequire = (name) => {
    return name === 'name' ? true : false;
  };

  return (
    <VioletTextField
      className={[classes.textField, fullWidth ? classes.fullWidth : ''].join(
        ' '
      )}
      style={{ width: !width ? '100%' : `${width}%` }}
      value={value || ''}
      defaultValue={defaultValue}
      variant='outlined'
      color='primary'
      name={name}
      label={label}
      onChange={onChange}
      multiline={multiline ? true : false}
      disabled={disabled}
      required={isRequire(name)}
    />
  );
};

export default CustomTextField;
