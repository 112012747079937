import React from 'react';

import { makeStyles, LinearProgress } from '@material-ui/core';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';

import { colors } from '../../common/colors';

const useStyles = makeStyles((theme) => ({
  fileInputBox: {
    padding: '10px 0',

    '& label': {
      maxWidth: 'fit-content',
      backgroundColor: colors.lightGrey,

      '&:hover': {
        color: colors.blue,
      },
    },
  },

  fileInput: {
    display: 'none',
    //visibility: 'hidden',
  },

  cancel: {
    backgroundColor: colors.lightGrey,

    '&:hover': {
      color: '#ff4444',
    },
  },

  button: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    padding: '6px 14px',
    border: 'none',
    borderRadius: 4,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    cursor: 'pointer',
  },

  icon: {
    marginRight: 4,
    width: 20,
  },

  warning: {
    margin: 0,
    marginTop: 6,
    color: colors.red,
    fontSize: 12,
  },
}));

const UploadFile = ({ inputChange, cancelUpload, progress }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.fileInputBox}>
        {progress ? (
          <>
            <button
              className={[classes.button, classes.cancel].join(' ')}
              onClick={cancelUpload}
            >
              <IndeterminateCheckBoxOutlinedIcon className={classes.icon} />
              업로드 취소
            </button>
          </>
        ) : (
          <>
            <label className={classes.button} htmlFor='input-file'>
              <FolderOpenOutlinedIcon className={classes.icon} />
              파일선택
            </label>
            <input
              id='input-file'
              className={classes.fileInput}
              type='file'
              onChange={inputChange}
              multiple
            />
          </>
        )}
        <p className={classes.warning}>
          ※10MB 이상의 대용량 첨부파일은 30일 후 삭제됩니다.
        </p>
      </div>

      {progress ? (
        <LinearProgress variant='determinate' value={progress} />
      ) : null}
    </>
  );
};

export default UploadFile;
