import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import loadable from '@loadable/component';

import Login from './pages/login/login';
import Home from './pages/home/home';
import Layout from './component/layout/layout';

import styles from './app.module.css';

import TaxRequest from './pages/tax_request/tax_request';
import Manual from './pages/manual/manual';

import { useAuth } from './Context/Auth';
import { getSessionData, removeSessionData } from './common/session';
import BoardNew from './pages/board_new/board_new';
import MenuModifyV2 from './pages/menu_modify_v2/menu_modify_v2';

const TestPage = loadable(() => import('./pages/test_page/test_page'), {
  fallback: <div></div>,
});

const SalesTerm = loadable(() => import('./pages/sales_term/sales_term'), {
  fallback: <div></div>,
});

const SalesMd = loadable(() => import('./pages/sales_md/sales_md'), {
  fallback: <div></div>,
});

const SalesStore = loadable(() => import('./pages/sales_store/sales_store'), {
  fallback: <div></div>,
});

const SalesPayType = loadable(
  () => import('./pages/sales_paytype/sales_paytype'),
  {
    fallback: <div></div>,
  }
);

const SalesCalendar = loadable(
  () => import('./pages/sales_calendar/sales_calendar'),
  {
    fallback: <div></div>,
  }
);

const SalesCategory = loadable(
  () => import('./pages/sales_category/sales_category'),
  {
    fallback: <div></div>,
  }
);

const SalesContrast = loadable(
  () => import('./pages/sales_contrast/sales_contrast'),
  {
    fallback: <div></div>,
  }
);

const SalesRealtime = loadable(
  () => import('./pages/sales_realtime/sales_realtime'),
  {
    fallback: <div></div>,
  }
);

const SalesDevice = loadable(
  () => import('./pages/sales_device/sales_device'),
  {
    fallback: <div></div>,
  }
);

const Stock = loadable(() => import('./pages/stock/stock'), {
  fallback: <div></div>,
});

const StockSoldout = loadable(
  () => import('./pages/stock_soldout/stock_soldout'),
  {
    fallback: <div></div>,
  }
);

const MenuSoldout = loadable(
  () => import('./pages/menu_soldout/menu_soldout'),
  {
    fallback: <div></div>,
  }
);

const MenuModify = loadable(() => import('./pages/menu_modify/menu_modify'), {
  fallback: <div></div>,
});

const OptionModify = loadable(
  () => import('./pages/option_modify/option_modify'),
  {
    fallback: <div></div>,
  }
);

const Notice = loadable(() => import('./pages/notice/notice'), {
  fallback: <div></div>,
});

const BoardView = loadable(() => import('./pages/board_view/board_view'), {
  fallback: <div></div>,
});

const VaccinePass = loadable(
  () => import('./pages/vaccine_pass/vaccine_pass'),
  {
    fallback: <div></div>,
  }
);

function App({ dataService, emailService, boardService }) {
  const history = useHistory();

  const auth = useAuth();
  const user = auth.user;

  function goToHome() {
    if (getSessionData('unos_logout')) {
      removeSessionData('unos_logout');
      history.push('/home');
      return;
    }

    if (history.location.state) {
      let from = history.location.state.from.pathname;
      history.push(from);
      return;
    }

    history.push('/home');
  }

  useEffect(() => {
    user && goToHome();
  }, [user]);

  useEffect(() => {
    auth.checkLogin();
  }, []);

  const preloadSalesTerm = () => {
    SalesTerm.preload();
  };

  return (
    <div className={styles.app}>
      <Switch>
        <Route
          path='/login'
          component={() => <Login preload={preloadSalesTerm} />}
        />

        <Layout>
          <RouteWrapper
            exact
            path={['/home', '/']}
            layout={Layout}
            component={() => <Home dataService={dataService} />}
          />
          <RouteWrapper
            path='/sales/term'
            layout={Layout}
            component={() => <SalesTerm dataService={dataService} />}
          />
          <RouteWrapper
            path='/sales/md'
            component={() => <SalesMd dataService={dataService} />}
            layout={Layout}
          />
          <RouteWrapper
            path='/sales/store'
            component={() => <SalesStore dataService={dataService} />}
            layout={Layout}
          />
          <RouteWrapper
            path='/sales/calendar'
            component={() => <SalesCalendar dataService={dataService} />}
            layout={Layout}
          />

          <RouteWrapper
            path='/sales/category'
            component={() => <SalesCategory dataService={dataService} />}
            layout={Layout}
          />
          <RouteWrapper
            path='/sales/paytype'
            component={() => <SalesPayType dataService={dataService} />}
            layout={Layout}
          />
          <RouteWrapper
            path='/sales/realtime'
            component={() => <SalesRealtime dataService={dataService} />}
            layout={Layout}
          />
          <RouteWrapper
            path='/sales/contrast'
            component={() => <SalesContrast dataService={dataService} />}
            layout={Layout}
          />
          <RouteWrapper
            path='/sales/device'
            component={() => <SalesDevice dataService={dataService} />}
            layout={Layout}
          />
          <RouteWrapper
            path='/stock/check'
            component={() => <Stock dataService={dataService} />}
            layout={Layout}
          />
          <RouteWrapper
            path='/stock/soldout'
            component={() => <StockSoldout dataService={dataService} />}
            layout={Layout}
          />

          <RouteWrapper
            path='/tax_request'
            component={() => <TaxRequest emailService={emailService} />}
            layout={Layout}
          />
          <RouteWrapper
            path='/manual'
            component={() => <Manual />}
            layout={Layout}
          />

          <RouteWrapper
            path='/menu/soldout'
            component={() => <MenuSoldout dataService={dataService} />}
            layout={Layout}
          />

          <RouteWrapper
            path='/menu/modify_old'
            component={() => <MenuModify />}
            layout={Layout}
          />

          <RouteWrapper
            path='/menu/modify'
            component={() => <MenuModifyV2 />}
            layout={Layout}
          />

          <RouteWrapper
            path='/option/modify'
            component={() => <OptionModify />}
            layout={Layout}
          />

          <RouteWrapper
            path='/vaccine_pass'
            component={() => <VaccinePass dataService={dataService} />}
            layout={Layout}
          />

          <RouteWrapper
            path='/test'
            layout={Layout}
            component={() => <TestPage dataService={dataService} />}
          />

          <RouteWrapper
            path='/board/new'
            layout={Layout}
            component={() => <BoardNew boardService={boardService} />}
          />

          <RouteWrapper
            path='/board/view/:category/:id'
            layout={Layout}
            component={() => <BoardView boardService={boardService} />}
          />

          <RouteWrapper
            path='/board/modify/:category/:id'
            layout={Layout}
            component={() => <BoardNew boardService={boardService} />}
          />

          <RouteWrapper
            path='/board/notice'
            layout={Layout}
            component={() => <Notice boardService={boardService} />}
          />
        </Layout>
      </Switch>
    </div>
  );
}

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default App;
