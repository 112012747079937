import React from 'react';

import { makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { colors } from '../../common/colors';

const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: 6,
    marginRight: 4,

    '& span': {
      fontSize: 14,
    },

    '& .MuiIconButton-label': {
      width: 18,
      height: 18,
      //color: colors.borderLightGrey,
    },

    '& .MuiIconButton-colorSecondary': {
      color: colors.grey,
    },

    /* '& .MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'rgba(255,152,0, 0.05)',
    },

    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.warning.main,
    }, */
  },

  icon: {
    width: 18,
    height: 18,
  },

  default: {
    '& .MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'rgba(255,152,0, 0.05)',
    },

    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: theme.palette.warning.main,
    },
  },

  violet: {
    '& .MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'rgba(217, 213, 236, 0.1)',
    },

    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: colors.darkViolet,
    },
  },
}));

const CustomIconCheckbox = (props) => {
  const classes = useStyles();
  const { label, value, onValueChange, color } = props;
  return (
    <FormControlLabel
      className={[classes.label, ColorClass(color)].join(' ')}
      color='primary'
      label={label}
      labelPlacement='start'
      onChange={onValueChange}
      control={
        <Checkbox
          className={classes.root}
          checked={value === 1 ? true : false}
          icon={<CheckCircleOutlinedIcon className={classes.icon} />}
          checkedIcon={<CheckCircleOutlinedIcon className={classes.icon} />}
          name='checked'
        />
      }
    />
  );
};

export default CustomIconCheckbox;

function ColorClass(color) {
  const classes = useStyles();

  switch (color) {
    case 'violet':
      return classes.violet;
    /* case 'Best':
      return classes.best;
    case 'Worst':
      return classes.worst;
    case 'Today':
      return classes.today;
    case 'Day':
      return classes.day; */
    default:
      return classes.default;

    /* default:
      throw new Error('unkown badge'); */
  }
}
