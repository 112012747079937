import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

import styles from './modal_portal.module.css';

const ModalPortal = ({ children, setOpen }) => {
  const ref = useRef();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);

    if (document) {
      const dom = document.getElementById('modal-root');
      ref.current = dom; // ref에 dom 값 전달
    }
  }, []);

  if (ref.current && mounted) {
    // mounted 됬고 dom이 존재하는 경우 모달 랜더링 진행
    return createPortal(
      <div className={styles.container}>
        <div
          className={styles.background}
          role='presentation'
          //onClick={() => setOpen(false)}
        />
        <div className={styles.content}>{children}</div>
      </div>,
      ref.current
    );
  }
  return null;
};

export default ModalPortal;
