import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';

import CardItem from '../card_item/card_item';

const cardStyles = makeStyles((theme) => ({
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: '1rem',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      height: 250,
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: '0.2rem',
      height: 200,
    },
  },

  card: {
    width: '24.8%',
    flexGrow: 1,
    minHeight: 130,

    [theme.breakpoints.down('sm')]: {
      width: '49%',
      minHeight: 100,
      fontSize: '15px',
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
}));

const Cards = memo(({ cards }) => {
  const classes = cardStyles();
  return (
    <>
      {cards.map((card, index) => (
        <div key={index} className={classes.card}>
          <CardItem card={card} />
        </div>
      ))}
    </>
  );
});

export default Cards;
