import React, { memo } from 'react';

import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

import DateFnsUtils from '@date-io/date-fns';
import { ko } from 'date-fns/esm/locale';
import { makeStyles } from '@material-ui/core';
import { colors } from '../../common/colors';

import {
  getFormatedDay,
  getFormatedMonth,
  getZeroSecDatetime,
} from '../../common/date';
import { format } from 'date-fns/esm';

const useStyles = makeStyles(() => ({
  picker: {
    width: 'fit-content',
    maxWidth: '8rem',
    //width: '7.4rem',
    padding: '0 4px',

    '& input': {
      height: '1rem',
      cursor: 'pointer',
      textAlign: 'center',
      color: colors.black400,
      cursor: 'pointer',
      fontSize: 15,
      fontWeight: 400,
      fontFamily: [
        'Noto Sans KR',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
      ],

      '&:hover': {
        color: colors.navy,
        fontWeight: 400,
      },
    },
  },

  calendarIcon: {
    fontSize: 18,
    color: '#c7c6e2',
    margin: '0 4px',
    cursor: 'pointer',
  },

  /* toolbar */
  toolbar: {
    height: '1.5em',

    '& h6:hover': {
      color: '#fff',
    },
  },

  toolbarSlash: {
    color: 'rgba(255, 255, 255, 0.54)',
    fontSize: '1.5em',
    padding: '0.5rem',
  },
}));

const DatePickerOne = memo(
  ({ value, setValue, type, disableFuture, isTimeValue, isClearIcon }) => {
    const classes = useStyles();
    const dateType =
      type === 'week'
        ? 'month'
        : type === 'time'
        ? isTimeValue
          ? 'time'
          : 'day'
        : type;

    const format =
      dateType === 'day'
        ? 'yyyy-MM-dd'
        : dateType === 'month'
        ? 'yyyy-MM'
        : 'yyyy-MM-dd HH:mm';

    const onChange = (value) => {
      setValue(
        dateType === 'day'
          ? getFormatedDay(value)
          : dateType === 'month'
          ? getFormatedMonth(value)
          : getZeroSecDatetime(value)
      );
    };

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ko}>
        {!isTimeValue ? (
          <DatePicker
            className={classes.picker}
            value={value}
            onChange={onChange}
            format={format}
            views={
              type === 'month' || type === 'week'
                ? ['year', 'month']
                : ['year', 'month', 'date']
            }
            disableFuture={disableFuture}
            ToolbarComponent={CustomToolbar}
            okLabel={<span>확인</span>}
            cancelLabel={<span>취소</span>}
            InputProps={{
              disableUnderline: true,
              endAdornment: isClearIcon ? null : (
                <InsertInvitationIcon className={classes.calendarIcon} />
              ),
            }}
          />
        ) : (
          <DateTimePicker
            className={classes.picker}
            value={value}
            //ampm={false}
            views={['year', 'month', 'date', 'hours', 'minutes']}
            onChange={onChange}
            format='yyyy-MM-dd HH:mm'
            disableFuture={disableFuture}
            okLabel={<span>확인</span>}
            cancelLabel={<span>취소</span>}
            //ToolbarComponent={CustomToolbar}
            InputProps={{
              disableUnderline: true,
            }}
          />
        )}
      </MuiPickersUtilsProvider>
    );
  }
);

export default DatePickerOne;

const CustomToolbar = (props) => {
  const classes = useStyles();

  const { date, isLandscape, openView, setOpenView, title, views } = props;
  const handleChangeViewClick = (view) => (e) => {
    setOpenView(view);
  };

  const yearLabel = format(date, 'yyyy');
  const monthLabel = format(date, 'MM');
  const dateLabel = format(date, 'dd');
  const isTimeValue = views.includes('hours');
  const timeLabel = isTimeValue ? format(date, 'HH:mm') : '';

  return (
    <PickerToolbar
      className={classes.toolbar}
      title={title}
      isLandscape={isLandscape}
    >
      <ToolbarButton
        onClick={handleChangeViewClick('year')}
        variant='h6'
        label={yearLabel}
        selected={openView === 'year'}
      />
      <span className={classes.toolbarSlash}> / </span>
      <ToolbarButton
        onClick={handleChangeViewClick('month')}
        variant='h6'
        label={monthLabel}
        selected={openView === 'month'}
      />

      {isTimeValue ? (
        <>
          <span className={classes.toolbarSlash}> / </span>
          <ToolbarButton
            onClick={handleChangeViewClick('date')}
            variant='h6'
            label={dateLabel}
            selected={openView === 'date'}
          />

          <span className={classes.toolbarSlash}> / </span>
          <ToolbarButton
            onClick={handleChangeViewClick('hours')}
            variant='h6'
            label={timeLabel}
            selected={openView === 'hours'}
          />
        </>
      ) : null}
    </PickerToolbar>
  );
};
