import React, { useEffect, useRef, useState } from 'react';
import { Grid, Button, makeStyles, Paper } from '@material-ui/core';
import { format } from 'date-fns';

import CustomDialog from '../../component/custom_dialog/custom_dialog';

import { useAuth } from '../../Context/Auth';

import styles from '../tax_request/tax_request.module.css';
import DatePickerOne from '../../component/date_picker_one/date_picker_one';
//import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '100%',
    maxWidth: 500,
    height: '100%',
    display: 'flex',
    padding: '1rem',
    margin: '0 auto',
    marginTop: '1rem',
  },
  button: {
    margin: '1rem',
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },

  alert: {
    width: '100%',
    height: '100%',
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
  },

  alertText: {
    marginTop: 18,
    color: '#616161',
    fontSize: 20,
    lineHeight: '32px',
    wordBreak: 'keep-all',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      lineHeight: '28px',
      marginTop: 10,
    },
  },

  closeIcon: {
    color: '#666',
    cursor: 'pointer',
  },
}));

const TaxRequest = ({ emailService }) => {
  const classes = useStyles();
  const formRef = useRef();
  //const startDateRef = useRef();
  //const endDateRef = useRef();
  const contactRef = useRef();
  const contactTypeRef = useRef();

  const auth = useAuth();
  const { user } = auth;
  const { id, name } = user;
  const { unosVan } = user || true;

  const [request, setRequest] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-M-d'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-M-d'));
  const [type, setType] = useState('Email');

  const onSubmit = (e) => {
    if (!unosVan) return;

    const start = format(new Date(startDate), 'yyyy-MM-dd');
    const end = format(new Date(endDate), 'yyyy-MM-dd');

    const contact = contactRef.current.value;
    const contactType = contactTypeRef.current.value;

    if (start > end) {
      alert('요청기간을 확인하세요.');
      return;
    }

    if (contact === '') {
      alert(`받으실 곳을 입력해 주세요.`);
      return;
    } else if (!validateContact(contact, contactType)) {
      let message = '이메일 주소를 바르게 입력해 주세요.';
      if (contactType !== 'Email') {
        message =
          'Fax 번호를 바르게 입력해 주세요.\r\n숫자와 - 만 사용가능합니다.';
      }
      alert(message);
      return;
    }

    const taxRequest = {
      startDate: start,
      endDate: end,
      contactType,
      contact,
      name,
      id,
    };
    setRequest(taxRequest);
  };

  useEffect(() => {
    request && setOpen(true);
  }, [request]);

  const validateContact = (value, type) => {
    let testValue = value;
    let reg = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w\w+)+$/;
    if (type === 'Fax') {
      testValue = value.replaceAll('-', '');
      reg = /^[0-9]*$/;
    }

    if (!reg.test(testValue)) {
      return false;
    } else {
      return true;
    }
  };

  const checkVan = (id) => {
    emailService
      .checkVan(id)
      .then((res) => {
        if (res === 1) {
          sendMail(request);
        } else {
          alert('권한이 없습니다.');
        }
        setOpen(false);
      })
      .catch((err) => {
        if (err.response) {
          // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          // 요청이 이루어 졌으나 응답을 받지 못했습니다.
          console.log(err.request);
        } else {
          // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
          console.log('Error', err.message);
        }
        console.log(err.config);
      });
  };

  const sendMail = (taxRequest) => {
    const from = 'engineer@unospay.com';
    //const to = 'yj_han@unospay.com'; //test
    const to = 'account@unospay.com';

    const subject = '부가세 신고자료 요청';
    const source = '홈페이지';
    const date = `신청기간 : ${taxRequest.startDate} ~ ${taxRequest.endDate}`;
    const affiliateName = `매장이름 : ${taxRequest.name}`;
    const affiliateId = `매장 ID : ${taxRequest.id}`;
    const contact = `연락받을 곳 (${taxRequest.contactType}) :  ${taxRequest.contact}`;

    const mailParams = {
      from,
      to,
      subject,
      source,
      date,
      affiliateName,
      affiliateId,
      contact,
    };

    emailService //
      .sendMail(mailParams)
      .then((res) => responseResult(res.data))
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
        }
        console.log(err.config);
      });
  };

  const onTypeChange = (e) => {
    setType(contactTypeRef.current.value);
  };

  function placeholder(type) {
    let contactType = type === 'Email' ? 'E-mail을' : 'FAX번호를';
    return `${contactType} 입력해주세요.`;
  }

  function responseResult(response) {
    if (response === 'Success') {
      formRef.current.reset();
      alert('문의해 주셔서 감사합니다.');
    } else {
      alert('전송실패. 메일주소를 확인하거나 전화문의 부탁드립니다.');
    }
  }

  return (
    <>
      {!unosVan ? (
        <div className={classes.alert}>
          <img
            src={process.env.PUBLIC_URL + '/images/etc/warning.png'}
            alt='warning'
          />
          <div className={classes.alertText}>
            지원하지 않는 기능입니다.
            <br /> <strong>담당 VAN대리점</strong>으로 요청 부탁드립니다.
          </div>
        </div>
      ) : (
        <Grid
          item={true}
          className={classes.formContainer}
          xs={12}
          component={Paper}
        >
          <form ref={formRef} className={styles.form}>
            <div>
              <div className={styles.row}>
                <span className={styles.title}>요청기간</span>
                <div className={styles.pickerBox}>
                  <div className={styles.picker}>
                    <DatePickerOne
                      value={startDate}
                      setValue={setStartDate}
                      type='day'
                      isClearIcon
                      disableFuture
                    />
                  </div>
                  <div className={styles.slash}> ~ </div>
                  <div className={styles.picker}>
                    <DatePickerOne
                      value={endDate}
                      setValue={setEndDate}
                      type='day'
                      isClearIcon
                      disableFuture
                    />
                  </div>
                </div>
              </div>

              <div className={styles.row}>
                <span className={styles.title}>받으실 곳</span>
                <div className={styles.contact}>
                  <select
                    className={styles.select}
                    onChange={onTypeChange}
                    ref={contactTypeRef}
                  >
                    <option value='Email'>Email</option>
                    <option value='Fax'>Fax</option>
                  </select>
                  <input
                    className={styles.inputText}
                    ref={contactRef}
                    type='text'
                    placeholder={placeholder(type)}
                  ></input>
                </div>
              </div>
            </div>

            <Button
              className={classes.button}
              variant='outlined'
              color='primary'
              name='Submit'
              onClick={onSubmit}
              disabled={!unosVan}
            >
              제출하기
            </Button>
          </form>
        </Grid>
      )}

      {open && (
        <CustomDialog
          open={open}
          handleClose={handleClose}
          contents={request}
          checkVan={checkVan}
        />
      )}
    </>
  );
};

export default TaxRequest;
