import React from 'react';

import { Grid, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { manulList } from './data';
import styles from '../../pages/manual/manual.module.css';

const Manual = () => {
  const onListClick = () => {
    alert('PC환경에서 다운로드가 가능합니다.');
  };

  //const userAgent = window.navigator.userAgent.toLowerCase();
  //const isWebview = /salesapp/i.test(userAgent);
  //let exportFlag = isWebview ? false : true;

  return (
    <Grid className={styles.listWrapper} component={Paper}>
      <ul>
        {manulList.map((manual) => {
          const { name, link, fileType } = manual;
          return (
            <li className={styles.list}>
              <div key={name} className={styles.content}>
                {name}

                <Link
                  className={styles.button}
                  to={link}
                  target='_blank'
                  download
                >
                  {fileType} 다운로드
                </Link>
              </div>
            </li>
          );
        })}
      </ul>
    </Grid>
  );
};

export default Manual;
