class EmailService {
  constructor(httpClient) {
    this.emailService = httpClient;
  }

  async checkVan(id) {
    const url = '/check_van.php';

    const result = await this.emailService
      .post(url, {
        id,
      })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  async sendMail(mailParams) {
    const url = '/send_email.php';

    /* function validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    } */

    const result = await this.emailService
      .post(url, { ...mailParams })
      .catch((error) => {
        console.log(error);
      });
    return result;
  }
}

export default EmailService;
