import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Collapse, Drawer, makeStyles } from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PhoneIcon from '@material-ui/icons/Phone';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
//import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';

import { useAuth } from '../../Context/Auth';
import { menu, studyMenus } from './menu';
import { hasChildren } from './utils';

import { getPageIcon } from '../../common/icons';

const menuWidth = 250;

const useStyles = makeStyles(() => ({
  icon: {
    minWidth: 40,
  },

  drawer: {
    flexShrink: 0,
  },

  drawerPaper: {
    paddingTop: '4.5rem',
    width: menuWidth,
    boxShadow: '10px 0 60px rgb(0 0 0 / 10%)',
  },

  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },

  menu: {
    '& selected': {
      backgroundColor: 'red',
    },
  },

  submenu: {
    backgroundColor: '#f5f5f5',
  },

  info: {
    color: '#454545',
    padding: '1rem',

    '& li': {
      paddingBottom: 4,
      display: 'flex',
      alignItems: 'center',
    },

    '& li span': {
      display: 'inline-block',
      width: '30%',
      fontWeight: 500,
    },
  },

  adBox: {
    overflow: 'hidden',
    height: 200,

    '& img': {
      height: '100%',
    },
  },

  dotIcon: {
    fontSize: '10px',
    margin: '0 auto',
  },
}));

const SideMenu = ({ onClose, open }) => {
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;

  const auth = useAuth();
  const role = auth?.user?.role;
  const id = auth?.user?.id;
  //const type = auth?.user?.type;

  const onCloseInMobile = () => {
    const innerWidth = window.innerWidth;
    innerWidth < 1024 && onClose();
  };

  return (
    <Drawer
      className={classes.drawer}
      variant='persistent'
      anchor='left'
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>
        <div className={classes.menu}>
          {menu.map((item, key) => (
            <MenuItem
              key={key}
              item={item}
              classes={classes}
              onClose={onClose}
              pathname={pathname}
              role={role}
            />
          ))}

          {id === 'emart24' && (
            <ListItem
              onClick={onCloseInMobile}
              className={classes.menu}
              button
              component={Link}
              to={'/menu/modify'}
            >
              <ListItemIcon className={classes.icon}>
                <EditOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary='메뉴수정' />
            </ListItem>
          )}
        </div>
        <InfoBox />
      </div>
    </Drawer>
  );
};

export default SideMenu;

const MenuItem = ({ item, onClose, pathname, role, isMultiLevel }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return (
    <Component
      item={item}
      onClose={onClose}
      pathname={pathname}
      role={role}
      isMultiLevel={isMultiLevel}
    />
  );
};

const SingleLevel = ({ item, onClose, pathname, role, isMultiLevel }) => {
  const classes = useStyles();
  const onMenuClick = () => {
    if (window.innerWidth <= 1280) {
      onClose();
    }
  };

  return (
    (!item.role ||
      item.role.findIndex((itemRole) => itemRole === role) !== -1) &&
    (!item.isBlank ? (
      <ListItem
        className={classes.menu}
        selected={pathname === item.url}
        onClick={onMenuClick}
        button
        component={Link}
        to={item.url}
      >
        <MenuIcon title={item.title} isMultiLevel={isMultiLevel} />
        <ListItemText primary={item.title} />
      </ListItem>
    ) : (
      <ListItem target='_blank' href={item.url} button component='a'>
        <MenuIcon title={item.title} />
        <ListItemText primary={item.title} />
      </ListItem>
    ))
  );
};

const MultiLevel = ({ item, onClose, pathname, role }) => {
  const classes = useStyles();
  let { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  //master / common 분류
  children = children.reduce((res, menu) => {
    if (!menu.role || menu.role.findIndex((item) => item === role) !== -1) {
      res.push(menu);
    }

    return res;
  }, []);

  return (
    <>
      <ListItem key={item.id} button onClick={handleClick}>
        <MenuIcon title={item.title} />
        <ListItemText primary={item.title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      <Collapse key={item.title} in={open} timeout='auto' unmountOnExit>
        <List className={classes.submenu} component='div' disablePadding>
          {children.map((child, key) => (
            <MenuItem
              key={key}
              item={child}
              onClose={onClose}
              pathname={pathname}
              role={role}
              isMultiLevel={true}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const infoIcons = {
  width: 16,
  marginRight: 6,
};

const MenuIcon = ({ isMultiLevel, title }) => {
  const classes = useStyles();
  return (
    <ListItemIcon className={classes.icon}>
      {isMultiLevel ? (
        <FiberManualRecordIcon className={classes.dotIcon} />
      ) : (
        getPageIcon(title)
      )}
    </ListItemIcon>
  );
};

const InfoBox = () => {
  const classes = useStyles();
  return (
    <div>
      <ul className={classes.info}>
        <li style={{ display: 'none' }}>
          <ListItem
            className={classes.menu}
            button
            component={Link}
            to={'/test'}
          ></ListItem>
        </li>
        <li>
          <PhoneIcon style={infoIcons} />
          <span>대표번호</span>: 1800 - 4520
        </li>
      </ul>
      {/* <div className={classes.adBox}>
        { <a href='https://forms.gle/pZXjxjuUUk6WfGYc7' target={'_blank'}>
          <img
            src={process.env.PUBLIC_URL + '/images/banner/menu_banner.png'}
            alt='banner'
          />
        </a>}
      </div> */}
    </div>
  );
};
