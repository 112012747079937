export const manulList = [
  {
    name: 'DID모니터 호출연동 매뉴얼',
    link: '/data/DID_manual.pdf',
    fileType: 'PDF',
  },
  {
    name: '유통관리자 매뉴얼',
    link: '/data/Distribution_manual_210406.pptx',
    fileType: 'PPT',
  },
  {
    name: '포스 매뉴얼 ver1.2',
    link: '/data/Pos_manual_ver1.2.pdf',
    fileType: 'PDF',
  },
  {
    name: '키오스크 매뉴얼 ver1.4',
    link: '/data/Kiosk_manual_ver1.4.pdf',
    fileType: 'PDF',
  },
];
