import React from 'react';

import Resizer from 'react-image-file-resizer';

import { useState, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 450,
    margin: '0 auto',
  },

  imgBox: {
    width: '100%',
    aspectRatio: 1.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    borderRadius: 8,
    marginBottom: '1rem',
    border: '1px solid #eeeeee',
    boxShadow: 'rgba(99, 99, 99, 0.1) 0px 2px 6px 0px',

    [theme.breakpoints.down('xs')]: {
      height: 240,
    },
  },

  imgForm: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    margin: '0 auto',
  },

  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },

  imgBtn: {
    color: '#0f1111',
    backgroundColor: '#fff',
    cursor: 'pointer',
    border: '1px solid #C7C6E2',
    borderRadius: 3,
    padding: '5px 15px',
    textDecoration: 'none',
    boxSizing: 'border-box',

    userSelect: 'none',

    '&:hover': {
      backgroundColor: '#F9F9FB',
    },

    '&:disabled': {
      backgroundColor: '#fafafa',
      cursor: 'default',
      opacity: 0.65,
    },
  },

  imgDescription: {
    width: '100%',
    height: 24,
    display: 'flex',
    margin: '0 auto',
    paddingTop: 6,
    fontSize: 13,
    marginBottom: 20,

    '& svg': {
      height: '100%',
    },
  },
}));

const SelectImage = ({ menuImage, setMenuImage, isLock }) => {
  const classes = useStyles();
  const imageInputRef = useRef(null);
  const defaultImg = process.env.PUBLIC_URL + '/images/defaultMenuImage.png';

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        480,
        360,
        'PNG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    });

  const onChangeImg = async (e) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files[0];

      try {
        const resizedFile = await resizeFile(file);

        let reader = new FileReader();
        reader.readAsDataURL(resizedFile);
        reader.onload = () => {
          const upload = reader.result;
          if (upload) {
            setMenuImage((old) => ({
              ...old,
              image: upload.toString(),
              uploadFile: resizedFile,
            }));
          }
        };
      } catch (err) {
        console.log(err);
      }
    }
  };

  const onImageSelect = (e) => {
    e.preventDefault();
    imageInputRef.current.click();
  };

  const onImageRemove = (e) => {
    e.preventDefault();

    if (menuImage.image) {
      setMenuImage((old) => ({
        ...old,
        image: null,
        uploadFile: null,
      }));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.imgBox}>
        {!menuImage || menuImage.loading ? (
          <div>loading...</div>
        ) : (
          <img
            className={classes.img}
            src={menuImage.image || defaultImg}
            alt='미리보기 이미지'
          />
        )}
      </div>
      <div className={classes.imgForm}>
        <div>
          <input
            ref={imageInputRef}
            type='file'
            name='menuImage'
            accept='image/png, image/jpeg, image/jpg'
            onChange={onChangeImg}
            style={{ display: 'none' }}
          ></input>
          <button
            disabled={isLock}
            className={classes.imgBtn}
            onClick={onImageSelect}
          >
            사진 선택
          </button>
        </div>

        <button
          disabled={isLock}
          className={classes.imgBtn}
          onClick={onImageRemove}
        >
          사진 삭제
        </button>
      </div>
      <span className={classes.imgDescription}>
        ✔ 권장 사진 사이즈 : 300 * 155
      </span>
    </div>
  );
};

export default SelectImage;
