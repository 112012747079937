import React from 'react';

import { useAuth } from '../../Context/Auth';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import { makeStyles } from '@material-ui/core/styles';
import { useMenuInfo } from '../../Context/MenuInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  appbar: {
    backgroundColor: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },

  toolbar: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },

  menuButton: {
    marginRight: 8,
  },

  titleBox: {
    display: 'flex',
    alignItems: 'center',
  },

  title: {
    flexGrow: 1,
    color: '#3f51b5',
    fontSize: 20,
  },

  logoImg: {
    height: 30,
    position: 'fixed',
    left: '45%',

    [theme.breakpoints.down('sm')]: {
      height: 20,
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  logoutBtn: {
    color: '#3f51b5',
    border: '1px solid #3f51b5',

    '&:hover': {
      backgroundColor: '#3f51b5',
      color: 'white',
    },
  },
}));

const Header = ({ onToggle }) => {
  const classes = useStyles();

  const auth = useAuth();
  const user = auth.user;

  const menuInfoData = useMenuInfo();

  const onLogout = () => {
    auth.onLogout();
    menuInfoData.clearMenuInfo();
  };

  return (
    <div className={classes.root}>
      <AppBar position='fixed' elevation={3} className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.titleBox}>
            <IconButton
              onClick={onToggle}
              edge='start'
              className={classes.menuButton}
              aria-label='menu'
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title}>
              {user && user.name}
            </Typography>
          </div>

          <img
            className={classes.logoImg}
            src={process.env.PUBLIC_URL + '/images/logo.png'}
            alt='unospay'
          />

          <Button onClick={onLogout} className={classes.logoutBtn}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
