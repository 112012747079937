import axios from 'axios';
import { splitDateRange, splitDateSameMonth } from '../common/date';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

class DataService {
  constructor(httpClient) {
    this.dataService = httpClient;
  }

  //home
  async getAllSales(params) {
    const url = '/all_sales.php';
    const { date, role } = params;
    const result = await this.dataService
      .post(`${url}`, {
        date,
        role,
      })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  async getTopStores(params) {
    const url = '/all_sales_store.php';
    const { date, role } = params;
    const result = await this.dataService
      .post(`${url}`, {
        date,
        role,
      })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  async getTopMenus(params) {
    const url = '/all_sales_md.php';
    const { date, role } = params;
    const result = await this.dataService
      .post(`${url}`, {
        date,
        role,
      })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  /* 기간별 조회 */
  async getSalesTermData(params) {
    let url = 'sales_term.php';
    const result = await this.dataService
      .post(`${url}`, params, {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          //cancel = c;
          //console.log(cancel);
        }),
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('post Request canceled');
        }
        console.log(error);
      });

    return result.data;
  }

  /* 기간별 조회 */
  async getAllSalesTermDataOld(params) {
    let url = 'sales_term.php';
    const { startDate, endDate } = params;
    const maxDateRange = 240;
    const dateRanges = splitDateRange(startDate, endDate, maxDateRange);

    let result = { data: [], error: false };
    let resultData = [];
    const maxDays = 60;

    for await (let dateRange of dateRanges) {
      if (result.error) return;
      let { startDate: start, endDate: end } = dateRange;
      let dates = splitDateRange(start, end, maxDays);

      let data = await axios
        .all(
          dates.map((date) =>
            this.dataService.post(
              `${url}`,
              {
                ...params,
                startDate: date.startDate,
                endDate: date.endDate,
              },
              { cancelToken: source.token }
            )
          )
        )
        .then((res) => {
          //console.log(res);
          return res.map((obj) => {
            const { data, status } = obj;
            if (status === 200 && !data.error) {
              return data.data;
            } else {
              result.error = true;
              return [];
            }
          });
        })
        .catch((err) => {
          result.error = true;
          console.log(err);
        });

      resultData = [...resultData, ...data];
    }

    result.data = resultData.flat();
    return result;
  }

  async getAllSalesTermData(params) {
    let url = 'sales_term.php';
    const { startDate, endDate } = params;

    let result = { data: [], error: false };
    let resultData = [];

    const maxDays = 60;
    const dateRanges =
      params.searchType === 'detail' && params.tab === 'month'
        ? splitDateSameMonth(startDate, endDate)
        : splitDateRange(startDate, endDate, maxDays);

    const promises = dateRanges.map(async (dateRange) => {
      return await this.dataService.post(url, {
        ...params,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
    });

    const results = await Promise.all(promises);
    //console.log('results', results);
    return results.map((result) => result.data.data).flat();
  }

  /* 상품별 조회 */
  async getMdSalesData(params) {
    const url = '/md_store.php';

    const result = await this.dataService
      .post(`${url}`, { ...params })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  /* 상품별 조회 new api */
  async getSalesMd(params) {
    const url = '/sales_md.php';

    const { startDate, endDate } = params;
    const maxDateRange = 80;
    const dateRanges = splitDateRange(startDate, endDate, maxDateRange);

    let result = { data: [], error: false };
    let resultData = [];
    const maxDays = 40;

    for await (let dateRange of dateRanges) {
      if (result.error) return;
      let { startDate: start, endDate: end } = dateRange;
      let dates = splitDateRange(start, end, maxDays);

      let data = await axios
        .all(
          dates.map((date) =>
            this.dataService.post(
              `${url}`,
              {
                ...params,
                startDate: date.startDate,
                endDate: date.endDate,
              },
              { cancelToken: source.token }
            )
          )
        )
        .then((res) => {
          return res.map((obj) => {
            const { data, status } = obj;
            if (status === 200 && !data.error) {
              return data.data;
            } else {
              result.error = true;
              return null;
            }
          });
        })
        .catch((err) => {
          result.error = true;
          console.log(err);
        });

      resultData = [...resultData, ...data];
    }

    result.data = resultData.flat();
    return result;
  }

  /* 매장별 상품 */
  async getStoreSales(params) {
    let url = '/sales_store.php';

    const result = await this.dataService //
      .post(`${url}`, { ...params })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  async getSalesStoreMenu(params) {
    let url = '/sales_store_menu.php';

    const result = await this.dataService
      .post(`${url}`, { ...params })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  async getSalesStoreMenuOld(params) {
    //console.log('getStoreMenuSales', params);
    let url = '/sales_store_menu.php';

    const { startDate, endDate } = params;

    const maxDateRange = 80;
    const dateRanges = splitDateRange(startDate, endDate, maxDateRange);

    let result = { data: [], error: false };
    let resultData = [];
    const maxDays = 30;

    for await (let dateRange of dateRanges) {
      if (result.error) return;
      let { startDate: start, endDate: end } = dateRange;
      let dates = splitDateRange(start, end, maxDays);

      let data = await axios
        .all(
          dates.map((date) =>
            this.dataService.post(
              `${url}`,
              {
                ...params,
                startDate: date.startDate,
                endDate: date.endDate,
              },
              { cancelToken: source.token }
            )
          )
        )
        .then((res) => {
          return res.map((menu) => {
            if (menu?.data?.result === 'success') {
              return menu.data.data;
            } else {
              result.error = true;
              return null;
            }
          });
        })
        .catch((err) => {
          result.error = true;
          console.log(err);
        });

      resultData.push(...data.flat());
    }

    result.data = resultData;
    return result;
  }

  cancelRequest() {
    source.cancel('Abort request!!!');
  }

  async getOptionInfoOld(optionCodeList) {
    let url = '/sales_store_option.php';
    let codes = sliceIntoChunks(optionCodeList, 5000);

    const result = await axios
      .all(
        codes.map((code) =>
          this.dataService.post(url, { optionCodes: code.join(',') })
        )
      )
      .then((res) => {
        return res.map((option) => option.data);
      })
      .catch((err) => console.log(err));

    return result;
  }

  async getOptionInfo(optionCodeList) {
    let url = '/sales_store_option.php';
    let codes = sliceIntoChunks(optionCodeList, 5000);

    const promises = codes.map(async (code) => {
      return await this.dataService.post(url, { optionCodes: code.join(',') });
    });

    const results = await Promise.all(promises);

    return results.map((result) => result.data).flat();
  }

  //캘린더 조회
  async getCalendarSales(params) {
    const url = '/calendar.php';
    const { startDate, endDate, role } = params;
    const result = await this.dataService
      .post(`${url}`, {
        startDate,
        endDate,
        role,
      })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  /* 카테고리별 조회 */
  async getCategorySales(params) {
    let url = '/etc_category.php';

    const result = await this.dataService
      .post(`${url}`, { ...params })
      .catch((error) => console.log(error));
    return result.data;
  }

  /* 결제수단별 조회 */
  async getPayTypeSales(params) {
    let url = '/etc_paytype.php';
    let affiliates = params.affiliates;

    if (affiliates === undefined || affiliates === '') {
      affiliates = 'all';
    }

    const result = await this.dataService
      .post(`${url}`, { ...params, affiliates })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  /* 비교조회 */
  async getContrastTermSales(params) {
    const url = '/contrast_term.php';
    const { startDate, endDate, role, contStartDate, contEndDate } = params;
    const result = await this.dataService
      .post(`${url}`, {
        startDate,
        endDate,
        contStartDate,
        contEndDate,
        role,
      })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  async getContrastMdSales(params) {
    const url = '/contrast_md.php';
    const { startDate, endDate, contStartDate, contEndDate, role, menus } =
      params;
    const result = await this.dataService
      .post(`${url}`, {
        startDate,
        endDate,
        contStartDate,
        contEndDate,
        role,
        menus,
      })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  //영수건별 조회
  async getRealTimeSales(params) {
    let url = '/etc_realtime.php';
    const startDate = params.startDate;
    const endDate = params.endDate;
    const role = params.role;
    let affiliates = params.affiliates;
    if (affiliates === undefined || affiliates === '') {
      affiliates = 'all';
    }
    const result = await this.dataService
      .post(`${url}`, {
        startDate,
        endDate,
        role,
        affiliates,
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  }

  //영수건별 조회 상세페이지 - onclick event
  async getRealTimeSalesLog(params) {
    let url = '/etc_realtime_log.php';
    const { dailyCode, affiliateName, affiliateId, status, cancelTime } =
      params;
    const result = await this.dataService
      .post(`${url}`, {
        dailyCode,
        affiliateName,
        affiliateId,
        status,
        cancelTime,
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  }

  /* 기기별 조회 */
  async getDeviceSales(params) {
    const url = '/device_term.php';
    const { startDate, endDate, tab } = params;
    const result = await this.dataService
      .post(`${url}`, {
        startDate,
        endDate,
        tab,
      })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  async getDeviceInfo() {
    const result = await this.dataService //
      .get('/device_info.php')
      .catch((err) => {
        console.log(err);
      });
    return result.data;
  }

  //재고조회
  async getStockState(params) {
    const url = '/stock.php';
    const { role, affiliates, menus } = params;
    const result = await this.dataService
      .post(`${url}`, {
        affiliates,
        menus,
        role,
      })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  /* 품절 기록 조회 */
  async getSoldoutLog(params) {
    //console.log(params);
    const url = '/sold_out.php';
    const result = await this.dataService
      .post(`${url}`, params)
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  //메뉴 수정
  //lock
  async isModifyLock() {
    const url = '/modify_info.php';
    const result = await this.dataService
      .post(`${url}`)
      .catch((err) => console.log(err));
    return result.data;
  }

  /* 나중에 userinfo 확인하고 지울거 */
  //category
  async getCategoryList() {
    const url = '/modify_category.php';
    const action = 'getList';
    const result = await this.dataService
      .post(`${url}`, { action })
      .catch((err) => console.log(err));
    return result.data;
  }

  //option
  async getMenuInfoData(params) {
    const url = '/menu_information.php';
    const result = await this.dataService
      .post(`${url}`, { ...params })
      .catch((err) => console.log(err));

    return result.data;
  }
  /* 지울거 end */

  /* 재고관리 - 품절 */
  //get menu info
  async getMenuInfo() {
    const url = '/menu_info.php';
    const result = await this.dataService.post(`${url}`, {}).catch((error) => {
      console.log(error);
    });
    return result.data;
  }

  //update menu soldout, stock
  async updateMenuSoldout(updated) {
    const url = '/menu_update.php';
    const result = await this.dataService
      .post(`${url}`, {
        updated,
      })
      .catch((error) => {
        console.log(error);
      });
    return result.data;
  }

  //vaccine info
  async getVaccineData() {
    const url = '/vaccine.php';
    const result = await this.dataService
      .post(`${url}`)
      .catch((err) => console.log(err));

    return result.data;
  }

  //vaccine list update
  async updateVaccinePassList(updatedList) {
    const url = '/vaccine_update.php';
    const result = await this.dataService
      .post(`${url}`, { updatedList })
      .catch((err) => console.log(err));

    return result.data;
  }
}

export default DataService;

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}
