import React from 'react';

import ImageIcon from '@material-ui/icons/Image';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { makeStyles } from '@material-ui/core';
import { colors } from '../../common/colors';

const useStyles = makeStyles(() => ({
  icon: {
    width: 22,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    marginRight: 6,
    border: `1px solid ${colors.grey}`,
    borderRadius: '50%',

    '& svg': {
      width: '100%',
      color: colors.blue,
    },
  },
}));

const AttachIcon = ({ extension }) => {
  const classes = useStyles();
  const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG', 'ico', 'bmp', 'wepp'];

  return (
    <div className={classes.icon}>
      {imageExtensions.includes(extension) ? (
        <ImageIcon />
      ) : (
        <DescriptionOutlinedIcon />
      )}
    </div>
  );
};

export default AttachIcon;
