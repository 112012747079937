import React from 'react';

import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import { colors } from '../../common/colors';

const VioletTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors.violet,
    },

    '& .MuiInput-underline:after': {
      borderBottomColor: colors.violet,
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: 0,
      },
      '&:hover fieldset': {
        borderColor: colors.violet,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.violet,
      },
    },
  },
})(TextField);

const VioletSwitch = withStyles({
  root: {
    width: 54,
    height: 30,
    padding: 7,
  },

  switchBase: {
    color: '#FAFAFA',
    top: -5,
    left: -3,

    '&$checked': {
      color: colors.violet,
    },
    '&$checked + $track': {
      backgroundColor: colors.violet,
    },
  },

  checked: {},

  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    padding: 5,
  },
})(Switch);

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },

  halfWidth: {
    flexBasis: '45%',
    flex: 1,
  },
}));

const CustomInput = ({ onChange, ...props }) => {
  const classes = useStyles();
  const {
    name,
    label,
    value,
    inputProps,
    isCheckbox,
    isHalfWidth,
    isMultiline,
    isRequired,
    disabled,
    error,
    isSelect,
    selectList,
  } = props;

  if (isCheckbox) {
    return (
      <FormControlLabel
        control={
          <VioletSwitch
            checked={value * 1 === 1 ? true : false}
            onChange={onChange}
            name={name}
            disabled={disabled || false}
          />
        }
        label={label}
      />
    );
  } else if (isSelect) {
    return (
      <VioletTextField
        className={classes.select}
        value={value || 'default'}
        variant='outlined'
        name={name}
        label={label}
        select
        fullWidth
        onChange={onChange}
        multiline={isMultiline || false}
        disabled={disabled || false}
        required={isRequired || false}
        error={error && error[name] ? true : false}
        helperText={error && error[name] ? error[name] : ''}
        SelectProps={{
          native: true,
        }}
      >
        <option value='default'>--선택하세요--</option>
        {selectList &&
          selectList.map((option, index) => (
            <option key={option.code} value={option.code}>
              {option.name}
            </option>
          ))}
      </VioletTextField>
    );
  } else {
    return (
      <VioletTextField
        className={isHalfWidth ? classes.halfWidth : classes.fullWidth}
        value={value || value === 0 ? value : ''}
        variant='outlined'
        color='primary'
        name={name}
        label={label}
        onChange={onChange}
        multiline={isMultiline || false}
        disabled={disabled || false}
        required={isRequired || false}
        error={error && error[name] ? true : false}
        helperText={error && error[name] ? error[name] : ''}
        inputProps={inputProps}
      />
    );
  }
};

export default CustomInput;
