import React from 'react';
import styles from './scroll_button.module.css';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const ScrollButton = ({ visible }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      className={styles.button}
      onClick={scrollToTop}
      style={{ display: visible ? 'inline' : 'none' }}
    >
      <ArrowUpwardIcon />
    </button>
  );
};

export default ScrollButton;
