export const optionInfos = [
  {
    title: '기본',
    list: [
      {
        label: '옵션명',
        name: 'option_name',
        isRequired: true,
        helperText: '옵션명은 필수입니다.',
      },
      {
        label: '옵션가격',
        name: 'option_price',
        isHalfWidth: true,
        isNum: true,
        inputProps: { inputMode: 'numeric' },
        isRequired: true,
        helperText: '옵션가격은 필수입니다.(숫자만 입력)',
      },
      {
        label: '그룹명',
        name: 'group_name',
        isHalfWidth: true,
        isRequired: true,
        helperText: '그룹명은 필수입니다.',
      },
      {
        label: '최소선택',
        name: 'min_select',
        isHalfWidth: true,
        isNum: true,
        inputProps: {
          inputMode: 'numeric',
          min: 0,
        },
        helperText: '최대선택보다 작거나 같아야 합니다.(숫자만 입력, 최솟값 0)',
      },
      {
        label: '최대선택',
        name: 'max_select',
        isHalfWidth: true,
        isNum: true,
        inputProps: { min: 1, max: 999, inputMode: 'numeric' },
        helperText:
          '최소선택보다 크거나 같아야 합니다.(숫자만 입력, 최솟값 1, 최댓값 999)',
      },
      {
        label: '품절여부',
        name: 'sold_out',
        isCheckbox: true,
      },
      {
        label: '출력여부',
        name: 'print_setting',
        isCheckbox: true,
      },
    ],
  },
  {
    title: '다국어(옵션명/그룹명)',
    list: [
      {
        label: '옵션명(영)',
        name: 'name_eng',
        isHalfWidth: true,
      },
      {
        label: '그룹명(영)',
        name: 'group_name_eng',
        isHalfWidth: true,
      },
      {
        label: '옵션명(중)',
        name: 'name_ch',
        isHalfWidth: true,
      },
      {
        label: '그룹명(중)',
        name: 'group_name_ch',
        isHalfWidth: true,
      },
      {
        label: '옵션명(일)',
        name: 'name_jp',
        isHalfWidth: true,
      },
      {
        label: '그룹명(일)',
        name: 'group_name_jp',
        isHalfWidth: true,
      },
    ],
  },
  {
    title: '기타',
    list: [
      {
        label: '컵 갯수',
        name: 'cup_count',
        isHalfWidth: true,
        isNum: true,
        inputProps: { min: 0, inputMode: 'numeric' },
      },
      {
        label: '외부코드',
        name: 'external_code',
        isHalfWidth: true,
      },
      {
        label: 'New',
        name: 'new',
        isCheckbox: true,
      },
      {
        label: 'Best',
        name: 'best',
        isCheckbox: true,
      },
      {
        label: 'Sale',
        name: 'sale',
        isCheckbox: true,
      },
      {
        label: '한정',
        name: 'limited',
        isCheckbox: true,
      },
    ],
  },
];

export const categoryInfos = [
  {
    title: '기본',
    list: [
      {
        label: '카테고리명',
        name: 'name',
        isRequired: true,
        helperText: '카테고리명은 필수입니다.',
      },
      {
        label: '영어',
        name: 'nameEng',
      },
      {
        label: '일어',
        name: 'nameJp',
      },
      {
        label: '중국어',
        name: 'nameCh',
      },
      {
        label: '키오스크 노출',
        name: 'visible',
        isCheckbox: true,
      },
    ],
  },
];

export const menuInfos = [
  [
    {
      title: '필수 기입사항',
      list: [
        {
          label: '카테고리',
          name: 'category',
          isSelect: true,
          isRequired: true,
        },
        {
          label: '메뉴명',
          name: 'name',
          isRequired: true,
        },
        {
          label: '가격',
          name: 'price',
          isHalfWidth: true,
          isNum: true,
          inputProps: { inputMode: 'numeric' },
          isRequired: true,
        },
        {
          label: '면세금액',
          name: 'noTaxPrice',
          isHalfWidth: true,
          isNum: true,
          inputProps: { min: 0, inputMode: 'numeric' },
          isRequired: true,
        },
        {
          label: '재고',
          name: 'stock',
          isHalfWidth: true,
          isNum: true,
          inputProps: { inputMode: 'numeric' },
          isRequired: true,
        },
        {
          label: '최대재고',
          name: 'maxStock',
          isHalfWidth: true,
          isNum: true,
          inputProps: { inputMode: 'numeric' },
          isRequired: true,
        },
        {
          label: '기본샷 갯수 (카페모드 사용자만 해당)',
          name: 'baseShot',
          isNum: true,
          inputProps: { inputMode: 'numeric' },
          //isCheckbox: true,
        },
      ],
    },
    {
      title: '주방 별칭',
      list: [
        {
          label: '주방 주문서에 노출되는 별칭',
          name: 'nameForKitchen',
        },
      ],
    },
    {
      title: '다국어',
      list: [
        {
          label: '영어 이름',
          name: 'nameEng',
        },
        {
          label: '일본어 이름',
          name: 'nameJp',
        },
        {
          label: '중국어 이름',
          name: 'nameCh',
        },
      ],
    },
    {
      title: '코드/라벨',
      list: [
        {
          label: '쥬씨 제품코드',
          name: 'juicyProductCode',
          isHalfWidth: true,
        },
        {
          label: '기프티콘 코드',
          name: 'gifticonCode',
          isHalfWidth: true,
        },
        {
          label: '바코드 번호',
          name: 'barcode',
        },
        {
          label: '라벨 출력 장수',
          name: 'labelCnt',
          isHalfWidth: true,
          inputProps: { min: 0, inputMode: 'numeric' },
        },
        {
          label: '외부 코드',
          name: 'externalCode',
          isHalfWidth: true,
        },
      ],
    },
  ],
  [
    {
      title: '메뉴설명',
      list: [
        {
          label: '설명',
          name: 'info',
        },
        {
          label: '영어 설명',
          name: 'infoEng',
        },
        {
          label: '일본어 설명',
          name: 'infoJp',
        },
        {
          label: '중국어 설명',
          name: 'infoCh',
        },
      ],
    },
    {
      title: '메뉴 서브네임',
      list: [
        {
          label: '서브네임',
          name: 'subName',
        },
        {
          label: '영어 서브네임',
          name: 'subNameEng',
        },
        {
          label: '일본어 서브네임',
          name: 'subNameJp',
        },
        {
          label: '중국어 서브네임',
          name: 'subNameCh',
        },
      ],
    },
    {
      title: '메뉴 정보',
      list: [
        {
          label: '칼로리',
          name: 'kcal',
          isHalfWidth: true,
          inputProps: { min: 0, inputMode: 'numeric' },
        },
        {
          label: '원산지',
          name: 'origin',
          isHalfWidth: true,
        },
        {
          label: '영양성분',
          name: 'nutrition',
        },
      ],
    },
  ],
];
