import { addMonths } from 'date-fns';
import {
  format,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  lastDayOfMonth,
} from 'date-fns/esm';

export function getZeroSecDatetime(value) {
  return format(new Date(value).setSeconds(0), 'yyyy-MM-dd HH:mm:ss');
}

export function getFormatedDay(date) {
  return date
    ? format(new Date(date), 'yyyy-MM-dd')
    : format(new Date(date), 'yyyy-MM-dd');
}

export function getFormatedMonth(date) {
  return date && format(new Date(date), 'yyyy-MM');
}

export function getSubdate(date, subDate, dateType) {
  if (dateType === 'day') {
    return getFormatedDay(subDays(new Date(date), subDate));
  } else if (dateType === 'month') {
    return getFormatedMonth(subMonths(new Date(date), subDate));
  }
}

export function getStartOfMonth(date) {
  return date && getFormatedDay(startOfMonth(new Date(date)));
}

export function getStartOfYear(date) {
  return date && getFormatedDay(startOfYear(new Date(date)));
}

export function getLastOfMonth(date) {
  return date && getFormatedDay(lastDayOfMonth(new Date(date)));
}

export function getLastDayofMonth(date) {
  let newDate =
    lastDayOfMonth(new Date(date)) > new Date()
      ? new Date()
      : lastDayOfMonth(new Date(date));
  return format(newDate, 'yyyy-MM-dd');
}

export function getPeriod(id) {
  let startDate = getFormatedDay(new Date());
  let endDate = getSubdate(new Date(), 1, 'day');

  if (id === 'today') {
    endDate = startDate;
  } else if (id === 'yesterday') {
    startDate = endDate;
  } else if (id === 'week') {
    startDate = getSubdate(endDate, 6, 'day');
  } else if (id === 'thisMonth') {
    startDate = getStartOfMonth(endDate);
  } else if (id === 'lastMonth') {
    endDate = getLastOfMonth(getSubdate(endDate, 1, 'month'));
    startDate = getStartOfMonth(endDate);
  } else if (id === 'thisMonth') {
    startDate = getStartOfMonth(endDate);
  } else if (id === 'last3Months') {
    startDate = getStartOfMonth(getSubdate(endDate, 2, 'month'));
  } else if (id === 'last6Months') {
    startDate = getStartOfMonth(getSubdate(endDate, 5, 'month'));
  } else if (id === 'thisYear') {
    startDate = getStartOfYear(endDate);
  }

  return { startDate, endDate };
}

export function splitDateRange(startDate, endDate, maxDays) {
  let dateArr = [];

  let start = new Date(startDate);

  while (start <= new Date(endDate)) {
    let newStartDate = getFormatedDay(start);
    if (start === new Date(endDate)) {
      dateArr.push({ startDate: newStartDate, endDate: newStartDate });
    } else {
      let end = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate() + maxDays
      );

      if (end > new Date(endDate)) end = endDate;

      let newEndDate = getFormatedDay(end);
      dateArr.push({ startDate: newStartDate, endDate: newEndDate });
    }

    start.setDate(start.getDate() + maxDays + 1);
  }
  return dateArr;
}

export function splitDateSameMonth(startDate, endDate) {
  let dateArr = [];

  let start = new Date(startDate);

  while (start <= new Date(endDate)) {
    let newStartDate = getFormatedDay(start);
    if (start === new Date(endDate)) {
      dateArr.push({ startDate: newStartDate, endDate: newStartDate });
    } else {
      let end = new Date(getLastOfMonth(start));

      if (end > new Date(endDate)) end = endDate;

      let newEndDate = getFormatedDay(end);
      dateArr.push({ startDate: newStartDate, endDate: newEndDate });
    }

    start = addMonths(start, 1);
  }
  return dateArr;
}
