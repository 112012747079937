//import { client } from './client';
import { httpClient } from './axios';

const baseUrl = 'option.php';
const getOptionList = (id, type, code) => {
  let menuCode = type === 'all' ? 0 : code;
  return httpClient
    .get(`${baseUrl}?id=${id}&type=${type}&code=${menuCode}`)
    .then((res) => res.data);
};

const addOption = (action, menuCode, option) => {
  return httpClient
    .post(baseUrl, { action, menuCode, option })
    .then((res) => res.data);
};

const deleteOption = (menuCodes) => {
  return httpClient
    .delete(`${baseUrl}?codes=${menuCodes}`)
    .then((res) => res.data);
};

const updateOption = (options) => {
  return httpClient.put(baseUrl, { options }).then((res) => res.data);
};

/* 옵션 전체 */
const allOptionBaseUrl = 'option_all.php';
const getAllOptionList = (id) => {
  return httpClient.get(`${allOptionBaseUrl}?id=${id}`).then((res) => res.data);
};

const deleteAllOption = (loginId, id, option) => {
  //console.log('deleteAllOption', id, option);
  return httpClient
    .delete(`${allOptionBaseUrl}?id=${id}`, { data: { loginId, id, option } })
    .then((res) => res.data);
};

const updateAllOption = (loginId, id, originOption, newOption) => {
  //console.log('updateAllOption', id, originOption, newOption);
  return httpClient
    .put(`${allOptionBaseUrl}?id=${id}`, {
      loginId,
      id,
      originOption,
      newOption,
    })
    .then((res) => res.data);
};

const OptionService = {
  getOptionList,
  addOption,
  deleteOption,
  updateOption,
  getAllOptionList,
  deleteAllOption,
  updateAllOption,
};

export default OptionService;
