//import { client } from './client';
import { httpClient } from './axios';

const baseUrl = 'category.php';

const getCategoryList = (id, type) => {
  return httpClient
    .get(`${baseUrl}?id=${id}&type=${type}`)
    .then((res) => res.data);
};

const addOrUpdateCategory = (type, category, id) => {
  return type === 'add'
    ? httpClient.post(baseUrl, { category, id }).then((res) => res.data)
    : type === 'modify'
    ? httpClient.put(baseUrl, { category, id }).then((res) => res.data)
    : httpClient.patch(baseUrl, { category, id }).then((res) => res.data);
};

const deleteCategory = (codes, id) => {
  return httpClient
    .delete(`${baseUrl}?id=${id}&codes=${codes}`)
    .then((res) => res.data);
};

const CategoryService = {
  getCategoryList,
  addOrUpdateCategory,
  deleteCategory,
};

export default CategoryService;
