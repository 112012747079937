import React, { createContext, useContext, useState } from 'react';
import { Cookies } from 'react-cookie';
//import { useCookies } from 'react-cookie';

import LoginService from '../service/login_service';

import { clearSessionData, setSessionData } from '../common/session';
import { httpClient } from '../service/axios';

const cookies = new Cookies();

const loginService = new LoginService(httpClient);

const authContext = createContext();

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const onLogin = ({ id, password }) => {
    loginService //
      .login(id, password)
      .then((res) => {
        //console.log(res.data);
        if (!res) {
          setUser(null);
          return;
        }

        if (res.data[0] === 'login_failed') {
          alert('아이디 또는 비밀번호 오류입니다.');
        } else if (res.data[0] === 'denied') {
          alert('접근이 제한된 아이디입니다.');
        } else {
          /* new login */
          //getUserInfo(id, res.data.user.role);
          /* End new login */

          let user = res.data.user;
          if (!user) return;
          if (user.role === 'common') {
            user.affiliates = user.id;
            user.unosVan = user.unosVan === 1 ? true : false;
          } else {
            user.affiliates = user.affiliates.map((a) => ({
              label: a[1],
              value: a[0],
            }));
          }
          setUser({ ...user });
        }
      });
  };

  const onLogout = () => {
    loginService
      .logout() //
      .then(clearSessionData())
      .then(setSessionData('unos_logout', true))
      .then(setUser(null));
  };

  const checkLogin = () => {
    loginService
      .check() //
      .then((res) => {
        //console.log(res);
        if (!res || res.data.is_login === false) {
          setUser(null);
          return;
        }

        let user = res.data.user;
        if (user.role === 'common') {
          user.affiliates = user.id;
        } else {
          user.affiliates = user.affiliates.map((a) => ({
            label: a[1],
            value: a[0],
          }));
        }

        setUser({ ...user });
      });
  };

  const getUserInfo = (id, role) => {
    //console.log('getUserInfo!!!', id, role);
    loginService
      .getUserInfo(id, role)
      .then((res) => {
        let user = res.data;

        if (user.role === 'common') {
          user.affiliates = user.id;
        } else {
          user.affiliates = user.affiliates.map((a) => ({
            label: a.id,
            value: a.name,
          }));
        }

        //setUser({ ...user });
        //setSessionData('user', user);
      })
      .catch((err) => console.log(err));
  };

  //token 방식 로그인
  const login_test = ({ id, password }) => {
    //console.log(id, password);

    loginService
      .login_test(id, password)
      .then((res) => {
        console.log(res);
        //JWT
        const jwt = res.data.jwt;
        const { access_token, refresh_token } = jwt;
        console.log('login_test access_token', access_token);
        localStorage.setItem('accessToken', access_token);

        const onTwoHours = 2 * 60 * 60;
        //oneday = 24 * 60 * 60

        cookies.set('refresh_token', refresh_token, {
          path: '/',
          maxAge: onTwoHours,
          sameSite: 'lax',
          httpOnly: true,
        });

        /* setCookie('refresh_token', refresh_token, {
          path: '/',
          maxAge: onTwoHours,
          sameSite: 'lax',
        }); */

        //USER
        const user = res.data.user;
        console.log('user', user);
        const { id, role } = user;
        getUserInfo(id, role);
      })
      .catch((err) => console.log(err));
  };

  const getJWT = () => {
    loginService
      .getJwt()
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const checkLoginTest = () => {
    const accessToken = localStorage.getItem('accessToken');
    console.log(accessToken);
    if (!accessToken) {
      return { state: 'fail', msg: 'access token is not exsit' };
    }

    loginService
      .onSilentRefresh()
      .then((res) => console.log('onSilentRefresh', res.data))
      .catch((err) => console.log(err));
  };

  return {
    user,
    onLogin,
    onLogout,
    getUserInfo,
    checkLogin,
    getJWT,
    login_test,
    checkLoginTest,
  };
}
