import { format, lastDayOfMonth, subDays, parseISO } from 'date-fns';
import { ko } from 'date-fns/esm/locale';

import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachHourOfInterval,
} from 'date-fns/esm';

export function toCurrency(numberString) {
  if (numberString === undefined) {
    return 0;
  }
  let number = parseInt(numberString);
  return number.toLocaleString('USD');
}

//default date ver
export function getDateRows(startDate, endDate, tab) {
  let start = new Date(startDate);
  let end = new Date(endDate);

  if (tab === 'day') {
    return eachDayOfInterval({
      start,
      end,
    }).map((d) => format(d, 'yyyy-MM-dd'));
  } else if (tab === 'month') {
    return eachMonthOfInterval({
      start,
      end,
    }).map((d) => format(d, 'yyyy-MM'));
  } else if (tab === 'week') {
    return ['2', '3', '4', '5', '6', '7', '1'];
  } else {
    return eachHourOfInterval({
      start: new Date(2021, 5, 13, 0),
      end: new Date(2021, 5, 13, 23),
    }).map((d) => format(d, 'H'));
  }
}

//short date ver
export function formatDate(value, tab) {
  if (tab === 'day') {
    return format(new Date(value), 'M-d (eee)', { locale: ko });
  } else if (tab === 'month') {
    return format(new Date(value), 'MM월', { locale: ko });
  } else if (tab === 'week') {
    return getWeekDay(value);
  } else {
    return `${value}:00 ~ ${value}:59`;
  }
}

export function formatDateValue(value, tab) {
  if (!tab || (value !== 0 && !value)) return '';
  if (value === '총 합계') return value;

  if (tab === 'day') {
    return format(new Date(value), 'yyyy-MM-dd');
  } else if (tab === 'month') {
    return format(new Date(value), 'yyyy-MM');
  } else if (tab === 'time') {
    return `${value}:00 ~ ${value}:59`;
  } else {
    return getWeekDay(value);
  }
}

export function getWeekDay(value) {
  const weekday = [
    '일요일',
    '월요일',
    '화요일',
    '수요일',
    '목요일',
    '금요일',
    '토요일',
  ];
  return weekday[value - 1];
}

export function getFormatedDay(value) {
  return format(new Date(value), 'yyyy-MM-dd');
}

export function getFormatedDatetime(value) {
  return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
}

export function makeFormatedDatetime(value) {
  return format(
    new Date(value.replace(' ', 'T')).setSeconds(0),
    'yyyy-MM-dd HH:mm:ss'
  );
}

export function getSubDay(date, subNum) {
  return format(subDays(new Date(date), subNum), 'yyyy-MM-dd');
}

export function getLastDayofMonth(date) {
  let newDate =
    lastDayOfMonth(new Date(date)) > new Date()
      ? new Date()
      : lastDayOfMonth(new Date(date));
  return format(newDate, 'yyyy-MM-dd');
}

export function getDatetime(date) {
  return date
    ? format(new Date(date.replace(' ', 'T')), 'yyyy-MM-dd HH:mm')
    : '';
}

export function generateDatetime() {
  return format(new Date(), 'yyyyMMdd-HHmmss');
}

export function getDateTimeToExport() {
  return format(new Date(), 'yyyy-MM-dd-HH-mm');
}
