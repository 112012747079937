class BoardService {
  constructor(httpClient) {
    this.boardService = httpClient;
  }

  async getBoardList(id, category, role) {
    const url = `/board_list.php?id=${id}&category=${category}&type=${role}`;
    const result = await this.boardService
      .get(url)
      .catch((err) => console.log(err));

    return result.data;
  }

  async getBoard(id, category, boardId) {
    const url = `/notice.php?id=${id}&category=${category}&boardId=${boardId}`;
    const result = await this.boardService
      .get(url)
      .catch((err) => console.log(err));
    return result.data;
  }

  async postBoard(postProps) {
    const url = `/notice.php`;
    const result = await this.boardService
      .post(url, { ...postProps })
      .catch((err) => console.log(err));

    return result.data;
  }

  async updateBoard(postProps) {
    const url = `/notice.php`;
    const result = await this.boardService
      .put(url, { ...postProps })
      .catch((err) => console.log(err));

    return result.data;
  }

  async deleteNotice(id, category, noticeId) {
    const url = `notice.php?id=${id}&category=${category}&boardId=${noticeId}`;
    const result = await this.boardService
      .delete(url)
      .catch((err) => console.log(err));

    return result.data;
  }

  async updateVisit(name, boardId, noticeId) {
    const url = 'board_visit.php';
    const result = await this.boardService
      .post(url, { name, boardId, noticeId })
      .catch((err) => console.log(err));

    return result.data;
  }

  //attach file
  async getAttachFileList(boardId, noticeId) {
    const result = await this.boardService
      .get(`/board_get_file.php?boardId=${boardId}&noticeId=${noticeId}`)
      .catch((err) => console.log(err));

    return result.data;
  }

  async uploadAttachFile(filedata) {
    const result = await this.boardService
      .post('/board_upload_file.php', filedata, {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          let percentage = (progressEvent.loaded * 100) / progressEvent.total;
          console.log(percentage);
        },
      })
      .catch((err) => console.log(err));

    return result.data;
  }

  async deleteAttachFile(fileName, extension) {
    const result = await this.boardService
      .post('/board_delete_file.php', { fileName, extension })
      .catch((err) => console.log(err));

    return result.data;
  }

  async deleteAttachFileWithId(boardId, noticeId) {
    const result = await this.boardService
      .delete(`/board_delete_file.php?boardId=${boardId}&noticeId=${noticeId}`)
      .catch((err) => console.log(err));

    return result.data;
  }

  //poll
  async getPollList(boardId, noticeId) {
    const result = await this.boardService
      .get(`/poll.php?boardId=${boardId}&noticeId=${noticeId}`)
      .catch((err) => console.log(err));

    return result.data;
  }

  async checkPollSubmit(userName, pollId, type) {
    const result = await this.boardService
      .get(`poll_answer.php?userName=${userName}&pollId=${pollId}&type=${type}`)
      .catch((err) => console.log(err));

    return result.data;
  }

  async pollSubmit(userName, pollId, selectedOption) {
    const result = await this.boardService
      .post('poll_answer.php', { userName, pollId, selectedOption })
      .catch((err) => console.log(err));

    return result.data;
  }

  async getPollResult(pollId, type) {
    const result = await this.boardService
      .get(`/poll_result.php?pollId=${pollId}&type=${type}`)
      .catch((err) => console.log(err));

    return result.data;
  }

  async deletePoll(pollId) {
    const result = await this.boardService
      .delete(`/poll.php?pollId=${pollId}`)
      .catch((err) => console.log(err));

    return result.data;
  }
}

export default BoardService;
