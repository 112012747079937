export const defaultMenu = {
  category: 'default',
  name: '',
  price: '',
  noTaxPrice: 0,
  baseShot: 0,

  nameForKitchen: '',
  nameEng: '',
  nameCh: '',
  nameJp: '',

  subName: '',
  subNameEng: '',
  subNameCh: '',
  subNameJp: '',

  stock: 999,
  maxStock: 999,

  juicyProductCode: '',
  gifticonCode: '',
  barcode: '',
  labelCnt: 1,
  externalCode: '',

  info: '',
  infoEng: '',
  infoCh: '',
  infoJp: '',

  kcal: '',
  origin: '',
  nutrition: '',

  visible: 1,
  soldOut: 0,
  package: 0,
  packageFlag: -1,
  saveStamp: 1,

  limitedMenu: 0,
  bestMenu: 0,
  newMenu: 0,
  saleMenu: 0,

  alcoholMenu: 0,
};

export const defaultCategory = {
  name: '',
  nameEng: '',
  nameJp: '',
  nameCh: '',
  visible: 1,
};

export const categoryInfo = [
  {
    label: '카테고리명',
    value: 'name',
  },
  {
    label: '영어',
    value: 'nameEng',
  },
  {
    label: '일어',
    value: 'nameJp',
  },
  {
    label: '중국어',
    value: 'nameCh',
  },
];

export const basicInfos = [
  {
    label: '메뉴명',
    value: 'name',
  },
  {
    label: '가격',
    value: 'price',
    width: 49,
  },
  {
    label: '면세금액',
    value: 'noTaxPrice',
    width: 49,
  },
  {
    label: '재고',
    value: 'stock',
    width: 49,
  },
  {
    label: '최대재고',
    value: 'maxStock',
    width: 49,
  },
  {
    //type: 'checkbox',
    label: '기본샷 개수 (카페모드 사용자만 해당)',
    value: 'baseShot',
  },

  {
    label: 'divider',
    value: '주방 별칭',
  },
  {
    label: '주방 주문서에 노출되는 별칭',
    value: 'nameForKitchen',
  },

  {
    label: 'divider',
    value: '다국어',
  },
  {
    label: '영어 이름',
    value: 'nameEng',
  },
  {
    label: '일본어 이름',
    value: 'nameJp',
  },
  {
    label: '중국어 이름',
    value: 'nameCh',
  },

  {
    label: 'divider',
    value: '코드/라벨',
  },
  {
    label: '쥬씨 제품코드',
    value: 'juicyProductCode',
    width: 49,
  },
  {
    label: '기프티콘 코드',
    value: 'gifticonCode',
    width: 49,
  },
  {
    label: '바코드 번호',
    value: 'barcode',
  },
  {
    label: '라벨 출력 장수',
    value: 'labelCnt',
    width: 49,
  },
  {
    label: '외부 코드',
    value: 'externalCode',
    width: 49,
  },
];

export const etcInfos = [
  {
    label: 'divider',
    value: '메뉴설명',
    helper: 'menuDescription',
  },
  {
    label: '설명',
    value: 'info',
  },
  {
    label: '영어 설명',
    value: 'infoEng',
  },
  {
    label: '일본어 설명',
    value: 'infoJp',
  },
  {
    label: '중국어 설명',
    value: 'infoCh',
  },

  {
    label: 'divider',
    value: '메뉴 서브네임',
    helper: 'menuSubname',
  },
  {
    label: '서브네임',
    value: 'subName',
  },
  {
    label: '영어 서브네임',
    value: 'subNameEng',
  },
  {
    label: '일본어 서브네임',
    value: 'subNameJp',
  },
  {
    label: '중국어 서브네임',
    value: 'subNameCh',
  },
  {
    label: 'divider',
    value: '메뉴 정보',
  },
  {
    label: '칼로리',
    value: 'kcal',
    width: 49,
  },
  {
    label: '원산지',
    value: 'origin',
  },
  {
    label: '영양성분',
    value: 'nutrition',
  },
];
