export function footerValue(props, valueId) {
  let value = props.data.reduce((p, c) => {
    let keys = valueId.split('.');
    let firstKey = keys[0];
    let secondKey = keys[1];
    let newValue = 0;
    if (
      typeof c[firstKey] !== 'undefined' &&
      typeof c[firstKey][secondKey] !== 'undefined'
    ) {
      newValue = c[firstKey][secondKey] * 1;
    }
    p += newValue;
    return p;
  }, 0);
  //console.log(value);
  return value.toLocaleString('en-us');
}

export function sumValues(leafValues) {
  const result = leafValues.reduce((prev, curr) => {
    if (curr !== undefined) {
      prev += curr * 1;
    }
    return prev;
  }, 0);
  return result;
}

export function avgValues(leafValues) {
  let count = 0;
  const numer = leafValues.reduce((prev, curr) => {
    let num = curr * 1;
    if (num === 0 || typeof num === 'undefined' || isNaN(num)) {
      num = 0;
    } else {
      count += 1;
    }
    prev += num;
    return prev;
  }, 0);

  if (numer === 0) {
    return 0;
  }

  const avg = Math.round(numer / count);
  return avg;
}

export function growthRates(props) {
  const { data } = props;
  const id = props.column.id;
  const keys = id.split('.');
  const standard = data[1][keys[0]][keys[1]] || 0;
  const contrast = data[0][keys[0]][keys[1]] || 0;
  const rate =
    standard === 0
      ? contrast === 0
        ? 0
        : 100
      : (((contrast - standard) / standard) * 100).toFixed(2);
  return rate + '%';
}

export function swipeRow(array, a, b) {
  //console.log(array, a, b);
  const temp = array[a];

  array[a] = array[b];
  array[b] = temp;

  const tempSortCode = array[b].sort_code;
  array[b].sort_code = array[a].sort_code;
  array[a].sort_code = tempSortCode;

  return array;
}

export function swipeRowSortCode(array, a, b) {
  const tempSortCodeA = array[a].sortCode;
  const tempSortCodeB = array[b].sortCode;

  array[a] = { ...array[a], sortCode: tempSortCodeB };
  array[b] = { ...array[b], sortCode: tempSortCodeA };

  return array.sort((a, b) => a.sortCode - b.sortCode);
}
