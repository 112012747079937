//import { client } from './httpClient';
import { httpClient } from './axios';

const baseUrl = 'menu.php';

const getMenuList = (id, type) => {
  return httpClient
    .get(`${baseUrl}?id=${id}&type=${type}`)
    .then((res) => res.data);
};

const addOrUpdateMenu = (action, menu, id) => {
  //console.log(action, menu, id);
  return action === 'add'
    ? httpClient
        .post(baseUrl, { menu, id, action: 'addMenu' })
        .then((res) => res.data)
    : action === 'modify'
    ? httpClient.put(baseUrl, { menu, id }).then((res) => res.data)
    : httpClient.patch(baseUrl, { menu, id }).then((res) => res.data);
};

const deleteMenu = (id, codes) => {
  return httpClient
    .delete(`${baseUrl}?id=${id}&codes=${codes}`)
    .then((res) => res.data);
};

/* menu image */
const imageBaseUrl = 'menu_image.php';
const getMenuImage = (menuCode) => {
  return httpClient
    .get(`${imageBaseUrl}?menuCode=${menuCode}`)
    .then((res) => res.data);
};

const getMenuImageList = (menuCodes) => {
  return httpClient.post(imageBaseUrl, { menuCodes }).then((res) => res.data);
};

const addOrUpdateMenuImage = (imageFormData) => {
  return httpClient
    .post(imageBaseUrl, imageFormData, {
      headers: { 'content-type': 'multipart/form-data' },
    })
    .then((res) => res.data);
};

const deleteMenuImage = (menuCodes) => {
  return httpClient.delete(`${imageBaseUrl}?codes=${menuCodes}`);
};

const MenuService = {
  getMenuList,
  addOrUpdateMenu,
  deleteMenu,
  getMenuImage,
  getMenuImageList,
  addOrUpdateMenuImage,
  deleteMenuImage,
};

export default MenuService;
