class UploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.url = `${process.env.REACT_APP_API_URL}/board_upload_file.php`;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open('POST', this.url, true);
    xhr.responseType = 'json';
    //xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    //xhr.setRequestHeader('Authorization', getToken());
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `${file.name} 파일을 업로드 하지 못했습니다.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;
      //console.log('response', response);

      if (!response || response.error) {
        return reject(genericErrorText);
      }

      const { originName, fileName, extension } = response[0];

      // If the upload is successful, resolve the upload pro mise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      //테썹에서는 앞에 /test 붙이기
      resolve({
        default: `${response[0].url.substring(2)}`,
        originName,
        fileName,
        extension,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        //console.log('progress', evt);

        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest(file) {
    const data = new FormData();
    //console.log('_sendRequest', file);
    let nameArr = file.name.split('.');
    let name = nameArr.slice(0, nameArr.length - 1).join('.');

    data.append('names[]', name);
    data.append('files[]', file);

    //보내기
    this.xhr.send(data);
  }
}

export default UploadAdapter;
