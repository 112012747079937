import React from 'react';
import { useEffect } from 'react';
import { useMemo, useState, useRef } from 'react';

import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';

import EditiableWithSelectTable from '../editable_with_select_table/editiable_with_select_table';
import EditCellCheckbox from '../../component/edit_cell_checkbox/edit_cell_checkbox';

import CustomInput from '../custom_input/custom_input';
import CustomTextField from '../custom_textField/custom_textField';

import MenuService from '../../service/menu_service';
import OptionService from '../../service/option_service';
import SelectImage from '../select_image/select_image';

import { swipeRow } from '../detail_table/table';
import { optionInfos, categoryInfos, menuInfos } from './modifyFormRef';
import { colors } from '../../common/colors';

import { menu } from '../side_menu/menu';

import styles from './modify_form.module.css';

const useStyles = makeStyles((theme) => ({
  //option
  optionBox: {
    width: '100%',
    marginBottom: '2rem',
  },

  selectAllBtn: {
    border: 'none',
    backgroundColor: 'transparent',
    height: 20,

    '& svg': {
      fontSize: 18,
      color: colors.darkViolet600,
    },
  },

  editableInput: {
    width: '100%',
    border: 'none',
    fontSize: 14,
    fontWeight: 300,
    textAlign: 'center',

    '&:focus': {
      border: '1px solid black',
    },
  },

  arrowDown: {
    transform: 'rotate(180deg)',
  },
}));

const ModifyForm = ({
  title,
  newItem,
  setNewItem,
  isLock,
  error,
  categoryList,
  menuList,
  optionList,
  affiliate,
  setUpdatedOption,
  menuImage,
  setMenuImage,
  setIsOptionUpdate,
}) => {
  const classes = useStyles();
  const formRef = useRef();
  const formList = useMemo(() => {
    if (title === '옵션수정') {
      return optionInfos;
    } else if (title.includes('카테고리')) {
      return categoryInfos;
    } else {
      return menuInfos;
    }

    return [];
  }, [title]);

  //menu form
  const [tab, setTab] = useState(0);
  const onTabChange = (e, newValue) => setTab(newValue);

  //option
  const [option, setOption] = useState({ list: optionList, loading: false });
  const [menuOptionList, setMenuOptionList] = useState(null); //newMenuOption
  const [selectedOption, setSelectedOption] = useState([]); //select option row

  const selectMenuRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);
  const [loadOptions, setLoadOptions] = useState({
    list: null,
    loading: false,
  });

  const [selectedLoadOptions, setSelectedLoadOptions] = useState([]);

  const optionCheckColumns = [
    {
      Header: 'ID',
      accessor: 'sort_code',
    },
    {
      Header: '품절',
      accessor: 'sold_out',
      Cell: (props) => (
        <EditCellCheckbox updateData={updateMenuOption} {...props} />
      ),
      minWidth: 20,
    },
    {
      Header: '출력',
      accessor: 'print_setting',
      Cell: (props) => (
        <EditCellCheckbox updateData={updateMenuOption} {...props} />
      ),
      minWidth: 20,
    },
  ];

  const optionBaseColumns = useMemo(
    () => [
      {
        Header: '그룹이름',
        accessor: 'group_name',
        minWidth: 120,
      },
      {
        Header: '옵션이름',
        accessor: 'menu_option',
        minWidth: 120,
      },
      {
        Header: '가격',
        accessor: 'option_price',
      },
      {
        Header: '최소선택',
        accessor: 'min_select',
      },
      {
        Header: '최대선택',
        accessor: 'max_select',
      },
      {
        Header: '영어',
        accessor: 'name_eng',
        minWidth: 80,
      },
      {
        Header: '일본어',
        accessor: 'name_jp',
        minWidth: 80,
      },
      {
        Header: '중국어',
        accessor: 'name_ch',
        minWidth: 80,
      },
      {
        Header: '그룹_영',
        accessor: 'group_name_eng',
        minWidth: 80,
      },
      {
        Header: '그룹_일',
        accessor: 'group_name_jp',
        minWidth: 80,
      },
      {
        Header: '그룹_중',
        accessor: 'group_name_ch',
        minWidth: 80,
      },
    ],
    []
  );

  const getMenuOption = (menuCode) => {
    if (!menuCode || !affiliate.id) return;
    setOption((old) => ({ ...old, loading: true }));

    OptionService.getOptionList(affiliate.id, 'one', menuCode)
      .then((res) => {
        //console.log(res);
        let { data, error } = res;
        if (!error) {
          setMenuOptionList(data);
          setOption({ list: data, loading: false });
        }
      })
      .catch((err) => console.log(err));
  };

  const updateMenuOption = (rowIndex, columnId, value) => {
    setIsOptionUpdate(true);
    setMenuOptionList((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const optionColumns = optionCheckColumns.concat(
    optionBaseColumns.map((column, index) => {
      return {
        ...column,
        Cell: (props) => (
          <EditableCellInput
            key={index}
            updateData={updateMenuOption}
            disabled={isLock}
            {...props}
          />
        ),
      };
    })
  );

  const loadOptionTable = useMemo(() => {
    if (!optionColumns || !loadOptions.list) return [];
    return {
      columns: [{ Header: 'ID', accessor: 'sort_code' }, ...optionBaseColumns],
      data: loadOptions.list,
    };
  }, [loadOptions.list]);

  const isDifferent = (origin, newOne) => {
    return JSON.stringify(origin) !== JSON.stringify(newOne);
  };

  const getUpdatedOptions = () => {
    if (!option.list) return;
    if (!isDifferent(option.list, menuOptionList)) return [];

    let updated = [];
    menuOptionList.forEach((newOption) => {
      let origin = option.list.find(
        (originOption) => originOption.code === newOption.code
      );

      if (!origin || isDifferent(origin, newOption)) {
        updated.push(newOption);
      }
    });
    return updated;
  };

  const updateOption = async () => {
    //console.log('updateOption', menuOptionList);
    let updatedOptionList = getUpdatedOptions();
    if (!updatedOptionList || updatedOptionList.length === 0) {
      return 'success';
    }

    return await OptionService.updateOption(menuOptionList)
      .then((res) => {
        setIsOptionUpdate(true);
        return res.data;
      })
      .catch((err) => console.log(err));
  };

  const addDefaultOption = async (e) => {
    e.preventDefault();
    //현재 옵션 저장 후 새 옵션 추가
    let menuCode = newItem.code;
    updateOption().then((res) => {
      if (res === 'success') {
        OptionService.addOption('default', menuCode, null)
          .then((res) => {
            let { data, error } = res;
            if (!error && data === 'success') {
              getMenuOption(menuCode);
              setIsOptionUpdate(true);
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const deleteOption = (e) => {
    e.preventDefault();

    if (!selectedOption || selectedOption.length === 0) return;
    let codes = selectedOption.map((option) => option.original.code).join(',');

    const confirm = window.confirm(
      `${selectedOption.length}개 옵션을 삭제할까요?`
    );
    if (!confirm) return;

    updateOption().then((res) => {
      if (res === 'success') {
        OptionService.deleteOption(codes)
          .then((res) => {
            if (!res.error && res.data === 'success') {
              setIsOptionUpdate(true);
              getMenuOption(newItem.code);
              setSelectedOption([]);
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const addLoadedOption = (e) => {
    e.preventDefault();

    if (isLock) return;

    if (!selectedLoadOptions || selectedLoadOptions.length === 0) {
      alert('선택된 옵션이 없습니다.');
      return;
    }

    let menuCode = newItem.code;
    const originalList = selectedLoadOptions.map((option) => option.original);
    //기존 내용 저장 후 로드 된 옵션 추가
    updateOption().then((res) => {
      if (res === 'success') {
        OptionService.addOption('loaded', menuCode, originalList)
          .then((res) => {
            let { data, error } = res;
            if (!error && data === 'success') {
              getMenuOption(menuCode);
              alert('추가 완료!');
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const onOpenMenuSelect = (e) => {
    e.preventDefault();
    !isLock && setMenuOpen(true);
  };

  const getMenuOptionList = async (type) => {
    let menuCode = '';

    setLoadOptions((old) => ({ ...old, loading: true }));
    if (type === 'all') {
      setMenuOpen(false);
    } else {
      menuCode = selectMenuRef.current.value;
      if (!menuCode) return;
    }

    await OptionService.getOptionList(affiliate.id, type, menuCode)
      .then((res) => {
        let { data, error } = res;
        if (!error) {
          setLoadOptions({ list: data, loading: false });
        }
      })
      .catch((err) => console.log(err));
  };

  const isMovable = (selectedRows, direction, list) => {
    const minOrMaxRow = selectedRows[0];
    const rowIndex = list.findIndex(
      (row) => row.code === minOrMaxRow.original.code
    );

    return direction === 'up'
      ? rowIndex === 0
        ? false
        : true
      : rowIndex === list.length - 1
      ? false
      : true;
  };

  const moveOptionRow = (e, direction) => {
    e.preventDefault();

    let selectedRows = [...selectedOption];
    if (!selectedRows || selectedRows.length === 0) return;

    if (direction === 'down') {
      selectedRows.sort((a, b) => b.original.sort_code - a.original.sort_code);
    }

    let newList = JSON.parse(JSON.stringify(menuOptionList));
    if (!isMovable(selectedRows, direction, newList)) return;

    selectedRows.forEach((row) => {
      const rowIndex = menuOptionList.findIndex(
        (option) => option.code === row.original.code
      );
      const affectedIndex = direction === 'up' ? rowIndex - 1 : rowIndex + 1;
      swipeRow(newList, rowIndex, affectedIndex);
    });

    setMenuOptionList(newList);
  };

  useEffect(() => {
    if (!optionList) return;

    setOption({ list: optionList, loading: false });
  }, [optionList]);

  useEffect(() => {
    if (!option || option.list === null) return;

    setMenuOptionList([...option.list]);
  }, [option.list]);

  useEffect(() => {
    if (!menuOptionList) return;

    setUpdatedOption(menuOptionList);
  }, [menuOptionList]);

  return (
    <>
      {newItem && formList && (
        <form ref={formRef}>
          {!title.includes('메뉴') ? (
            formList && (
              <FormPart
                formList={formList}
                newItem={newItem}
                setNewItem={setNewItem}
                error={error}
                isLock={isLock}
              />
            )
          ) : (
            <>
              <Tabs
                onChange={onTabChange}
                value={tab}
                indicatorColor='primary'
                textColor='primary'
                variant='fullWidth'
                aria-label='add modify tabs'
              >
                <Tab label='기본 정보' {...getTabProps(0)} />
                <Tab label='상세 정보' {...getTabProps(1)} />
                {title === '메뉴 수정' && (
                  <Tab label='옵션 정보' {...getTabProps(2)} />
                )}
              </Tabs>

              {formList.map((tabList, index) => {
                return (
                  <div key={index}>
                    {index === tab && (
                      <div className={styles.tabContent}>
                        {index === 0 && (
                          <div className={styles.section}>
                            <span className={styles.subTitle}>메뉴 이미지</span>
                            <SelectImage
                              menuImage={menuImage}
                              setMenuImage={setMenuImage}
                              isLock={isLock}
                            />
                          </div>
                        )}
                        <FormPart
                          formList={tabList}
                          newItem={newItem}
                          setNewItem={setNewItem}
                          error={error}
                          isLock={isLock}
                          selectList={categoryList}
                        />
                      </div>
                    )}
                  </div>
                );
              })}

              {tab === 2 && (
                <div className={styles.tabContent}>
                  <div className={styles.section}>
                    <span className={styles.subTitle}>현재 옵션</span>
                    {!menuOptionList ? (
                      '옵션이 없습니다.'
                    ) : (
                      <>
                        <div className={styles.btnRow}>
                          <div className={styles.actionBtns}>
                            <div
                              className={`${styles.btn} ${
                                isLock ? styles.disabledBtn : ''
                              }`}
                              disabled={isLock}
                              onClick={(e) => moveOptionRow(e, 'up')}
                            >
                              <span className={styles.arrow}>➜</span>
                            </div>
                            <div
                              className={`${styles.btn} ${
                                isLock ? styles.disabledBtn : ''
                              }`}
                              disabled={isLock}
                              onClick={(e) => moveOptionRow(e, 'down')}
                            >
                              <span
                                className={`${styles.arrow} ${styles.arrowDown}`}
                              >
                                ➜
                              </span>
                            </div>
                          </div>
                          <div className={styles.actionBtns}>
                            <button
                              className={`${styles.btn} ${
                                isLock ? styles.disabledBtn : ''
                              }`}
                              disabled={isLock}
                              onClick={addDefaultOption}
                            >
                              새옵션추가
                            </button>
                            <button
                              className={`${styles.btn} ${
                                isLock ? styles.disabledBtn : ''
                              }`}
                              disabled={isLock}
                              onClick={deleteOption}
                            >
                              옵션삭제
                            </button>
                          </div>
                        </div>

                        <div className={styles.tableContainer}>
                          <EditiableWithSelectTable
                            xs
                            tableData={{
                              columns: optionColumns,
                              data: menuOptionList,
                            }}
                            selectedRow={selectedOption}
                            setSelectedRow={setSelectedOption}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <div className={styles.section}>
                    <span className={styles.subTitle}>옵션 불러오기</span>
                    <div className={styles.btnRow}>
                      <div className={styles.actionBtns}>
                        <div
                          className={`${styles.btn} ${
                            isLock ? styles.disabledBtn : ''
                          }`}
                          onClick={addLoadedOption}
                        >
                          <VerticalAlignTopIcon />
                          옵션 추가
                        </div>
                      </div>

                      <div className={styles.actionBtns}>
                        <div
                          className={`${styles.btn} ${
                            isLock ? styles.disabledBtn : ''
                          }`}
                          onClick={() => !isLock && getMenuOptionList('all')}
                        >
                          전체옵션
                        </div>
                        <div
                          className={`${styles.btn} ${
                            isLock ? styles.disabledBtn : ''
                          }`}
                          onClick={onOpenMenuSelect}
                        >
                          특정메뉴에서 불러오기
                        </div>
                      </div>
                    </div>

                    {menuOpen && menuList && (
                      <div className={`${styles.actionBtns} ${styles.flexEnd}`}>
                        <select
                          className={styles.select}
                          ref={selectMenuRef}
                          name='menu'
                          id='selectLoadMenu'
                        >
                          <option value=''>메뉴를 선택하세요</option>
                          {menuList &&
                            menuList.map((menu) => {
                              let { code, name, price, category_name } = menu;

                              return (
                                <option key={code} value={code}>
                                  {name} ({category_name} | {price})
                                </option>
                              );
                            })}
                        </select>
                        <div
                          className={styles.btn}
                          onClick={() => getMenuOptionList('one')}
                        >
                          조회
                        </div>
                      </div>
                    )}

                    {loadOptions.list !== null ? (
                      loadOptions.loading ? (
                        <div
                          style={{
                            width: '100%',
                            height: 100,
                            textAlign: 'center',
                          }}
                        >
                          loading...
                        </div>
                      ) : (
                        <EditiableWithSelectTable
                          xs
                          tableData={loadOptionTable}
                          selectedRow={selectedLoadOptions}
                          setSelectedRow={setSelectedLoadOptions}
                        />
                      )
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: 100,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        전체옵션 or 특정메뉴 불러오기를 선택하세요.
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </form>
      )}
    </>
  );
};

export default ModifyForm;

function getTabProps(index) {
  return {
    id: `add-modify-tab-${index}`,
    'aria-controls': `add-modify-tabpanel-${index}`,
  };
}

function FormPart(props) {
  const { formList, newItem, setNewItem, error, isLock, selectList } = props;

  const getCategoryName = (code) => {
    console.log(selectList, code);
    if (!selectList) return;
    if (!code) return 'default';
    return (
      selectList.find((category) => category.code === code * 1).name || '미분류'
    );
  };

  const onInputChange = (e) => {
    const { name, type, inputMode } = e.target;

    let value = e.target.value;
    if (type === 'checkbox') {
      value = e.target.checked ? 1 : 0;
    }

    if (inputMode === 'numeric' && value !== '-' && value !== '') {
      if (!Number.isInteger(value * 1)) return;
      value = parseInt(value);
    }

    if (name === 'category') {
      let categoryName = getCategoryName(value * 1);
      setNewItem((old) => ({
        ...old,
        [name]: value,
        category_name: categoryName,
      }));
      return;
    }

    setNewItem((old) => ({ ...old, [name]: value }));
  };

  if (!formList) return null;

  return (
    <div className={styles.tabContent}>
      {formList.map((info, index) => {
        let { title, list } = info;
        return (
          <div className={styles.section} key={index}>
            {title && <span className={styles.subTitle}>{title}</span>}
            <div className={styles.listBox}>
              {list.map((item, index) => (
                <CustomInput
                  {...item}
                  key={index}
                  value={newItem[item.name]}
                  onChange={onInputChange}
                  selectList={item.isSelect ? selectList : null}
                  error={error}
                  disabled={
                    item.name === 'sold_out' ||
                    item.name === 'visible' ||
                    item.name === 'print_setting'
                      ? false
                      : isLock
                      ? true
                      : false
                  }
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const EditableCellInput = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateData,
  disabled,
}) => {
  const classes = useStyles();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => setValue(e.target.value);

  const onBlur = () => {
    let newValue = value;

    const intValueColumn = [
      { name: '가격', value: 'option_price' },
      { name: '최소선택', value: 'min_select' },
      { name: '최대선택', value: 'max_select' },
    ];

    const isIntColumn = intValueColumn.find((column) => column.value === id);
    if (isIntColumn && Number.isInteger(value * 1)) {
      newValue = value * 1;
    }

    updateData(index, id, newValue);
  };
  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onEnterPress = (e) => {
    const key = e.key;
    if (key === 'Enter') {
      onBlur();
    }
  };

  return (
    <input
      className={classes.editableInput}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={onEnterPress}
      disabled={disabled}
      onClick={(e) => e.stopPropagation()}
    />
  );
};
