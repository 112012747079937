import EventNoteIcon from '@material-ui/icons/EventNote';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import StorefrontIcon from '@material-ui/icons/Storefront';
import CategoryIcon from '@material-ui/icons/Category';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import CompareIcon from '@material-ui/icons/Compare';
import DateRangeIcon from '@material-ui/icons/DateRange';
import WidgetsIcon from '@material-ui/icons/Widgets';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';

import AssessmentIcon from '@material-ui/icons/Assessment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StoreIcon from '@material-ui/icons/Store';
import BuildIcon from '@material-ui/icons/Build';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';

export const pageIcons = {
  매출조회: <AssessmentIcon />,
  재고관리: <WidgetsIcon />,
  'A/S': <BuildIcon />,
  '우노스 스토어': <StoreIcon />,
  '기간별 조회': <EventNoteIcon />,
  '상품별 조회': <AllInboxIcon />,
  '매장별 조회': <StorefrontIcon />,
  '카테고리별 조회': <CategoryIcon />,
  '비교 조회': <CompareIcon />,
  '캘린더 조회': <DateRangeIcon />,
  '결제수단별 조회': <CreditCardIcon />,
  '영수건별 조회': <AccessTimeIcon />,
  '기기별 조회': <VideoLabelIcon />,
  재고조회: <WidgetsIcon />,
  '품절기록 조회': <BorderClearIcon />,
  '재고수정/품절 관리': <MenuBookIcon />,
  '부가세 신고자료 요청': <LocalLibraryOutlinedIcon />,
  '매뉴얼 다운로드': <LibraryBooksIcon />,
  Home: <HomeOutlinedIcon />,
  '백신패스 관리': <PlaylistAddCheckIcon />,
  메뉴수정: <EditOutlinedIcon />,
  '옵션 전체수정': <CreateRoundedIcon />,
  공지사항: <NotificationsOutlinedIcon />,
};

export function getPageIcon(title) {
  return pageIcons[title] || <FiberManualRecordIcon />;
}
