import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Paper,
  Popper,
  Fade,
  ClickAwayListener,
  Modal,
  Backdrop,
} from '@material-ui/core';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { colors } from '../../common/colors';

const popperContents = {
  visible: '체크 시 키오스크에 노출되며, 체크해제 시 포스에만 노출됩니다.',
  category_visible: '비활성화 시 포스에만 노출',
  membership:
    "체크해제 시 해당메뉴 '우키키' 포인트 적립 불가메뉴로 설정됩니다.",
  baseShot: '카페모드 사용 매장만 해당',
  package_flag:
    '키오스크에서 매장/포장 기능을 사용할 경우, 해당 버튼을 누른 페이지에만 설정된 메뉴가 노출됩니다.',
  menu_description: `${process.env.PUBLIC_URL}/images/menu_description.png`,
  menu_subname: `${process.env.PUBLIC_URL}/images/menu_subname.jpeg`,
  yogiyo: `요기요 배달매출의 경우, 요기요에서 메뉴별 구분제공을 하지 않는 관계로 \n총합인 단일메뉴로 나타내고 있으니 참고바랍니다.`,
};

const imgDescription = {
  menu_description: '텍스트를 길게 적으실 경우, 정상노출되지 않을 수 있습니다.',
  menu_subname: '텍스트를 길게 적으실 경우, 정상노출되지 않을 수 있습니다.',
};

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 1301,
  },

  paper: {
    borderRadius: 6,
    padding: '8px 16px',
    whiteSpace: 'pre-wrap',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
    },
  },

  icon: {
    width: 20,
    paddingLeft: 4,

    '&:hover': {
      cursor: 'pointer',
    },
  },

  arrow: {
    content: ' ',
    position: 'absolute',
    borderStyle: 'solid',
    borderWidth: 5,
    borderColor: 'transparent transparent #fff transparent',

    top: -10,
    left: '49.5%',
  },

  //image
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  imgBox: {
    position: 'relative',
    backgroundColor: colors.white,
    maxWidth: 700,
    maxHeight: '100vh',
    overflow: 'auto',

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },

    '& p': {
      textAlign: 'center',
      padding: '0.7rem 1rem',
      fontSize: 14,
      fontWeight: 500,
      color: colors.red,

      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },

  closeBtn: {
    position: 'absolute',
    top: '1%',
    right: '1%',
    fontSize: 16,
    padding: '3px 9px',
    border: '1px solid black',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'all 200ms ease-in',
    color: colors.white,
    backgroundColor: colors.black,

    '&:hover': {
      color: colors.black,
      backgroundColor: colors.white,
    },
  },
}));

const SimplePopper = ({ contentsKey, image }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'description-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //hide popper on click
  const handleClickAway = () => {
    setAnchorEl(null);
  };

  //hide popper on scrolling
  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleClickAway);
    };
    watch(); // addEventListener 함수를 실행
    return () => {
      // addEventListener 함수를 삭제
      window.removeEventListener('scroll', handleClickAway);
    };
  });

  return (
    <>
      <HelpOutlineIcon
        className={classes.icon}
        style={{ color: anchorEl ? '#CC3333' : '#454545' }}
        aria-describedby={id}
        onClick={handleClick}
      />
      {image ? (
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 300,
          }}
        >
          <Fade in={open}>
            <div className={classes.imgBox}>
              <div className={classes.closeBtn} onClick={handleClose}>
                X
              </div>
              <img
                style={{ maxWidth: '100%' }}
                src={popperContents[contentsKey]}
                alt={contentsKey}
              />

              <p id='transition-modal-description'>
                <ErrorOutline style={{ fontSize: 15, marginRight: 6 }} />
                {imgDescription[contentsKey]}
              </p>
            </div>
          </Fade>
        </Modal>
      ) : (
        <Popper
          id={id}
          className={classes.popper}
          open={open}
          anchorEl={anchorEl}
          transition
          modifiers={{
            offset: {
              enabled: true,
              offset: '0, 8',
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
          }}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={classes.paper}>
                  <span className={classes.arrow}></span>
                  {popperContents[contentsKey]}
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
    </>
  );
};

export default SimplePopper;
