import React from 'react';
import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import styles from './custom_dialog.module.css';

const CustomDialog = ({ open, handleClose, contents, checkVan }) => {
  const { startDate, endDate, contactType, contact, name, id } = contents;
  const onSubmit = () => {
    checkVan(id);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
    >
      <DialogTitle id='alert-dialog-title'>
        {'이대로 요청하시겠습니까?'}
      </DialogTitle>
      <DialogContent className={styles.contentsWrapper}>
        <div className={styles.box}>
          <span className={styles.title}>매장명</span>
          <div className={styles.contents}>{name}</div>
        </div>
        <div className={styles.box}>
          <span className={styles.title}>기간</span>
          <div className={styles.contents}>
            {startDate} ~ {endDate}
          </div>
        </div>
        <div className={styles.box}>
          <span className={styles.title}>받으실 곳</span>
          <div className={styles.contents}>
            {contactType} / {contact}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={styles.actionBox}>
        <Button
          className={styles.cancelBtn}
          onClick={handleClose}
          color='primary'
        >
          취소
        </Button>
        <Button
          onClick={onSubmit}
          className={styles.submitBtn}
          color='primary'
          autoFocus
        >
          보내기
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
