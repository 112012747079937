class LoginService {
  constructor(httpClient) {
    this.loginService = httpClient;
  }

  login(id, password) {
    const url = '/db_login.php';
    const result = this.loginService
      .post(url, {
        id,
        password,
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  }

  logout() {
    const url = '/db_logout.php';
    const result = this.loginService.post(url).catch((error) => {
      console.log(error);
    });

    return result;
  }

  check() {
    const url = '/login_check.php';
    const result = this.loginService //
      .post(url)
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
        }
        //console.log(err.config);
      });
    return result;
  }

  //refresh access token
  onSilentRefresh() {
    const data = {};
    const refresh = this.loginService
      .post('/silent_refresh.php', data)
      .then((res) => {
        console.log('silent_refresh token', res);
        return res;
      })
      .catch(
        (err) => console.log(err)
        //로그인 실패 처리
      );

    return refresh;
  }

  getUserInfo(id, role) {
    const url = '/user.php';
    const result = this.loginService
      .post(url, {
        id,
        role,
      })
      .catch((err) => console.log(err));

    return result;
  }

  getJwt() {
    const url = '/jwt.php';
    const result = this.loginService.post(url).catch((err) => console.log(err));

    return result;
  }

  login_test(id, password) {
    const url = '/login.php';
    const result = this.loginService
      .post(url, { id, password })
      .catch((err) => console.log(err));

    return result;
  }
}

export default LoginService;
