import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { setSessionData, getSessionData } from '../common/session.js';

import CategoryService from '../service/category_service.js';
import MenuService from '../service/menu_service.js';

import { useAuth } from './Auth.js';

const menuInfoContext = createContext();

export const ProvideMenuInfo = ({ children }) => {
  const { user } = useAuth();
  const info = useProvideMenuInfo(user);
  return (
    <menuInfoContext.Provider value={info}>{children}</menuInfoContext.Provider>
  );
};

export const useMenuInfo = () => useContext(menuInfoContext);

function useProvideMenuInfo(user) {
  const history = useHistory();
  const { id, role, affiliates } = user || '';
  const [menuInfo, setMenuInfo] = useState(
    id ? getSessionData(`${id}_ml`) : null
  );
  const [categoryInfo, setCategoryInfo] = useState(
    id ? getSessionData(`${id}_cl`) : null
  );

  const fetchMenuInfo = async () => {
    if (!user) return;
    let getId =
      role === 'investor'
        ? affiliates.map((affiliate) => affiliate.value).join("','")
        : id;

    let type = role === 'investor' ? 'simple_investor' : 'simple';
    await MenuService.getMenuList(getId, type).then((res) => {
      if (res.error && res.message === 'Session expired') {
        history.push('/');
        return;
      }
      const menuList = res.data;
      setMenuInfo(() => menuList);
      setSessionData(`${id}_ml`, res.data);
    });

    await CategoryService.getCategoryList(getId, type).then((res) => {
      if (res.error && res.message === 'Session expired') {
        history.push('/');
        return;
      }
      const categoryList = res.data;
      setCategoryInfo(() => categoryList);
      setSessionData(`${id}_cl`, categoryList);
    });
  };

  const clearMenuInfo = () => {
    setMenuInfo(null);
    setCategoryInfo(null);
  };

  const makeMenuInfo = () => {
    if (!menuInfo || !categoryInfo) fetchMenuInfo();

    /* return menuInfo.map((menu) => {
      console.log(menu);
    }); */
  };

  useEffect(() => {
    if (!menuInfo) fetchMenuInfo();
  }, []);

  return { menuInfo, categoryInfo, fetchMenuInfo, clearMenuInfo, makeMenuInfo };
}
